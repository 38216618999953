import axios from '../lib/api-client';

import { DISPLAY_ERROR_MESSAGE, DISPLAY_INFO_MESSAGE } from './ui';

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const CREATE_EVENT = 'CREATE_EVENT';
export const SELECT_EVENT = 'SELECT_EVENT';
export const JOIN_EVENT = 'JOIN_EVENT';
export const LOADING_JOIN_EVENT = 'LOADING_JOIN_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const JOIN_MATCH = 'JOIN_MATCH';
export const LOADING_JOIN_MATCH = 'LOADING_JOIN_MATCH';

export const FETCH_EVENT_MATCHES = 'FETCH_EVENT_MATCHES';
export const SELECT_MATCH = 'SELECT_MATCH';
export const CREATE_TEAM = 'CREATE_TEAM';
export const GET_TEAMS = 'GET_TEAMS';
export const CREATE_VENUE = 'CREATE_VENUE';
export const GET_VENUES = 'GET_VENUES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_CITY = 'GET_CITY';
export const CREATE_MATCH = 'CREATE_MATCH';
export const GET_MATCHES = 'GET_MATCHES';
export const EDIT_MATCH = 'EDIT_MATCH';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const GET_RESERVATION_DETAILS = 'GET_RESERVATION_DETAILS';

const getEvents = (options = {}) => (dispatch) => {
  axios
    .get(
      `/api/event?pageSize=${options.pageSize || 10}&page=${options.page || 1}`
    )
    .then((response) => {
      dispatch({ type: FETCH_EVENTS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_EVENTS, data: { events: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getReservationDetails = (ref) => (dispatch) => {
  axios
    .get(`/api/event/reservation/${ref}`)
    .then((response) => {
      dispatch({ type: GET_RESERVATION_DETAILS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createEvent = (event) => (dispatch) => {
  axios
    .post(`/api/event`, { event })
    .then((response) => {
      dispatch({ type: CREATE_EVENT });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Tournament created successfully!',
      });
      window.history.back();
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const selectEvent = (id) => (dispatch) => {
  axios
    .get(`/api/event/${id}`)
    .then((response) => {
      dispatch({ type: SELECT_EVENT, data: response.data.data });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const editEvent = (id, event) => (dispatch) => {
  axios
    .patch(`/api/event/${id}`, { event })
    .then((response) => {
      dispatch({ type: EDIT_EVENT });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Data updated successfully!',
      });
      window.history.back();
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const joinEvent = (id, scope) => (dispatch) => {
  dispatch({ type: LOADING_JOIN_EVENT, loading: true });
  axios
    .patch(`/api/event/${id}/join`, { scope })
    .then((response) => {
      dispatch({ type: JOIN_EVENT });
      dispatch({ type: LOADING_JOIN_EVENT, loading: false });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Successfully sent a request to join event.',
      });
    })
    .catch((err) => {
      dispatch({ type: LOADING_JOIN_EVENT, loading: false });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getEventMatches = (id) => (dispatch) => {
  axios
    .get(`/api/event/${id}/matches`)
    .then((response) => {
      dispatch({ type: FETCH_EVENT_MATCHES, data: response.data.data });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const selectMatch = (id, match_id) => (dispatch) => {
  axios
    .get(`/api/event/${id}/matches/${match_id}`)
    .then((response) => {
      dispatch({ type: SELECT_MATCH, data: response.data.data });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const joinMatch = (id, match_id, scope) => (dispatch) => {
  dispatch({ type: LOADING_JOIN_MATCH, loading: true });
  axios
    .patch(`/api/event/${id}/matches/${match_id}/join`, {
      scope,
    })
    .then((response) => {
      dispatch({ type: JOIN_MATCH });
      dispatch({ type: LOADING_JOIN_MATCH, loading: false });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Successfully sent a request to join match',
      });
    })
    .catch((err) => {
      dispatch({ type: LOADING_JOIN_MATCH, loading: false });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const reserveSeat = (eventId, matchId, seat) => (dispatch) => {
  console.log('reserve action');
  axios
    .patch(`/api/event/${eventId}/matches/${matchId}/seat`, {
      seat,
    })
    .then(() => {
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Seat reserved.',
      });
      window.history.back();
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const downloadDocument = (document_name) => (dispatch) => {
  axios
    .get(`/api/file/${document_name}/download`, {
      responseType: 'blob',
    })
    .then((response) => {
      // dispatch({
      //   type: DISPLAY_INFO_MESSAGE,
      //   data: "Document downloaded successfully!",
      // });
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/pdf',
          encoding: 'UTF-8',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', document_name);
      document.body.appendChild(link);
      link.click();
      // document.body.removeChild(link);
      link.remove();
    })
    .catch((err) => {
      console.log('err: ', err);
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const exportEvent = (id) => (dispatch) => {
  console.log('exporting event');
  axios
    .get(`/api/event/${id}/export`)
    .then(() => {
      return axios.get(`/api/file/event_${id}.xlsx/download`, {
        responseType: 'blob',
      });
    })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          encoding: 'UTF-8',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `event_${id}`);
      document.body.appendChild(link);
      link.click();
      // document.body.removeChild(link);
      link.remove();
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const exportMatchList = (id, match_id) => (dispatch) => {
  console.log('exporting match list');
  axios
    .get(`/api/event/${id}/matches/${match_id}/export`)
    .then(() => {
      return axios.get(`/api/file/match_${match_id}.xlsx/download`, {
        responseType: 'blob',
      });
    })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          encoding: 'UTF-8',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `match_${match_id}`);
      document.body.appendChild(link);
      link.click();
      // document.body.removeChild(link);
      link.remove();
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createTeam = (team) => (dispatch) => {
  axios
    .post(`/api/admin/team`, { team })
    .then((response) => {
      dispatch({ type: CREATE_TEAM });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Club created successfully!',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getTeams = () => (dispatch) => {
  axios
    .get(`/api/search/team`)
    .then((response) => {
      dispatch({ type: GET_TEAMS, data: response.data.data });
      // dispatch({
      //   type: DISPLAY_INFO_MESSAGE,
      //   data: "Countries fetched successfully!",
      // });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createVenue = (location) => (dispatch) => {
  axios
    .post(`/api/location`, { location })
    .then((response) => {
      dispatch({ type: CREATE_VENUE });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Venue created successfully!',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getVenues = () => (dispatch) => {
  axios
    .get(`/api/search/location`)
    .then((response) => {
      dispatch({ type: GET_VENUES, data: response.data.data });
      // dispatch({
      //   type: DISPLAY_INFO_MESSAGE,
      //   data: "Countries fetched successfully!",
      // });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getCountries = () => (dispatch) => {
  axios
    .get(`/api/search/country`)
    .then((response) => {
      dispatch({ type: GET_COUNTRIES, data: response.data.data });
      // dispatch({
      //   type: DISPLAY_INFO_MESSAGE,
      //   data: "Countries fetched successfully!",
      // });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getCity = (country_id) => (dispatch) => {
  axios
    .get(`/api/search/city?countryId=${country_id}`)
    .then((response) => {
      dispatch({ type: GET_CITY, data: response.data.data });
      // dispatch({
      //   type: DISPLAY_INFO_MESSAGE,
      //   data: "Cities fetched successfully!",
      // });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createMatch = (match, event_id) => (dispatch) => {
  axios
    .post(`/api/event/${event_id}/match`, { match })
    .then((response) => {
      dispatch({ type: CREATE_MATCH });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Match created successfully!',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getMatches = () => (dispatch) => {
  axios
    .get(`/api/search/match`)
    .then((response) => {
      dispatch({ type: GET_MATCHES, data: response.data.data });
      // dispatch({
      //   type: DISPLAY_INFO_MESSAGE,
      //   data: "Countries fetched successfully!",
      // });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const editMatch = (id, event_id, match_id, match) => (dispatch) => {
  axios
    .patch(`/api/event/${event_id}/match/${match_id}`, { match })
    .then((response) => {
      dispatch({ type: EDIT_MATCH });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Data updated successfully!',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createDocument = (file) => (dispatch) => {
  axios
    .post(`/api/file/add`, { file })
    .then((response) => {
      dispatch({ type: CREATE_DOCUMENT });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Document created successfully!',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

export default {
  getEvents,
  getReservationDetails,
  createEvent,
  selectEvent,
  editEvent,
  joinEvent,
  joinMatch,
  reserveSeat,
  getEventMatches,
  selectMatch,
  downloadDocument,
  exportEvent,
  exportMatchList,
  createTeam,
  getTeams,
  createVenue,
  getVenues,
  getCountries,
  getCity,
  createMatch,
  getMatches,
  editMatch,
  createDocument,
};
