import {
  ADMIN_GET_USERS,
  ADMIN_GET_USER,
  ADMIN_EDIT_USER,
  ADMIN_DELETE_USER,
  ADMIN_GET_TEAMS,
  ADMIN_GET_TEAM,
  ADMIN_CREATE_TEAM,
  ADMIN_EDIT_TEAM,
  ADMIN_DELETE_TEAM,
  ADMIN_GET_EVENTS,
  ADMIN_GET_EVENT,
  ADMIN_CREATE_EVENT,
  ADMIN_EDIT_EVENT,
  ADMIN_DELETE_EVENT,
  ADMIN_GET_MATCHES,
  ADMIN_GET_MATCH,
  ADMIN_CREATE_MATCH,
  ADMIN_EDIT_MATCH,
  ADMIN_DELETE_MATCH,
  ADMIN_GET_LOCATIONS,
  ADMIN_GET_LOCATION,
  ADMIN_CREATE_LOCATION,
  ADMIN_EDIT_LOCATION,
  ADMIN_DELETE_LOCATION,
  ADMIN_GET_OFFICERS,
  ADMIN_ASSIGN_EVENT_OFFICERS,
  ADMIN_ASSIGN_MATCH_OFFICER,
  ADMIN_GET_REPORTS,
  ADMIN_GET_REPORT,
  ADMIN_EDIT_REPORT,
  ADMIN_CREATE_REPORTS,
} from '../actions/admin';
import { LOGOUT } from '../actions/auth';

const initialState = {
  users: [],
  selectedUser: null,
  teams: [],
  selectedTeam: null,
  events: [],
  selectedEvent: null,
  matches: [],
  selectedMatch: null,
  locations: [],
  selectedLocation: null,
  officers: [],
  reports: [],
  selectedReport: null,
  editMode: false,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_GET_USERS:
      return {
        ...state,
        users: action.data.users,
      };
    case ADMIN_GET_USER:
      return {
        ...state,
        selectedUser: action.data.user,
      };
    case ADMIN_EDIT_USER:
      return {
        ...state,
        editMode: true,
        selectedUser: action.data.user,
      };
    case ADMIN_DELETE_USER:
      return {
        ...state,
        selectedUser: null,
      };
    case ADMIN_GET_TEAMS:
      return {
        ...state,
        teams: action.data.teams,
      };
    case ADMIN_GET_TEAM:
      return {
        ...state,
        selectedTeam: action.data.team,
      };
    case ADMIN_CREATE_TEAM:
      return {
        ...state,
        selectedTeam: action.data.team,
      };
    case ADMIN_EDIT_TEAM:
      return {
        ...state,
        selectedTeam: action.data.team,
      };
    case ADMIN_DELETE_TEAM:
      return {
        ...state,
        selectedTeam: null,
      };
    case ADMIN_GET_EVENTS:
      return {
        ...state,
        events: action.data.events,
      };
    case ADMIN_GET_EVENT:
      return {
        ...state,
        selectedEvent: action.data.event,
      };
    case ADMIN_CREATE_EVENT:
      return {
        ...state,
        selectedEvent: action.data.event,
      };
    case ADMIN_EDIT_EVENT:
      return {
        ...state,
        selectedEvent: action.data.event,
      };
    case ADMIN_DELETE_EVENT:
      return {
        ...state,
        selectedEvent: null,
      };
    case ADMIN_GET_MATCHES:
      return {
        ...state,
        matches: action.data.matches,
      };
    case ADMIN_GET_MATCH:
      return {
        ...state,
        selectedMatch: action.data.match,
      };
    case ADMIN_CREATE_MATCH:
      return {
        ...state,
        editMode: false,
        selectedMatch: null,
      };
    case ADMIN_EDIT_MATCH:
      return {
        ...state,
        editMode: true,
        selectedMatch: action.data.match,
      };
    case ADMIN_DELETE_MATCH:
      return {
        ...state,
        selectedMatch: null,
      };
    case ADMIN_GET_LOCATIONS:
      return {
        ...state,
        locations: action.data.locations,
      };
    case ADMIN_GET_LOCATION:
      return {
        ...state,
        selectedLocation: action.data.location,
      };
    case ADMIN_CREATE_LOCATION:
      return {
        ...state,
        selectedLocation: action.data.location,
      };
    case ADMIN_EDIT_LOCATION:
      return {
        ...state,
        selectedLocation: action.data.location,
      };
    case ADMIN_DELETE_LOCATION:
      return {
        ...state,
        selectedLocation: null,
      };
    case ADMIN_GET_OFFICERS:
      return {
        ...state,
        officers: action.data.officers,
      };
    case ADMIN_ASSIGN_EVENT_OFFICERS:
      return {
        ...state,
      };
    case ADMIN_ASSIGN_MATCH_OFFICER:
      return {
        ...state,
      };
    case ADMIN_GET_REPORTS:
      return {
        ...state,
        reports: action.data.reports,
      };
    case ADMIN_GET_REPORT:
      return {
        ...state,
        selectedReport: action.data.report,
      };
    case ADMIN_EDIT_REPORT:
      return {
        ...state,
        selectedReport: action.data.report,
      };
    case ADMIN_CREATE_REPORTS:
      return {
        ...state,
        reports: [...state.reports, action.data.reports],
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default adminReducer;
