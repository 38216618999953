import {
  FETCH_EVENTS,
  SELECT_EVENT,
  JOIN_EVENT,
  EDIT_EVENT,
  JOIN_MATCH,
  FETCH_EVENT_MATCHES,
  SELECT_MATCH,
  CREATE_TEAM,
  GET_TEAMS,
  GET_COUNTRIES,
  GET_CITY,
  CREATE_VENUE,
  GET_VENUES,
  CREATE_MATCH,
  EDIT_MATCH,
  CREATE_EVENT,
  GET_MATCHES,
  CREATE_DOCUMENT,
  GET_RESERVATION_DETAILS,
} from '../actions/events';
import { LOGOUT } from '../actions/auth';

const initialState = {
  eventList: [],
  selectedEvent: null,
  selectedEventWithMatches: null,
  selectedMatch: null,
  countries: [],
  cities: [],
  editMode: false,
  selectedReservation: null,
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS:
      return {
        ...state,
        eventList: action.data.events,
      };
    case CREATE_EVENT:
      return {
        ...state,
        editMode: false,
      };
    case SELECT_EVENT:
      return {
        ...state,
        selectedEvent: action.data.event,
      };
    case JOIN_EVENT:
      return {
        ...state,
      };
    case EDIT_EVENT:
      return {
        ...state,
        editMode: true,
        selectedEvent: action.data.event,
      };
    case FETCH_EVENT_MATCHES:
      return {
        ...state,
        editMode: true,
        selectedEventWithMatches: action.data.event,
      };
    case SELECT_MATCH:
      return {
        ...state,
        selectedMatch: action.data.match,
      };
    case JOIN_MATCH:
      return {
        ...state,
      };
    case CREATE_MATCH:
      return {
        ...state,
        editMode: false,
        selectedMatch: null,
      };
    case GET_MATCHES:
      return {
        ...state,
        matches: action.data.match,
      };
    case EDIT_MATCH:
      return {
        ...state,
        editMode: true,
      };
    case CREATE_TEAM:
      return {
        ...state,
      };
    case GET_TEAMS:
      return {
        ...state,
        teams: action.data.team,
      };
    case CREATE_VENUE:
      return {
        ...state,
      };
    case GET_VENUES:
      return {
        ...state,
        venues: action.data.location,
      };
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.data.country,
      };
    case GET_CITY:
      return {
        ...state,
        cities: action.data.city,
      };
    case CREATE_DOCUMENT:
      return {
        ...state,
      };

    case GET_RESERVATION_DETAILS:
      return {
        ...state,
        selectedReservation: action.data.reservation,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default eventsReducer;
