import React, { Component } from 'react';
import { Carousel, Card, Col, Row, Grid, Tag, Typography, Button } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import './Home.css';

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, slides: 1 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    let slides = 1;
    if (window.innerWidth < 464) {
      slides = 1;
    } else if (window.innerWidth > 464 && window.innerWidth < 1024) {
      slides = 2;
    } else {
      slides = 3;
    }
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      slides,
    });
  }

  render() {
    return (
      <div style={{ margin: '1rem' }}>
        <Typography.Title level={1}>This page was not found</Typography.Title>
        <Link to="/">
          <Button type="primary">GO HOME</Button>
        </Link>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(NotFound);
