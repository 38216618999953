import {
  GET_REPORTS,
  GET_REPORT,
  EDIT_REPORT,
  PUBLISH_REPORT,
} from '../actions/reports';
import { LOGOUT } from '../actions/auth';

const initialState = {
  reportsList: [],
  selectedReport: null,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS:
      return { ...state, reportsList: action.data.reports };
    case GET_REPORT:
      return { ...state, selectedReport: action.data.report };
    case EDIT_REPORT:
      return { ...state, selectedReport: action.data.report };
    case PUBLISH_REPORT:
      return { ...state, selectedReport: action.data.report };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reportsReducer;
