import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Upload,
  Row,
  Col,
  Divider,
  Image,
  Button,
  Input,
  Select,
  message,
} from "antd";
import actions from "../../../actions";
import "../../UserProfile.css";
import "./EditUser.css";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: props.match.params.id,
      },
    };
    props.dispatch(actions.users.selectUser(this.state.user.id));
  }

  componentDidMount() {
    console.log("Selected User: ", this.props.selectedUser);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedUser != prevProps.selectedUser &&
      this.props.selectedUser
    ) {
      this.setState({
        firstName: this.props.selectedUser
          ? this.props.selectedUser.firstName
          : "",
        lastName: this.props.selectedUser.lastName,
        email: this.props.selectedUser.email,
        organization: this.props.selectedUser.organization,
        mobileNumber: this.props.selectedUser.mobileNumber,
        pressId: this.props.selectedUser.pressId,
        profilePicture: this.props.selectedUser.profilePicture,
        identification: this.props.selectedUser.identification,
        role: this.props.selectedUser.role,
        status: this.props.selectedUser.status
      });
    }
  }

  onSaveData = (e) => {
    e.stopPropagation();

    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      organization: this.state.organization,
      mobileNumber: this.state.mobileNumber,
      pressId: this.state.pressId,
      profilePicture: this.state.profilePicture,
      identification: this.state.identification,
      role: this.state.role,
      status: this.state.status
    };

    this.props.dispatch(actions.admin.editUser(this.state.user.id, data));
  };

  render() {
    const toTitleCase = (str) => {
      if (!str) return null;
      const words = str.replace(/_/g, " ").split(" ");
      const titleCaseWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
      return titleCaseWords.join(" ");
    };

    const self = this;
    const uploadIDProps = {
      name: "file",
      multiple: false,
      maxCount: 1,
      action: `/api/file`,
      beforeUpload: (file) => {
        const isIMG = file.type === "image/png" || "image/jpeg" || "image/jpg";

        if (!isIMG) {
          message.error(`${file.name} is not an image.`);
          return Promise.reject(new Error("File not an image."));
        }
        if (file.size > 5000000) {
          message.error(`${file.name} size should be less than 5 MB`);
          return Promise.reject(
            new Error("File size should be less than 5 MB.")
          );
        }

        return isIMG || Upload.LIST_IGNORE;
      },
      onChange(info) {
        if (info.fileList.length == 1 && info.fileList[0].response) {
          self.setState({
            identification: info.fileList[0].response.fileName,
          });
        }
      },
    };

    const uploadPressProps = {
      name: "file",
      multiple: false,
      maxCount: 1,
      action: `/api/file`,
      beforeUpload: (file) => {
        const isIMG = file.type === "image/png" || "image/jpeg" || "image/jpg";

        if (!isIMG) {
          message.error(`${file.name} is not an image.`);
          return Promise.reject(new Error("File not an image."));
        }
        if (file.size > 5000000) {
          message.error(`${file.name} size should be less than 5 MB`);
          return Promise.reject(
            new Error("File size should be less than 5 MB.")
          );
        }

        return isIMG || Upload.LIST_IGNORE;
      },
      onChange(info) {
        if (info.fileList.length == 1 && info.fileList[0].response) {
          self.setState({
            pressId: info.fileList[0].response.fileName,
          });
        }
      },
    };

    const uploadProfileProps = {
      name: "file",
      multiple: false,
      maxCount: 1,
      action: `/api/file`,
      beforeUpload: (file) => {
        const isIMG = file.type === "image/png" || "image/jpeg" || "image/jpg";

        if (!isIMG) {
          message.error(`${file.name} is not an image.`);
          return Promise.reject(new Error("File not an image."));
        }
        if (file.size > 5000000) {
          message.error(`${file.name} size should be less than 5 MB`);
          return Promise.reject(
            new Error("File size should be less than 5 MB.")
          );
        }

        return isIMG || Upload.LIST_IGNORE;
      },
      onChange(info) {
        if (info.fileList.length == 1 && info.fileList[0].response) {
          self.setState({
            profilePicture: info.fileList[0].response.fileName,
          });
        }
      },
    };

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
            fontWeight: "lighter",
            fontSize: "11px",
          }}
        >
          Upload
        </div>
      </div>
    );

    return (
      <div style={{ backgroundColor: "white" }}>
        <div className="user-profile-data-container">
          <Row>
            <Col xs={24} xl={6} md={24}>
              <div className="user-profile-image-container">
                <Upload
                  {...uploadProfileProps}
                  maxCount={1}
                  multiple={false}
                  listType="picture-card"
                  showUploadList={false}
                  className="edit-user-avatar-uploader"
                >
                  {this.state.pressId ? (
                    <img
                      src={`/api/file/${this.state.profilePicture}`}
                      alt="profile-img"
                      className="circular-edit-user-profile-image"
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
                <div className="user-profile-name">
                  {this.state.firstName} {this.state.lastName}
                </div>
                <div className="user-profile-role">
                  {toTitleCase(this.state.role)}
                </div>
              </div>
            </Col>
            <Col xs={24} xl={18} md={24} className="user-profile-info-column">
              <div className="user-profile-info-header">
                User Info
                <Divider />
              </div>

              <div className="user-profile-data">
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">First Name</div>
                    <div className="entry-input">
                      <Input
                        value={this.state.firstName}
                        onChange={(e) =>
                          this.setState({ firstName: e.target.value })
                        }
                      />
                    </div>
                  </Col>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Last Name</div>
                    <div className="entry-input">
                      <Input
                        value={this.state.lastName}
                        onChange={(e) =>
                          this.setState({ lastName: e.target.value })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Role</div>
                    {this.state.role === "media_officer" ? (
                      <div className="entry-data-cptl">
                        {toTitleCase(this.state.role)}
                      </div>
                    ) : (
                      <div>
                        <Select
                          className="edit-user-select"
                          placeholder="Role"
                          value={this.state.role}
                          onChange={(val) => {
                            this.setState({ role: val });
                          }}
                        >
                          <Option value="cameraman">Cameraman</Option>
                          <Option value="camera_technician">
                            Camera Technician
                          </Option>
                          <Option value="commentator">Commentator</Option>
                          <Option value="photographer">Photographer</Option>
                          <Option value="photo_technician">
                            Photo Technician
                          </Option>
                          <Option value="radio_reporter">Radio Reporter</Option>
                          <Option value="tv_reporter">Tv Reporter</Option>
                          <Option value="written_press">Written Press</Option>
                        </Select>
                      </div>
                    )}
                  </Col>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Organization</div>
                    <div className="entry-input">
                      <Input
                        value={this.state.organization}
                        onChange={(e) =>
                          this.setState({ organization: e.target.value })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Email</div>
                    <div className="entry-data">{this.state.email}</div>
                  </Col>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Phone Number</div>
                    <div className="entry-input">
                      <Input
                        value={this.state.mobileNumber}
                        onChange={(e) =>
                          this.setState({ mobileNumber: e.target.value })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Status</div>
                    <div>
                      <Select
                        className="edit-user-select"
                        placeholder="Status"
                        value={this.state.status}
                        onChange={(val) => {
                          this.setState({ status: val });
                        }}
                      >
                        <Option value="pending">Pending</Option>
                        <Option value="rejected">Rejected</Option>
                        <Option value="approved">Approved</Option>
                      </Select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">ID/Passport</div>
                    <div className="entry-image">
                      <Upload
                        {...uploadIDProps}
                        maxCount={1}
                        multiple={false}
                        listType="picture-card"
                        showUploadList={false}
                        className="avatar-uploader"
                      >
                        {this.state.identification ? (
                          <img
                            src={`/api/file/${this.state.identification}`}
                            alt="identification"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </div>
                  </Col>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Press ID</div>
                    <div className="entry-image">
                      <Upload
                        {...uploadPressProps}
                        maxCount={1}
                        multiple={false}
                        listType="picture-card"
                        showUploadList={false}
                        className="avatar-uploader"
                      >
                        {this.state.pressId ? (
                          <img
                            src={`/api/file/${this.state.pressId}`}
                            alt="press-id"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} xl={24} md={24}>
                    <Button
                      className="edit-user-data-btn accept"
                      onClick={this.onSaveData}
                    >
                      Save
                    </Button>
                    <Button
                      className="edit-user-data-btn reject"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.history.goBack();
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedUser: state.users.selectedUser,
  };
}
export default connect(mapStateToProps)(EditUser);
