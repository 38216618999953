import {
  LOADING_NEWS,
  FETCH_NEWS,
  SELECT_NEWS,
  EDIT_NEWS,
  CREATE_NEWS,
  DELETE_NEWS,
  FETCH_TAGS,
} from '../actions/news';
import { LOGOUT } from '../actions/auth';

const initialState = {
  newsList: [],
  pageNumber: 1,
  loading: false,
  tags: [],
  selectedFeed: null,
  editMode: false,
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NEWS:
      return {
        ...state,
        // newsList: action.data.news,
        // newsList: [...state.newsList, ...action.data.news],
        newsList:
          action.pageNumber === 1
            ? action.data.news
            : [...state.newsList, ...action.data.news],
          totalCount: action.data.totalCount,
        pageNumber: action.pageNumber,
      };
    case LOADING_NEWS:
      return {
        ...state,
        loading: action.loading,
      };
    case SELECT_NEWS:
      return {
        ...state,
        selectedFeed: action.data.feed,
      };
    case CREATE_NEWS:
      return {
        ...state,
        editMode: false,
        selectedFeed: null,
      };
    case EDIT_NEWS:
      return {
        ...state,
        editMode: true,
        selectedFeed: action.data.feed,
      };
    case DELETE_NEWS:
      return {
        ...state,
        selectedFeed: null,
      };
    case FETCH_TAGS:
      return {
        ...state,
        tags: action.data.tags,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default newsReducer;
