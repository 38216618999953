import React, { Component } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Upload,
  Checkbox,
  Switch,
} from 'antd';
import { connect } from 'react-redux';
import actions from '../../../actions';
import {
  LoadingOutlined,
  PlusOutlined,
  FileAddOutlined,
  FileOutlined,
} from '@ant-design/icons';
const { Option } = Select;

class CreateReport extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      emails: '',
      message: '',
      eventId: null,
      matchId: null,
      status: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.events.getEvents());
  }
  componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.state.category == "event" &&
    //   prevState.category != this.state.category
    // ) {
    //   this.props.dispatch(actions.events.getEvents());
    // }
    if (this.state.eventId && this.state.eventId != prevState.eventId) {
      this.props.dispatch(actions.events.selectEvent(this.state.eventId));
    }
  }

  onFinish = (values) => {
    this.props.dispatch(
      actions.admin.createReports({
        eventId: values.event,
        matchId: values.match,
        report: {
          name: values.name,
          content: JSON.stringify(
            values.questions.map((q) => ({ question: q, answer: '' }))
          ),
          urgent: false,
        },
      })
    );
  };

  render() {
    return (
      <div>
        <div className="content-title">
          <FileOutlined /> Reports
        </div>
        <Form
          name="reports"
          ref={this.formRef}
          style={{ padding: '10px', fontFamily: 'Roboto', fontWeight: 900 }}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{}}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Report Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input report name!',
              },
            ]}
          >
            <Input placeholder="Report Name" />
          </Form.Item>

          <Form.Item
            label="Event"
            name="event"
            rules={[
              {
                required: true,
                message: 'Please input a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Event"
              showArrow
              onSelect={(v) => {
                this.setState({ eventId: v });
              }}
            >
              {this.props.events &&
                this.props.events.map((event, index) => {
                  return (
                    <Option value={event.id} key={event.id}>
                      {event.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Match"
            name="match"
            rules={[
              {
                required: true,
                message: 'Please select match!',
              },
            ]}
          >
            <Select
              className="emails-select"
              placeholder="Match"
              onSelect={(v) => {
                this.setState({ matchId: v });
              }}
            >
              {this.props.selectedEvent &&
                this.props.selectedEvent.Matches.map((match, index) => {
                  return (
                    <Option value={match.id} key={match.id}>
                      {match.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Questions"
            name="questions"
            rules={[
              {
                required: true,
                message: 'Please input a report questions!',
              },
            ]}
          >
            <Select
              className="emails-select"
              placeholder="Report Questions"
              mode="tags"
              onSelect={(v) => {
                // this.setState({ matchId: v });
              }}
            ></Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 2,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn"
              // loading={this.state.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events.eventList,
    selectedEvent: state.events.selectedEvent,
  };
}
export default connect(mapStateToProps)(CreateReport);
