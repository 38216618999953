import axios from '../lib/api-client';

import { DISPLAY_ERROR_MESSAGE, DISPLAY_INFO_MESSAGE } from './ui';

export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORT = 'GET_REPORT';
export const EDIT_REPORT = 'EDIT_REPORT';
export const PUBLISH_REPORT = 'PUBLISH_REPORT';

const getReports = () => (dispatch) => {
  console.log('in action');
  axios
    .get(`/api/report`)
    .then((response) => {
      dispatch({ type: GET_REPORTS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: GET_REPORTS, data: { reports: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getReport = (id) => (dispatch) => {
  axios
    .get(`/api/report/${id}`)
    .then((response) => {
      dispatch({ type: GET_REPORT, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: GET_REPORT, data: { report: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const editReport = (id, report) => (dispatch) => {
  axios
    .patch(`/api/report/${id}`, { report })
    .then((response) => {
      dispatch({ type: EDIT_REPORT, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: "Report edited successfully.",
      });
    })
    .catch((err) => {
      dispatch({ type: EDIT_REPORT, data: { report: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Unexpected error. Please try again later",
      });
    });
};

const publishReport = (id, report) => (dispatch) => {
  axios
    .patch(`/api/report/${id}`, { report: { ...report, status: "published" } })
    .then((response) => {
      dispatch({ type: PUBLISH_REPORT, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: PUBLISH_REPORT, data: { report: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Unexpected error. Please try again later",
      });
    });
};

export default {
  getReports,
  getReport,
  editReport,
  publishReport,
};
