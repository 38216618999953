import React, { Component } from 'react';
import { Button, Form, Input, Select, DatePicker } from 'antd';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { TrophyOutlined } from '@ant-design/icons';
import moment from "moment";
const { Option } = Select;

class ManageMatchTab extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      homeTeam: null,
      awayTeam: null,
      eventId: null,
      locationId: null,
      seated: false,
      date: null,
      editMode: props.editMode,
      loading: false,
      imageUrl: '',

      match: {
        id: props.match.params.id,
      },
    };
    if (props.editMode) {
      props.dispatch(actions.admin.getMatch(this.state.match.id));
    }
  }

  componentDidMount() {
    this.props.dispatch(actions.admin.getTeams());
    this.props.dispatch(actions.admin.getEvents());
    this.props.dispatch(actions.admin.getLocations());
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedMatch &&
      this.props.selectedMatch !== prevProps.selectedMatch &&
      this.props.editMode
    ) {
      console.log("EDIT MODE ACTIVATED");
      this.setState({
        name: this.props.selectedMatch.name,
        homeTeam: this.props.selectedMatch.HomeTeam.id,
        awayTeam: this.props.selectedMatch.AwayTeam.id,
        event: this.props.selectedMatch.Event.id,
        venue: this.props.selectedMatch.Location.id,
        seated: this.props.selectedMatch.seated,
        reservationOpen: this.props.selectedMatch.reservationOpen,
        date: this.props.selectedMatch.date,
      });
      this.formRef.current.setFieldsValue({
        name: this.props.selectedMatch.name,
        homeTeam: this.props.selectedMatch.HomeTeam.id,
        awayTeam: this.props.selectedMatch.AwayTeam.id,
        event: this.props.selectedMatch.Event.id,
        venue: this.props.selectedMatch.Location.id,
        seated: this.props.selectedMatch.seated,
        reservationOpen: this.props.selectedMatch.reservationOpen,
        date: moment(this.props.selectedMatch.date),
      });
    }
  }

  onChange = (value, dateString) => {
    // console.log("Selected Time: ", value);
    // console.log("Formatted Selected Time: ", dateString);
    this.setState({ date: dateString });
  };
  onOk = (value) => {
    // console.log("onOk: ", value);
  };

  onFinish = (values) => {
    console.log('Match Value: ', values);
    const data = {
      name: values.name,
      homeTeam: values.homeTeam,
      awayTeam: values.awayTeam,
      eventId: values.event,
      locationId: values.venue,
      seated: values.seated,
      reservationOpen: values.reservationOpen,
      date: this.state.date,
    };
    if (this.state.editMode) {
      this.props.dispatch(actions.admin.editMatch(this.state.match.id, data));
    } else {
      this.props.dispatch(actions.admin.createMatch(data));
    }
  };
  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  render() {
    return (
      <div>
        <div className="content-title">
          <TrophyOutlined /> Matches
        </div>
        <Form
          name="matches"
          className="teams-form"
          ref={this.formRef}
          style={{ padding: '10px', fontFamily: 'Roboto', fontWeight: 900 }}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{}}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Match Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input match name!',
              },
            ]}
          >
            <Input placeholder="Match name" />
          </Form.Item>

          <Form.Item
            label="Home Team"
            name="homeTeam"
            rules={[
              {
                required: true,
                message: 'Please select a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Team"
              showArrow
              showSearch
              optionFilterProp="children"
              onChange={(val) => {
                this.setState({ homeTeam: val });
              }}
            >
              {this.props.teams &&
                this.props.teams.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Away Team"
            name="awayTeam"
            rules={[
              {
                required: true,
                message: 'Please select a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Team"
              showArrow
              showSearch
              optionFilterProp="children"
              onChange={(val) => {
                this.setState({ awayTeam: val });
              }}
            >
              {this.props.teams &&
                this.props.teams.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Associated Event"
            name="event"
            rules={[
              {
                required: true,
                message: 'Please select a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Event"
              showArrow
              showSearch
              optionFilterProp="children"
              onChange={(val) => {
                this.setState({ eventId: val });
              }}
            >
              {this.props.events &&
                this.props.events.map((event) => (
                  <Option key={event.id} value={event.id}>
                    {event.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Venue"
            name="venue"
            rules={[
              {
                required: true,
                message: 'Please select a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Venue"
              showArrow
              showSearch
              optionFilterProp="children"
              onChange={(val) => {
                this.setState({ locationId: val });
              }}
            >
              {this.props.venues &&
                this.props.venues.map((event) => (
                  <Option key={event.id} value={event.id}>
                    {event.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Seated"
            name="seated"
            rules={[
              {
                required: true,
                message: 'Please select a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Seated"
              showArrow
              showSearch
              disabled={this.state.editMode}
              optionFilterProp="children"
              onChange={(val) => {
                this.setState({ seated: val });
              }}
            >
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Reservation Open"
            name="reservationOpen"
            rules={[
              {
                required: true,
                message: 'Please select a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Open for reservations"
              showArrow
              showSearch
              optionFilterProp="children"
              onChange={(val) => {
                this.setState({ reservationOpen: val });
              }}
            >
              <Option value={true}>Opened</Option>
              <Option value={false}>Closed</Option>
            </Select>
          </Form.Item>
          
          <Form.Item
            label="Date | Time"
            name="date"
            rules={[
              {
                required: true,
                message: 'Please input a value!',
              },
            ]}
          >
            <DatePicker
              showTime={{ showSecond: false }}
              minuteStep={15}
              onChange={this.onChange}
              onOk={this.onOk}
              showNow={false}
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 2,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn"
              // loading={this.state.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    events: state.admin.events,
    teams: state.admin.teams,
    venues: state.admin.locations,
    selectedMatch: state.admin.selectedMatch,
    editMode: state.admin.editMode,
  };
}
export default connect(mapStateToProps)(ManageMatchTab);
