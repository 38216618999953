import React, { Component } from 'react';
import { Row, Col, Input, Button } from 'antd';
import './FootballPitchMap.css';
import { ArrowRightOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import actions from '../actions';

class FootballPitchMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: {
        id: props.match.params.id,
      },
      match: {
        id: props.match.params.matchId,
      },
      selectedSeat: null,
    };

    props.dispatch(
      actions.events.selectMatch(this.state.event.id, this.state.match.id)
    );
  }

  componentDidMount() {}

  onConfirmSeat = () => {
    this.props.dispatch(
      actions.events.reserveSeat(
        this.state.event.id,
        this.state.match.id,
        this.state.selectedSeat
      )
    );
    // CALL ACTION TO SAVE MATCH WITH THE DATA REQUIRED.
  };

  drawSeatsTopRight = () => {
    const seats = [];
    const maxSeats = 40;

    const bookedSeats = this.props.selectedMatch.Members.filter(
      (m) => !!m.MatchMember.seat
    ).map((m) => m.MatchMember.seat);

    // Draw 8 seats on the right bottom border vertical of the football pitch image
    for (let i = 0; i < 20; i++) {
      const x = 272;
      const y = i * 9 + 34;

      if (seats.length < maxSeats) {
        const seatId = `seat-${i + 61}`;
        const isBooked = bookedSeats.includes(seatId);
        seats.push(
          <g key={`seat-${i + 61}`}>
            <circle
              cx={x}
              cy={y}
              r={4}
              fill={
                this.state.selectedSeat === seatId
                  ? 'red'
                  : isBooked
                  ? 'gray' // Set the fill color for booked seats
                  : 'white' // Set the fill color for available seats
              }
              onClick={() => {
                // Only allow selection for available seats
                if (!isBooked) {
                  this.setState({ selectedSeat: seatId });
                }
              }}
            />
          </g>
        );
      }
    }

    // Draw 12 seats on the right bottom border horizontal of the football pitch image
    for (let i = 0; i < 20; i++) {
      const x = i * 9 + 90;
      const y = 34;

      if (seats.length < maxSeats) {
        const seatId = `seat-${i + 41}`;
        const isBooked = bookedSeats.includes(seatId);
        seats.push(
          <g key={`seat-${i + 41}`}>
            <circle
              cx={x}
              cy={y}
              r={4}
              fill={
                this.state.selectedSeat === seatId
                  ? 'red'
                  : isBooked
                  ? 'gray' // Set the fill color for booked seats
                  : 'white' // Set the fill color for available seats
              }
              onClick={() => {
                // Only allow selection for available seats
                if (!isBooked) {
                  this.setState({ selectedSeat: seatId });
                  console.log('Selected Seat: ', i + 41);
                }
              }}
            />
            {/* <text
              x={x}
              y={y}
              textAnchor="middle"
              fontSize={4}
              dominantBaseline="middle"
              pointerEvents="none"
              fill={
                this.state.selectedSeat === `seat-${i + 41}` ? "white" : "black"
              }
            >
              {`${i + 41}`}
            </text> */}
          </g>
        );
      }
    }

    return seats;
  };

  drawSeatsBottomRight = () => {
    const seats = [];
    const maxSeats = 20;

    const bookedSeats = this.props.selectedMatch.Members.filter(
      (m) => !!m.MatchMember.seat
    ).map((m) => m.MatchMember.seat);

    // Draw 8 seats on the right bottom border vertical of the football pitch image
    for (let i = 0; i < 20; i++) {
      const x = 272;
      const y = i * 9 + 30;

      if (seats.length < maxSeats) {
        const seatId = `seat-${i + 81}`;
        const isBooked = bookedSeats.includes(seatId);
        seats.push(
          <g key={`seat-${i + 81}`}>
            <circle
              cx={x}
              cy={y}
              r={4}
              fill={
                this.state.selectedSeat === seatId
                  ? 'red'
                  : isBooked
                  ? 'gray' // Set the fill color for booked seats
                  : 'white' // Set the fill color for available seats
              }
              onClick={() => {
                // Only allow selection for available seats
                if (!isBooked) {
                  this.setState({ selectedSeat: seatId });
                  console.log('Selected Seat: ', i + 81);
                }
              }}
            />
          </g>
        );
      }
    }

    return seats;
  };

  drawSeatsBottomLeft = () => {
    const seats = [];
    const maxSeats = 20;

    const bookedSeats = this.props.selectedMatch.Members.filter(
      (m) => !!m.MatchMember.seat
    ).map((m) => m.MatchMember.seat);

    // Draw 8 seats on the left bottom border vertical of the football pitch image
    for (let i = 0; i < 20; i++) {
      const x = 20;
      const y = i * 9 + 30;

      if (seats.length < maxSeats) {
        const seatId = `seat-${120 - i}`;
        const isBooked = bookedSeats.includes(seatId);
        seats.push(
          <g key={`seat-${120 - i}`}>
            <circle
              cx={x}
              cy={y}
              r={4}
              fill={
                this.state.selectedSeat === seatId
                  ? 'red'
                  : isBooked
                  ? 'gray' // Set the fill color for booked seats
                  : 'white' // Set the fill color for available seats
              }
              onClick={() => {
                // Only allow selection for available seats
                if (!isBooked) {
                  this.setState({ selectedSeat: seatId });
                  console.log('Selected Seat: ', 120 - i);
                }
              }}
            />
            {/* <text
              x={x}
              y={y}
              textAnchor="middle"
              fontSize={4}
              dominantBaseline="middle"
              pointerEvents="none"
              fill={
                this.state.selectedSeat === `seat-${120 - i}` ? "white" : "black"
              }
            >
              {`${120 - i}`}
            </text> */}
          </g>
        );
      }
    }

    return seats;
  };

  drawSeatsTopLeft = () => {
    const seats = [];
    const maxSeats = 40;

    const bookedSeats = this.props.selectedMatch.Members.filter(
      (m) => !!m.MatchMember.seat
    ).map((m) => m.MatchMember.seat);

    // Draw 8 seats on the left top border vertical of the football pitch image
    for (let i = 0; i < 20; i++) {
      const x = 20;
      const y = i * 9 + 34;

      if (seats.length < maxSeats) {
        const seatId = `seat-${20 - i}`;
        const isBooked = bookedSeats.includes(seatId);
        seats.push(
          <g key={`seat-${20 - i}`}>
            <circle
              cx={x}
              cy={y}
              r={4}
              fill={
                this.state.selectedSeat === seatId
                  ? 'red'
                  : isBooked
                  ? 'gray' // Set the fill color for booked seats
                  : 'white' // Set the fill color for available seats
              }
              onClick={() => {
                // Only allow selection for available seats
                if (!isBooked) {
                  this.setState({ selectedSeat: seatId });
                  console.log('Selected Seat: ', 20 - i);
                }
              }}
            />
          </g>
        );
      }
    }

    // Draw 12 seats on the left top border horizontal of the football pitch image
    for (let i = 0; i < 20; i++) {
      const x = i * 9 + 30;
      const y = 34;

      if (seats.length < maxSeats) {
        const seatId = `seat-${i + 21}`;
        const isBooked = bookedSeats.includes(seatId);
        seats.push(
          <g key={`seat-${i + 21}`}>
            <circle
              cx={x}
              cy={y}
              r={4}
              fill={
                this.state.selectedSeat === seatId
                  ? 'red'
                  : isBooked
                  ? 'gray' // Set the fill color for booked seats
                  : 'white' // Set the fill color for available seats
              }
              onClick={() => {
                // Only allow selection for available seats
                if (!isBooked) {
                  this.setState({ selectedSeat: seatId });
                  console.log('Selected Seat: ', i + 21);
                }
              }}
            />
          </g>
        );
      }
    }

    return seats;
  };

  render() {
    if (!this.props.selectedMatch) {
      return <></>;
    }
    return (
      <div>
        <Row>
          <Col className="seat-selection-col" xs={24} xl={9} md={24}>
            <div className="seat-section-title">Selected Seat</div>
            <div className="seat-section-subtitle">Seat Number</div>
            <div className="seat-number-view">
              <Input
                placeholder="Seat"
                value={
                  this.state.selectedSeat &&
                  this.state.selectedSeat.split('-')[1]
                }
                readOnly
              />
            </div>
            <div className="bullet-container">
              <svg width="100" height="60">
                {/* Unavailable Seats */}
                <circle cx="10" cy="15" r="5" fill="grey" />
                <text x="25" y="20" fill="black" fontSize="12">
                  Unavailable
                </text>

                {/* Available Seat */}
                <circle
                  cx="10"
                  cy="35"
                  r="5"
                  fill="white"
                  //   stroke="black"
                  //   strokeWidth="1"
                />
                <text x="25" y="40" fill="black" fontSize="12">
                  Available
                </text>

                {/* Selected Seats */}
                <circle cx="10" cy="55" r="5" fill="red" />
                <text x="25" y="60" fill="black" fontSize="12">
                  Selected
                </text>
              </svg>
            </div>
            <div>
              <Button
                className="submit-btn"
                disabled={this.state.selectedSeat ? false : true}
                onClick={this.onConfirmSeat}
              >
                Confirm <ArrowRightOutlined />
              </Button>
            </div>
          </Col>
          <Col xs={24} xl={15} md={24}>
            <div>
              <div className="pitch-main-container">
                <div className="map-container">
                  <svg
                    width="300"
                    height="220"
                    style={{
                      position: 'absolute',
                      right: '0px',
                      bottom: '-10px',
                    }}
                  >
                    {this.drawSeatsBottomRight()}
                  </svg>
                  <svg
                    width="300"
                    height="220"
                    style={{ position: 'absolute', right: '0px', top: '-24px' }}
                  >
                    {this.drawSeatsTopRight()}
                  </svg>
                  <svg
                    width="300"
                    height="220"
                    style={{
                      position: 'absolute',
                      left: '6px',
                      bottom: '-10px',
                    }}
                  >
                    {this.drawSeatsBottomLeft()}
                  </svg>
                  <svg
                    width="300"
                    height="220"
                    style={{ position: 'absolute', left: '6px', top: '-24px' }}
                  >
                    {this.drawSeatsTopLeft()}
                  </svg>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedMatch: state.events.selectedMatch,
  };
}
export default connect(mapStateToProps)(FootballPitchMap);
