import React, { Component } from 'react';
import './Events.css';
import { Carousel, Card, Col, Row, Grid, List } from 'antd';
import { connect } from 'react-redux';
import actions from '../actions';
import moment from 'moment';

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(actions.events.getEvents());
  }

  render() {
    return (
      <div className="event-page-container">
        <Row justify="start">
          <Col span={24}>
            <List
              // pagination
              grid={{
                gutter: 48,
                xs: 1,
                sm: 2,
                md: 3,
                // lg: 3,
                // xl: 3,
                // xxl: 5,
              }}
              dataSource={this.props.events}
              renderItem={(event) => (
                <List.Item>
                  <div>
                    <Card
                      style={{
                        width: 260,
                        height: 300,
                        borderRadius: 12,
                      }}
                      key={event.id}
                      cover={
                        <img
                          alt="example"
                          src={`/api/file/${event.banner}`}
                          width="100%"
                          height="220px"
                          style={{
                            objectFit: 'cover',
                            borderRadius: '10px',
                          }}
                        />
                      }
                      bodyStyle={{
                        width: '100%',
                        height: '140px',
                      }}
                      onClick={(e) => {
                        console.log('News Clicked');
                        this.props.history.push(`/events/${event.id}`);
                      }}
                    >
                      <div className="event-page-content">
                        <div className="event-page-content-title">
                          {event.acronym ? `#${event.acronym}` : ''}
                        </div>
                        <div className="event-page-content-description">
                          {event.name}
                        </div>
                        <div className="event-page-content-date">
                          {moment(event.startDate).format('DD-MM-YYYY')} -{' '}
                          {moment(event.endDate).format('DD-MM-YYYY')}
                        </div>
                      </div>
                    </Card>
                  </div>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    events: state.events.eventList,
  };
}
export default connect(mapStateToProps)(Events);
