import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Reservations.css';
import { Button, Card, message, Table, Tag } from 'antd';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  ArrowRightOutlined,
  DownloadOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import actions from '../actions';
import CountryFlag from 'react-world-flags';

class Reservations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {
        id: props.match.params.id,
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.events.getEventMatches(this.state.event.id));
  }

  render() {
    if (!this.props.selectedEvent) {
      return <></>;
    }
    const matches = this.props.selectedEvent.Matches.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    }).map((m) => {
      return {
        id: m.id,
        key: m.id,
        homeTeam: m.HomeTeam,
        awayTeam: m.AwayTeam,
        date: new Date(m.date).toLocaleDateString('en-GB'),
        time: new Date(m.date).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        }),
        venue: m.Location.name,
        Members: m.Members,
        Officers: m.Officers,
        reservationOpen: m.reservationOpen,
      };
    });

    const actionButton = (match) => {
      if (this.props.auth.scopes.includes('admin')) {
        // Handling Admin
        return (
          <a
            style={{ color: 'grey' }}
            onClick={() => {
              message.info('exporting match list');
              this.props.dispatch(
                actions.events.exportMatchList(
                  this.props.selectedEvent.id,
                  match.id
                )
              );
            }}
          >
            <DownloadOutlined style={{ color: 'green' }} />
            Export Match List
          </a>
        );
      } else if (this.props.auth.scopes.includes('officer')) {
        // Handling Officer
        const officerApplication = match.Officers.find(
          (o) => o.userId === this.props.auth.user.id
        );

        if (!officerApplication) {
          if (!match.reservationOpen) {
            return <Tag color="default">Reservation Closed</Tag>;
          } else {
            return (
              <a
                style={{ color: 'grey' }}
                onClick={() => {
                  this.props.dispatch(
                    actions.events.joinMatch(
                      this.props.selectedEvent.id,
                      match.id,
                      'officer'
                    )
                  );
                }}
              >
                Book Match <ArrowRightOutlined style={{ color: 'green' }} />
              </a>
            );
          }
        } else {
          if (officerApplication.MatchOfficer.status === 'pending') {
            return <Tag color="warning">Pending</Tag>;
          } else if (officerApplication.MatchOfficer.status === 'rejected') {
            return <Tag color="error">Rejected</Tag>;
          } else {
            return (
              <a
                style={{ color: 'grey' }}
                onClick={() => {
                  message.info('exporting match list');
                  this.props.dispatch(
                    actions.events.exportMatchList(
                      this.props.selectedEvent.id,
                      match.id
                    )
                  );
                }}
              >
                <DownloadOutlined style={{ color: 'green' }} />
                Export Match List
              </a>
            );
          }
        }
      } else {
        // Handling Member
        const memberApplication = match.Members.find(
          (m) => m.userId === this.props.auth.user.id
        );

        if (!memberApplication) {
          if (!match.reservationOpen) {
            return <Tag color="default">Reservation Closed</Tag>;
          } else {
            return (
              // <a
              //   style={{ color: 'grey' }}
              //   onClick={() => {
              //     this.props.dispatch(
              //       actions.events.joinMatch(
              //         this.props.selectedEvent.id,
              //         match.id,
              //         'member'
              //       )
              //     );
              //   }}
              // >
              //   Book Match <ArrowRightOutlined style={{ color: 'green' }} />
              // </a>
              <Button
                key={match.id}
                className="book-match-button"
                onClick={() => {
                  this.props.dispatch(
                    actions.events.joinMatch(
                      this.props.selectedEvent.id,
                      match.id,
                      'member'
                    )
                  );
                }}
              >
                Book Match <ArrowRightOutlined style={{ color: 'green' }} />
              </Button>
            );
          }
        } else {
          if (memberApplication.MatchMember.status === 'pending') {
            return <Tag color="warning">Pending</Tag>;
          } else if (memberApplication.MatchMember.status === 'rejected') {
            return <Tag color="error">Rejected</Tag>;
          } else if (
            !memberApplication.MatchMember.seatReservationRequired ||
            (memberApplication.MatchMember.seatReservationRequired &&
              !!memberApplication.MatchMember.seat)
          ) {
            return (
              // <a
              //   style={{ color: 'grey' }}
              //   onClick={() => {
              //     this.props.history.push(
              //       `reservations/${memberApplication.MatchMember.ref}`
              //     );
              //   }}
              // >
              //   <EyeOutlined style={{ color: 'grey', marginRight: '2px' }} />
              //   View Ticket
              // </a>
              <Tag color="green">Approved</Tag>
            );
          } else {
            return (
              <a
                style={{ color: 'grey' }}
                onClick={() => {
                  this.props.history.push(`reservations/${match.id}/map`);
                }}
              >
                Seats <ArrowRightOutlined style={{ color: 'green' }} />
              </a>
            );
          }
        }
      }
    };

    const getTeamPicture = (team) => {
      if (team.type === 'club') {
        return (
          <>
            <img
              alt="home team"
              className="circular-image"
              src={`/api/file/${team.logo}`}
            />
            {team.name}
          </>
        );
      } else if (team.type === 'national') {
        return (
          <>
            <CountryFlag
              code={team.Country.code}
              height={32}
              width={32}
              style={{ marginRight: '10px' }}
            />
            {team.name}
          </>
        );
      } else return <>{team.name}</>;
    };

    const columns = [
      {
        title: 'Team 1',
        dataIndex: 'homeTeam',
        render: getTeamPicture,
        onFilter: (value, record) => record.team1.indexOf(value) === 0,
      },
      {
        title: '',
        dataIndex: 'vs',
        responsive: ['lg'],
      },
      {
        title: 'Team 2',
        dataIndex: 'awayTeam',
        render: getTeamPicture,
        onFilter: (value, record) => record.team2.indexOf(value) === 0,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        render: (date) => (
          <>
            <CalendarOutlined
              style={{
                fontSize: '24px',
                color: 'grey',
                verticalAlign: 'bottom',
                marginRight: '3px',
              }}
            />
            {date}
          </>
        ),
      },
      {
        title: 'Time',
        dataIndex: 'time',
        render: (time) => (
          <>
            <ClockCircleOutlined
              style={{
                fontSize: '24px',
                color: 'grey',
                verticalAlign: 'bottom',
                marginRight: '3px',
              }}
            />
            {time}
          </>
        ),
        // responsive: ['lg'],
        sorter: (a, b) => a.time - b.time,
      },
      {
        title: 'Venue',
        dataIndex: 'venue',
        render: (venue) => (
          <>
            <img
              alt="venue"
              src={`/dist/img/place-marker.png`}
              style={{
                width: '36px',
                verticalAlign: 'middle',
              }}
            />
            {venue}
          </>
        ),
        // responsive: ['lg'],
        onFilter: (value, record) => record.venue.indexOf(value) === 0,
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (record) => <div key={record.key}>{actionButton(record)}</div>,
      },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
      // console.log('params', pagination, filters, sorter, extra);
    };

    return (
      <div className="reservations-container">
        <Card
          style={{
            width: '100%',
            backgroundColor: 'black',
            height: '380px',
            backgroundImage: `url('/dist/img/banner-5.jpeg')`,
            backgroundPosition: 'center',
          }}
        >
          <div className="banner">
            <span className="banner-title">
              {this.props.selectedEvent.name}
            </span>
            <span className="banner-subtitle">Matches</span>
          </div>
        </Card>

        <div className="matches-table">
          <Table
            columns={columns}
            dataSource={matches}
            onChange={onChange}
            loading={this.props.ui.loading_join_match}
            // scroll={{ x: "calc(700px + 50%)"}}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    ui: state.ui,
    selectedEvent: state.events.selectedEventWithMatches,
  };
}

export default connect(mapStateToProps)(Reservations);
