import React, { Component } from 'react';
import { Button, Form, Input, Select, message, Card, Tabs, Upload } from 'antd';
import { UploadOutlined, MailOutlined } from '@ant-design/icons';
import './Notifications.css';
import { connect } from 'react-redux';
import axios from '../../lib/api-client';
import actions from '../../actions';
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";

const { Option } = Select;
const { Dragger } = Upload;

class Notifications extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      category: "individuals",
      emails: "",
      message: "",
      eventId: null,
      matchId: null,
      status: "",
      attachments: [],
      loading: false,
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.category == "event" &&
      prevState.category != this.state.category
    ) {
      this.props.dispatch(actions.events.getEvents());
    }

    if (
      this.state.category == "match" &&
      this.state.eventId &&
      this.state.eventId != prevState.eventId
    ) {
      this.props.dispatch(actions.events.selectEvent(this.state.eventId));
    }
  }

  handleEditorChange = (editorState) => {
    this.setState({ editorState });
  };

  onFinish = (values) => {
    console.log("Success:", values);
    this.setState({ loading: true });
    let requestData;

    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const htmlContext = stateToHTML(contentState);
    const plainText = contentState.getPlainText();
    if (plainText.trim().length === 0) {
      this.formRef.current.setFields([
        {
          name: "message",
          value: "",
          errors: ["Email message is required. Please write it."],
        },
      ]);
      return;
    }

    console.log('ATT: ', this.state.attachments);
    switch (values.category) {
      case "individuals":
        requestData = {
          email: {
            mail: {
              subject: values.subject,
              text: htmlContext,
              button: false,
              styled: true,
              rtl: values.language === "arabic" ? true : false
            },
            receivers: values.emails,
            templateName: "genericTemplate",
            attachments: this.state.attachments.map((f) => ({
              filename: f,
            })),
          },
        };
        break;
      case "event":
        requestData = {
          eventReceivers: [
            {
              eventId: values.event,
              status: values.status,
            },
          ],
          email: {
            mail: {
              subject: values.subject,
              text: htmlContext,
              button: false,
              styled: true,
              rtl: values.language === "arabic" ? true : false,
            },
            receivers: [],
            templateName: "genericTemplate",
            attachments: this.state.attachments.map((f) => ({
              filename: f,
            })),
          },
        };
        break;
      case "match":
        requestData = {
          eventReceivers: [
            {
              eventId: values.event,
              status: values.status,
            },
          ],
          matchReceivers: [
            {
              matchId: values.match,
              status: values.status,
            },
          ],
          email: {
            mail: {
              subject: values.subject,
              text: htmlContext,
              button: false,
              styled: true,
              rtl: values.language === "arabic" ? true : false,
            },
            receivers: [],
            templateName: "genericTemplate",
            attachments: this.state.attachments.map((f) => ({
              filename: f,
            })),
          },
        };
        break;
      case "registered":
        console.log("registerd users selected");
        requestData = {
          usersReceivers: [{ status: values.status }],
          email: {
            mail: {
              subject: values.subject,
              text: htmlContext,
              button: false,
              styled: true,
              rtl: values.language === "arabic" ? true : false,
            },
            receivers: [],
            templateName: "genericTemplate",
            attachments: this.state.attachments.map((f) => ({
              filename: f,
            })),
          },
        };
        break;
      default:
        requestData = {
          email: {
            mail: {
              subject: values.subject,
              text: htmlContext,
              button: false,
              styled: true,
              rtl: values.language === "arabic" ? true : false,
            },
            receivers: [],
            templateName: "genericTemplate",
            attachments: this.state.attachments.map((f) => ({
              filename: f,
            })),
          },
        };
        break;
    }

    axios
      .post(`/api/notifications/mail`, requestData)
      .then((res) => {
        message.success("Email sent successfully");
        this.formRef.current.resetFields();
        this.setState({
          loading: false,
          editorState: EditorState.createEmpty(),
        });
      })
      .catch((err) => {
        console.log(err);
        message.error("Error occurred");
        this.setState({ loading: false });
      });
    console.log("request data:", requestData);
    this.setState({ loading: false });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const self = this;
    const uploadAttachmentsProps = {
      name: "file",
      multiple: true,
      action: `/api/file`,
      beforeUpload: (file) => {
        console.log(file.type);

        if (file.size > 10000000) {
          message.error(`${file.name} size should be less than 10 MB`);
          return Promise.reject(
            new Error("File size should be less than 10 MB.")
          );
        }

        return true;
      },
      onChange(info) {
        const newList = [];
        console.log("fileList:", info.fileList);

        info.fileList.forEach((file) => {
          if (file.status && file.status === "done") {
            newList.push(file.response.fileName);
          }
        });
        self.setState({
          attachments: newList,
        });

        console.log(self.state.attachments);
      },
    };

    return (
      <div className="notifications-container">
        <div className="content-title">
          <MailOutlined /> Notifications
        </div>
        <Form
          name="notifications"
          ref={this.formRef}
          style={{ padding: "10px", fontFamily: "Roboto", fontWeight: 900 }}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{ category: "individuals", language: "arabic" }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Subject"
            name="subject"
            rules={[
              {
                required: true,
                message: "Please input email subject!",
              },
            ]}
          >
            <Input placeholder="Email Subject" />
          </Form.Item>

          <Form.Item
            label="Category"
            name="category"
            rules={[
              {
                required: true,
                message: "Please input category!",
              },
            ]}
          >
            <Select
              className="emails-select"
              placeholder="Email Category"
              onSelect={(v) => this.setState({ category: v })}
            >
              <Option value="event">Event</Option>
              <Option value="individuals">Individuals</Option>
              <Option value="match">Match</Option>
              <Option value="registered">Registered Users</Option>
            </Select>
          </Form.Item>

          {(this.state.category == "event" ||
            this.state.category == "match") && (
            <Form.Item
              label="Event"
              name="event"
              rules={[
                {
                  required: true,
                  message: "Please select event!",
                },
              ]}
            >
              <Select
                className="emails-select"
                placeholder="Select Event"
                onSelect={(v) => {
                  this.setState({ eventId: v });
                }}
              >
                {this.props.events.map((event, index) => {
                  return (
                    <Option value={event.id} key={event.id}>
                      {event.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          {this.state.category == "match" && (
            <Form.Item
              label="Match"
              name="match"
              rules={[
                {
                  required: true,
                  message: "Please select match!",
                },
              ]}
            >
              <Select
                className="emails-select"
                placeholder="Match"
                onSelect={(v) => {
                  this.setState({ matchId: v });
                }}
              >
                {this.props.selectedEvent &&
                  this.props.selectedEvent.Matches.map((match, index) => {
                    return (
                      <Option value={match.id} key={match.id}>
                        {match.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          )}

          {(this.state.category == "match" ||
            this.state.category == "event" ||
            this.state.category == "registered") && (
            <Form.Item
              label="To"
              name="status"
              rules={[
                {
                  required: true,
                  message: "Please input recipient Email!",
                },
              ]}
            >
              <Select className="emails-select" placeholder="Recipients Emails">
                <Option value="approved">Approved Media</Option>
                <Option value="rejected">Rejected Media</Option>
              </Select>
            </Form.Item>
          )}

          {this.state.category == "individuals" && (
            <Form.Item
              label="To"
              name="emails"
              rules={[
                {
                  required: true,
                  message: "Please input recipient Email!",
                },
              ]}
            >
              <Select
                className="emails-select"
                mode="tags"
                placeholder="Recipients Emails"
              ></Select>
            </Form.Item>
          )}

          <Form.Item
            label="Language"
            name="language"
            rules={[
              {
                required: true,
                message: "Please select a language!",
              },
            ]}
          >
            <Select className="emails-select" placeholder="Email Language">
              <Option value="arabic">Arabic</Option>
              <Option value="english">English</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Message"
            name="message"
            rules={[
              {
                required: true,
                message: "Please input a message!",
              },
            ]}
          >
            <Editor
              editorState={this.state.editorState}
              onEditorStateChange={this.handleEditorChange}
              handlePastedText={() => false}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "list",
                  "link",
                  "history",
                  "textAlign",
                  "fontSize",
                  "fontFamily",
                ],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                fontSize: {
                  options: [
                    8,
                    9,
                    10,
                    11,
                    12,
                    14,
                    16,
                    18,
                    24,
                    30,
                    36,
                    48,
                    60,
                    72,
                    96,
                  ],
                },
                fontFamily: {
                  options: [
                    "Alexandria",
                    "Arial",
                    "Georgia",
                    "Impact",
                    "Tahoma",
                    "Times New Roman",
                    "Roboto",
                    "Verdana",
                  ],
                },
                blockType: {
                  options: ["Normal", "H1", "H2", "H3", "Blockquote"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
                link: {
                  options: ["link", "unlink"],
                },
                history: {
                  options: ["undo", "redo"],
                },
              }}
              mention={false} // disable mention feature
              hashtag={false} // disable hashtag feature
              mentionSuggestions={null} // remove mention suggestions
              toolbarClassName="editor-toolbar"
              wrapperClassName="editor-wrapper"
              editorClassName="editor-content"
              handlePastedFiles={() => false} // disable image upload on paste
              toolbarOnFocus={false} // disable image upload from toolbar
            />
          </Form.Item>

          <Form.Item label="Attachments" name="attachments">
            <Dragger {...uploadAttachmentsProps}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Upload Attachments</p>
            </Dragger>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 2,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn"
              loading={this.state.loading}
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.events.eventList,
    selectedEvent: state.events.selectedEvent,
  };
}
export default connect(mapStateToProps)(Notifications);
