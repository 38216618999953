import React, { Component } from 'react';
import {
  DesktopOutlined,
  CarryOutOutlined,
  MailOutlined,
  TagOutlined,
  TrophyOutlined,
  DribbbleOutlined,
  TeamOutlined,
  FileAddOutlined,
  FilePdfOutlined,
  BankOutlined,
  DatabaseOutlined,
  IdcardOutlined,
  FileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import './AdminLayout.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const { Header, Content, Footer, Sider } = Layout;

class AdminLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      selectedMenuItem: this.props.children.props.location
        ? this.props.children.props.location.pathname.split('/')[3]
        : 'users',
      selectedIcon: <IdcardOutlined />,
      selectedLabel: '',
    };
  }
  componentDidMount() {}

  items = [
    {
      key: 'approvals',
      label: `Approvals`,
      icon: <CarryOutOutlined />,
      children: [
        {
          key: 'users',
          label: (
            <Link to="/admin/approvals/users">
              <span>Users</span>
            </Link>
          ),
          icon: <IdcardOutlined />,
        },
        {
          key: 'tournamentsAccreditations',
          label: (
            <Link to="/admin/approvals/tournaments-accreditations">
              <span>Tournaments</span>
            </Link>
          ),
          icon: <TagOutlined />,
        },
        {
          key: 'matchesTickets',
          label: (
            <Link to="/admin/approvals/match-tickets">
              <span>Matches</span>
            </Link>
          ),
          icon: <TrophyOutlined />,
        },
      ],
    },
    {
      key: 'notifications',
      label: (
        <Link to="/admin/notifications">
          <span>Notifications</span>
        </Link>
      ),
      icon: <MailOutlined />,
    },
    {
      key: 'manage',
      label: `Manage`,
      icon: <DesktopOutlined />,
      children: [
        {
          key: 'all-users',
          label: (
            <Link to="/admin/manage/users">
              <span>Users</span>
            </Link>
          ),
          icon: <UserOutlined />,
        },
        {
          key: 'assignments',
          label: (
            <Link to="/admin/manage/assignments">
              <span>Assignments</span>
            </Link>
          ),
          icon: <TeamOutlined />,
        },
        {
          key: 'news',
          label: (
            <Link to="/admin/manage/news">
              <span>News</span>
            </Link>
          ),
          icon: <FileAddOutlined />,
        },
        {
          key: 'documents',
          label: (
            <Link to="/admin/manage/documents">
              <span>Documents</span>
            </Link>
          ),
          icon: <FilePdfOutlined />,
        },
        {
          key: 'teams',
          label: (
            <Link to="/admin/manage/teams">
              <span>Teams</span>
            </Link>
          ),
          icon: <DribbbleOutlined />,
        },
        {
          key: 'venues',
          label: (
            <Link to="/admin/manage/venues">
              <span>Venues</span>
            </Link>
          ),
          icon: <BankOutlined />,
        },
        {
          key: 'matches',
          label: (
            <Link to="/admin/manage/matches">
              <span>Matches</span>
            </Link>
          ),
          icon: <TrophyOutlined />,
        },
        {
          key: 'tournaments',
          label: (
            <Link to="/admin/manage/tournaments">
              <span>Tournaments</span>
            </Link>
          ),
          icon: <DatabaseOutlined />,
        },
      ],
    },
    {
      key: 'reports',
      label: (
        <Link to="/admin/reports">
          <span>Reports</span>
        </Link>
      ),
      icon: <FileOutlined />,
    },
  ];

  render() {
    return (
      <div>
        <Layout
          style={{
            minHeight: '80vh',
            flexDirection: 'row',
          }}
        >
          <Sider
            collapsible
            title="Admin Panel"
            theme="dark"
            className="admin-sider"
            collapsed={this.state.collapsed}
            onCollapse={(v) => this.setState({ collapsed: v })}
          >
            <div className="sider-title">Admin Panel</div>
            <Menu
              theme="dark"
              mode="inline"
              items={this.items}
              activeKey={this.state.selectedMenuItem}
              onSelect={(e) => {
                console.log(e);
                this.setState({
                  selectedMenuItem: e.key,
                });
              }}
            />
          </Sider>
          {/* <Layout className="site-layout"> */}
          <Content
            style={{
              margin: '0 16px',
            }}
          >
            <div
              style={{
                padding: 5,
                minHeight: 360,
              }}
            >
              {this.props.children}
            </div>
          </Content>
          {/* </Layout> */}
        </Layout>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    // currentPath: state.routing.location.pathname,
  };
}
export default connect(mapStateToProps)(AdminLayout);
