import { LOADING_JOIN_EVENT, LOADING_JOIN_MATCH } from '../actions/events';
import { DISPLAY_INFO_MESSAGE, DISPLAY_ERROR_MESSAGE } from '../actions/ui';

const initialState = {
  infoMessage: null,
  errorMessage: null,
  editMode: false,
  loading_join_event: false,
  loading_join_match: false,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_INFO_MESSAGE:
      return {
        ...state,
        infoMessage: action.data,
      };
    case DISPLAY_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.data,
      };
    case LOADING_JOIN_EVENT:
      return {
        ...state,
        loading_join_event: action.loading,
      };
    case LOADING_JOIN_MATCH:
      return {
        ...state,
        loading_join_match: action.loading,
      };
    default:
      return state;
  }
};

export default uiReducer;
