import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TagOutlined } from "@ant-design/icons";
import { Button, Input, Table } from 'antd';
import './Tables.css';
import actions from '../../../actions';
const { Search } = Input;

class TournamentsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    };
  }
  componentDidMount() {
    this.props.dispatch(
      actions.requests.getRequests({ type: "join_event", pageSize: 1000 })
    );
  }

  onSearch = (value) => {
    this.setState({ searchText: value });
  };

  handleSearchChange = (e) => {
    this.setState({ searchText: e.target.value });
  };

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '25%',
        align: 'left',
        // render: (name) => (
        //   <a className="table-name-btn" onClick={() => message.info(name)}>
        //     {name}
        //   </a>
        // ),
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        width: '20%',
        align: 'left',
        responsive: ['lg'],
      },
      {
        title: 'Role',
        dataIndex: 'role',
        width: '10%',
        align: 'left',
        responsive: ['md'],
      },
      {
        title: 'Tournament',
        dataIndex: 'tournament',
        width: '20%',
        align: 'left',
      },
      {
        title: 'Action',
        dataIndex: '',
        width: '15%',
        key: 'x',
        render: (record) => (
          <div className="table-actions">
            <Button
              className="table-btn accept"
              onClick={(e) => {
                e.preventDefault();
                this.props.dispatch(
                  actions.requests.handleRequest(
                    record.key,
                    {
                      status: "approved",
                    },
                    { type: "join_event", pageSize: 1000 }
                  )
                );
                // message.success(`Accepting on ${record.name}`);
              }}
            >
              Accept
            </Button>
            <Button
              className="table-btn reject"
              onClick={(e) => {
                e.preventDefault();
                this.props.dispatch(
                  actions.requests.handleRequest(
                    record.key,
                    {
                      status: "rejected",
                    },
                    { type: "join_event", pageSize: 1000 }
                  )
                );
                // message.error(`Rejecting on ${record.name}`);
              }}
            >
              Reject
            </Button>
          </div>
        ),
      },
    ];

    const tournamentRequests = this.props.requests.requestList
      .map((request) => {
        let profile;
        if (request.memberId) {
          profile = request.Member;
        }
        if (request.officerId) {
          profile = request.Officer;
        }
        if (!profile) return null;
        return {
          key: request.id,
          name: `${profile.User.firstName} ${profile.User.lastName}`,
          organization: profile.organization,
          role: profile.role,
          email: profile.User.email,
          tournament: request.Event ? request.Event.name : '',
        };
      })
      .filter((request) => !!request);

    const onChange = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
    };

     const filteredUsers =
       this.state.searchText && tournamentRequests
         ? tournamentRequests.filter((profile) => {
             const searchText = this.state.searchText.toLowerCase();
             return (
               profile.name.toLowerCase().includes(searchText) ||
               profile.organization.toLowerCase().includes(searchText)
             );
           })
         : tournamentRequests;

    return (
      <div>
        <div className="content-title">
          <TagOutlined /> Tournaments ({filteredUsers.length || 0})
        </div>
        <div className="tabs-table-container">
          <div className="table-top-bar">
            <div className="news-search">
              <Search
                placeholder="Search"
                onSearch={this.onSearch}
                onChange={this.handleSearchChange}
                value={this.state.searchText}
                style={{
                  width: "25vw",
                }}
              />
            </div>
          </div>
          <Table
            className="tabs-table"
            // scroll={{ x: "calc(700px + 50%)"}}
            columns={columns}
            dataSource={filteredUsers}
            onChange={onChange}
            onRow={(record, recordIndex) => ({
              onClick: (event) => {
                console.log(
                  "onRow onClick",
                  event.target,
                  event.target.className,
                  record,
                  recordIndex
                );
              },
            })}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    requests: state.requests,
  };
}

export default connect(mapStateToProps)(TournamentsTab);
