import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, message, Table, Input } from 'antd';
import '../Approvals/Tables.css';
import './Manage.css';
import {
  DeleteOutlined,
  FormOutlined,
  FileAddOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import actions from '../../../actions';
const { Search } = Input;

class NewsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoading: false,
      loading: false,
      pageNumber: 1,
      pageSize: 1000,
    };
  }
  componentDidMount() {
    this.props.dispatch(actions.news.getNews(this.state.pageSize, this.state.pageNumber));
  }
  onSearch = (value) => console.log(value);

  render() {
    const columns = [
      {
        title: "Media",
        dataIndex: "media",
        width: "5%",
        align: "left",
        render: (media) => (
          <img
            className="news-table-image"
            alt="user-image"
            src={`/api/file/${media}`}
          />
        ),
      },
      {
        title: "Title",
        dataIndex: "title",
        width: "40%",
        align: "left",
      },
      {
        title: "Event",
        dataIndex: "event",
        width: "25%",
        align: "left",
        responsive: ["lg"],
      },
      {
        title: "Date",
        dataIndex: "date",
        width: "10%",
        align: "left",
        responsive: ["md"],
      },
      {
        title: "Action",
        dataIndex: "",
        width: "10%",
        key: "x",
        render: (record) => (
          <div className="table-actions">
            <FormOutlined
              className="table-icons"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                this.props.dispatch({ type: "EDIT_NEWS", data: record });
                this.props.history.push(`/admin/manage/news/${record.key}`);
              }}
            />
            <DeleteOutlined
              className="table-icons"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.props.dispatch(actions.news.deleteNews(record.key));
                // this.props.dispatch(actions.news.getNews());
              }}
            />
          </div>
        ),
      },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
      console.log("Params: ", pagination, filters, sorter, extra);
    };

    const newsData = this.props.news.map((n) => {
      return {
        key: n.id,
        media: n.banner,
        title: n.title,
        text: n.text,
        event: n.Event ? n.Event.name : "N/A",
        date: new Date(n.createdAt).toLocaleDateString("en-GB"),
      };
    });
    return (
      <div>
        <div className="content-title">
          <FileAddOutlined /> News
        </div>
        <div className="table-container">
          <div className="table-top-bar">
            <div className="news-search">
              {/* <Search
                placeholder="Search"
                onSearch={this.onSearch}
                style={{
                  width: "25vw",
                }}
              /> */}
            </div>
            <Button
              className="news-addbtn"
              onClick={() => {
                this.props.dispatch({ type: "CREATE_NEWS" });
                this.props.history.push(`/admin/manage/news/create`);
              }}
            >
              <PlusCircleOutlined /> Add
            </Button>
          </div>
          <Table
            className="news-table"
            // scroll={{ x: "calc(700px + 50%)"}}
            columns={columns}
            dataSource={newsData}
            onChange={onChange}
            onRow={(record, recordIndex) => ({
              onClick: (event) => {
                console.log(
                  "onRow onClick",
                  record,
                  // recordIndex,
                  this.props
                );
                // this.props.history.push(
                //   `/admin/manage/news/${record.key}`
                // );
              },
            })}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    news: state.news.newsList,
  };
}
export default connect(mapStateToProps)(NewsTable);
