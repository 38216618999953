import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Table, Modal, Input } from 'antd';
import './Tables.css';
import { IdcardOutlined } from '@ant-design/icons';
import actions from '../../../actions';
const { Search } = Input;

class UsersTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRejectModal: false,
      selectedRequestId: null,
      rejectionReason: '',
      searchText: ""
    };
  }

  componentDidMount() {
    this.props.dispatch(
      actions.requests.getRequests({ type: 'create_profile', pageSize: 1000  })
    );
  }

  onSearch = (value) => {
    this.setState({ searchText: value });
  };

  handleSearchChange = (e) => {
    this.setState({ searchText: e.target.value });
  };

  handleOk = () => {
    if (this.state.selectedRequestId) {
      this.props.dispatch(
        actions.requests.handleRequest(
          this.state.selectedRequestId,
          {
            status: "rejected",
            rejectionReason: this.state.rejectionReason,
          },
          { type: "create_profile", pageSize: 1000 }
        )
      );
    }

    this.setState({
      openRejectModal: false,
      rejectionReason: '',
      selectedRequestId: null,
    });
  };

  handleCancel = () => {
    this.setState({
      openRejectModal: false,
      rejectionReason: '',
      selectedRequestId: null,
    });
  };

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '25%',
        align: 'left',
        // render: (name) => (
        //   <a
        //     className="table-name-btn"
        //     onClick={() => {
        //       message.info(name);
        //       console.log(this.props);
        //     }}
        //   >
        //     {name}
        //   </a>
        // ),
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        width: '20%',
        align: 'left',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        width: '10%',
        align: 'left',
        // responsive: ['md'],
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '20%',
        align: 'left',
        // responsive: ['lg'],
      },
      {
        title: 'Action',
        dataIndex: '',
        width: '15%',
        key: 'x',
        render: (record) => {
          if (record.status === 'pending') {
            return (
              <div className="table-actions">
                <Button
                  className="table-btn accept"
                  onClick={(e) => {
                    console.log(record);
                    e.stopPropagation();
                    e.preventDefault();
                    this.props.dispatch(
                      actions.requests.handleRequest(
                        record.key,
                        {
                          status: "approved",
                        },
                        { type: "create_profile", pageSize: 1000 }
                      )
                    );
                  }}
                >
                  Accept
                </Button>
                <Button
                  className="table-btn reject"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    // this.props.dispatch(
                    //   actions.requests.handleRequest(record.key, {
                    //     status: 'rejected',
                    //   })
                    // );
                    this.setState({
                      selectedRequestId: record.key,
                      openRejectModal: true,
                    });
                  }}
                >
                  Reject
                </Button>
              </div>
            );
          }
          if (record.status === 'approved') {
            return <p>approved</p>;
          }
          if (record.status === 'rejected') {
            return <p>rejected</p>;
          }
        },
      },
    ];

    const profileRequests = this.props.requests.requestList
      .map((request) => {
        let profile;
        if (request.memberId) {
          profile = request.Member;
        }
        if (request.officerId) {
          profile = request.Officer;
        }
        if (!profile) return null;
        return {
          key: request.id,
          name: `${profile.User.firstName} ${profile.User.lastName}`,
          organization: profile.organization,
          role: profile.role,
          email: profile.User.email,
          userId: profile.userId,
          status: request.status,
        };
      })
      .filter((request) => !!request);

    const onChange = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
    };

    const filteredUsers =
      this.state.searchText && profileRequests
        ? profileRequests.filter((profile) => {
            const searchText = this.state.searchText.toLowerCase();
            return (
              profile.name.toLowerCase().includes(searchText) ||
              profile.email.toLowerCase().includes(searchText) ||
              profile.organization.toLowerCase().includes(searchText)
            );
          })
        : profileRequests;

    return (
      <div>
        <div className="content-title">
          <IdcardOutlined /> Users ({filteredUsers.length || 0})
        </div>
        <div className="tabs-table-container">
          <div className="table-top-bar">
            <div className="news-search">
              <Search
                placeholder="Search"
                onSearch={this.onSearch}
                onChange={this.handleSearchChange}
                value={this.state.searchText}
                style={{
                  width: "25vw",
                }}
              />
            </div>
          </div>
          <Table
            className="tabs-table"
            // scroll={{ x: "calc(700px + 50%)"}}
            columns={columns}
            dataSource={filteredUsers}
            onChange={onChange}
            onRow={(record, recordIndex) => ({
              onClick: (event) => {
                console.log(
                  "onRow onClick",
                  record,
                  // recordIndex,
                  this.props
                );
                this.props.dispatch(actions.requests.selectRequest(record.key));
                this.props.history.push(`/user-details/${record.userId}`);
                // this.props.history.push({
                //   pathname: `/user-details/${record.userId}`,
                //   state: { requestID: record.key },
                // });
              },
            })}
          />
        </div>
        <Modal
          title="Rejection Reason"
          open={this.state.openRejectModal}
          className="rejection-modal"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelButtonProps={{ className: "table-btn reject" }}
          okButtonProps={{
            disabled: !this.state.rejectionReason,
            className: "table-btn accept",
          }}
          okText="Submit"
        >
          <div className="rejection-modal-text">
            Please describe briefly the reason behind rejecting this profile
          </div>
          <div>
            <Input.TextArea
              rows={5}
              placeholder="Description"
              value={this.state.rejectionReason}
              onChange={(e) =>
                this.setState({ rejectionReason: e.target.value })
              }
            />
            {this.state.showValidationMessage &&
              !this.state.rejectionReason && (
                <div className="rejection-modal-error-message">
                  Rejection reason is required
                </div>
              )}
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    requests: state.requests,
  };
}

export default connect(mapStateToProps)(UsersTab);
