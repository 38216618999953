import admin from './admin';
import auth from './auth';
import events from './events';
import news from './news';
import reports from './reports';
import requests from './requests';
import users from './users';
import documents from "./documents";
import ui from './ui';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  admin,
  auth,
  events,
  news,
  reports,
  requests,
  users,
  documents,
  ui,
});

export default rootReducer;
