import axios from "../lib/api-client";

import { DISPLAY_ERROR_MESSAGE, DISPLAY_INFO_MESSAGE } from "./ui";

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const CREATE_DOCUMENT = "CREATE_DOCUMENT";
export const LOADING_DOCUMENTS = "LOADING_DOCUMENTS";


const getDocuments = (options = {}) => (dispatch) => {
    const {pageNumber} = options;
  dispatch({ type: LOADING_DOCUMENTS, loading: true });
  axios
    .get(
      `/api/document?pageSize=${options.pageSize || 10}&page=${options.page || 1}`
    )
    .then((response) => {
      dispatch({ type: GET_DOCUMENTS, data: response.data.data, pageNumber });
      dispatch({ type: LOADING_DOCUMENTS, loading: false });
    })
    .catch((err) => {
      dispatch({ type: GET_DOCUMENTS, data: { documents: [] }, pageNumber });
      dispatch({ type: LOADING_DOCUMENTS, loading: false });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Unexpected error. Please try again later",
      });
    });
};

const getDocument = (id) => (dispatch) => {
  axios
    .get(`/api/document/${id}`)
    .then((response) => {
      dispatch({ type: GET_DOCUMENT, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: GET_DOCUMENT, data: { document: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Unexpected error. Please try again later",
      });
    });
};

const createDocument = (file) => (dispatch) => {
  axios
    .post(`/api/document/`, { file })
    .then((response) => {
      dispatch({ type: CREATE_DOCUMENT });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: "Document created successfully!",
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Unexpected error. Please try again later",
      });
    });
};

export default {
  getDocuments,
  getDocument,
  createDocument,
};
