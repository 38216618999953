import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Divider,
  Upload,
  Button,
  Input,
  Image,
  List,
  Select,
} from "antd";
import "./ViewReport.css";
import { connect } from "react-redux";
import { UploadOutlined, MailOutlined } from "@ant-design/icons";
import actions from "../../../actions";

const { Dragger } = Upload;
const { Option } = Select;

class ViewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "draft",
      attachments: [],
      urgent: false,
      report: {
        id: props.match.params.id,
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.admin.getReport(this.state.report.id));
  }

  onFinish = (values) => {
    console.log("OnFinish: ", values);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    return (
      <div>
        <div className="view-reports-data-container">
          <Row>
            <Col xs={24} xl={6} md={24}>
              <div className="view-reports-info-header">
                Report Info
                <Divider />
              </div>

              <div className="view-reports-data">
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="view-reports-entry-title">Tournament</div>
                    <div className="view-reports-entry-data">
                      {this.props.selectedReport
                        ? this.props.selectedReport.Event.name
                        : ""}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="view-reports-entry-title">Match</div>
                    <div className="view-reports-entry-data">
                      {this.props.selectedReport
                        ? this.props.selectedReport.Match.name
                        : ""}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="view-reports-entry-title">Venue</div>
                    <div className="view-reports-entry-data-cptl">
                      {this.props.selectedReport
                        ? this.props.selectedReport.Match.Location.name
                        : ""}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="view-reports-entry-title">Date</div>
                    <div className="view-reports-entry-data">
                      {new Date(
                        this.props.selectedReport &&
                          this.props.selectedReport.updatedAt
                      ).toLocaleDateString("en-GB")}{" "}
                      {new Date(
                        this.props.selectedReport &&
                          this.props.selectedReport.updatedAt
                      ).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="view-reports-entry-title">Report Name</div>
                    <div className="view-reports-entry-data">
                      {this.props.selectedReport
                        ? this.props.selectedReport.name
                        : ""}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="view-reports-entry-title">Status</div>
                    <div className="view-reports-entry-data">
                      {this.props.selectedReport
                        ? this.props.selectedReport.status
                        : ""}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} xl={18} md={24} className="view-reports-info-column">
              <div className="view-reports-info-header">
                Report Questions
                <Divider />
              </div>

              <div className="view-reports-questions">
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    xxl: 2,
                  }}
                  dataSource={
                    this.props.selectedReport
                      ? JSON.parse(this.props.selectedReport.content)
                      : []
                  }
                  renderItem={(item, index) => (
                    <List.Item>
                      <Card
                        key={index}
                        title={`Q${index + 1}: ${item.question}`}
                      >
                        {item.answer || "No answers submitted yet."}
                      </Card>
                    </List.Item>
                  )}
                />
              </div>

              <div className="view-reports-info-header">
                Attachments
                <Divider />
              </div>

              <div className="view-reports-attachments">
                <List
                  grid={{
                    gutter: 16,
                    // column: 6,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 3,
                    xl: 3,
                    xxl: 6,
                  }}
                  dataSource={this.props.selectedReport ? JSON.parse(this.props.selectedReport.attachments) : []}
                  renderItem={(item, index) => (
                    <List.Item>
                      <Image
                        className="view-reports-img"
                        alt="attachement-image"
                        src={`/api/file/${item}`}
                        key={index}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedReport: state.admin.selectedReport,
  };
}
export default connect(mapStateToProps)(ViewReport);
