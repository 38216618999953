import React, { Component } from "react";
import {
  UserOutlined,
  UploadOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Checkbox,
  Card,
  Col,
  Row,
  Select,
  Upload,
  message,
  Tooltip,
} from "antd";
import "./Register.css";
import moment from "moment";
import { connect } from "react-redux";
import actions from "../actions";

const { Option } = Select;

// const props = {
//   beforeUpload: (file) => {
//     const isIMG = file.type === "image/png" || "image/jpeg" || "image/jpg";

//     if (!isIMG) {
//       message.error(`${file.name} is not an image.`);
//     }

//     return isIMG || Upload.LIST_IGNORE;
//   },
//   onChange: (info) => {
//     console.log(info.fileList);
//   },
// };

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedPassportFileName: "",
      originalPassportName: "Upload ID/Passport",

      uploadedPressFileName: "",
      originalPressName: "Upload Press ID",

      uploadedPersonalPhotoFileName: "",
      originalPersonalPhotoName: "Upload Personal Photo",
    };
  }

  handleKeyPressEnglishLetters = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const englishLettersAndSpaceRegex = /^[A-Za-z\s]+$/;

    if (!englishLettersAndSpaceRegex.test(keyValue)) {
      event.preventDefault();
    }
  };

  handleKeyPressEnglish = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const englishLettersNumbersAndSpaceRegex = /^[a-zA-Z0-9_!@#$%^&*()-+=:;,.<>?/|[\]{} ]*$/;

    if (!englishLettersNumbersAndSpaceRegex.test(keyValue)) {
      event.preventDefault();
    }
  };

  handleKeyPressNumbers = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numbersRegex = /^[0-9+]+$/;

    if (!numbersRegex.test(keyValue)) {
      event.preventDefault();
    }
  };

  onFinish = (values) => {
    console.log("Received values of form: ", values);

    const registerRequest = {
      user: {
        email: values.email,
        password: values.password,
        firstName: values.fname,
        lastName: values.lname,
        birthdate: values.dob,
        mobileNumber: values.phone,
        profilePicture: this.state.uploadedPersonalPhotoFileName,
      },
    };

    if (values.role === "media_officer") {
      registerRequest.officer = {
        organization: values.organization,
        role: values.role,
        identification: this.state.uploadedPassportFileName,
        pressId: this.state.uploadedPressFileName,
      };
    } else {
      registerRequest.member = {
        organization: values.organization,
        role: values.role,
        identification: this.state.uploadedPassportFileName,
        pressId: this.state.uploadedPressFileName,
      };
    }

    this.props.dispatch(actions.auth.register(registerRequest));
  };

  render() {
    const self = this;
    const uploadPassportProps = {
      name: "file",
      multiple: false,
      maxCount: 1,
      action: "/api/file",
      beforeUpload: (file) => {
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes

        if (!allowedTypes.includes(file.type)) {
          message.error(`${file.name} is not an image.`);
          return Upload.LIST_IGNORE; // Skip this file
        }

        if (file.size > maxSize) {
          message.error(`${file.name} size should be less than 2 MB.`);
          return Upload.LIST_IGNORE; // Skip this file
        }

        return true; // Allow the upload
      },
      onChange(info) {
        console.log(info.fileList);
        if (info.fileList.length === 1 && info.fileList[0].response) {
          self.setState({
            uploadedPassportFileName: info.fileList[0].response.fileName,
            originalPassportName: info.fileList[0].name,
          });
        }
      },
    };

    const uploadPressProps = {
      name: "file",
      multiple: false,
      maxCount: 1,
      action: `/api/file`,
      beforeUpload: (file) => {
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes

        if (!allowedTypes.includes(file.type)) {
          message.error(`${file.name} is not an image.`);
          return Upload.LIST_IGNORE; // Skip this file
        }

        if (file.size > maxSize) {
          message.error(`${file.name} size should be less than 2 MB.`);
          return Upload.LIST_IGNORE; // Skip this file
        }

        return true; // Allow the upload
      },
      onChange(info) {
        console.log(info.fileList);
        if (info.fileList.length === 1 && info.fileList[0].response) {
          self.setState({
            uploadedPressFileName: info.fileList[0].response.fileName,
            originalPressName: info.fileList[0].name,
          });
        }
      },
    };

    const uploadPersonalPhotoProps = {
      name: "file",
      multiple: false,
      maxCount: 1,
      action: `/api/file`,
      beforeUpload: (file) => {
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes

        if (!allowedTypes.includes(file.type)) {
          message.error(`${file.name} is not an image.`);
          return Upload.LIST_IGNORE; // Skip this file
        }

        if (file.size > maxSize) {
          message.error(`${file.name} size should be less than 2 MB.`);
          return Upload.LIST_IGNORE; // Skip this file
        }

        return true; // Allow the upload
      },
      onChange(info) {
        console.log(info.fileList);
        if (info.fileList.length === 1 && info.fileList[0].response) {
          self.setState({
            uploadedPersonalPhotoFileName: info.fileList[0].response.fileName,
            originalPersonalPhotoName: info.fileList[0].name,
          });
        }
      },
    };

    return (
      <div className="register-layout">
        <Card className="card-layout" bodyStyle={{ padding: "0" }}>
          <Row>
            <Col xs={24} xl={12} md={24}>
              <p className="form-title">Create Account</p>
              <Form
                name="normal_register"
                className="register-form"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 22,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
              >
                <Row>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="First Name"
                      name="fname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Firstname!",
                        },
                        {
                          pattern: /^[a-zA-Z\s]*$/,
                          message:
                            "Firstname must be in english only with no numbers or special characters",
                        },
                      ]}
                    >
                      <Input
                        placeholder="First name"
                        onKeyPress={this.handleKeyPressEnglishLetters}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Last Name"
                      name="lname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Lastname!",
                        },
                        {
                          pattern: /^[a-zA-Z\s]*$/,
                          message:
                            "Lastname must be in english only with no numbers or special characters",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Last name"
                        onKeyPress={this.handleKeyPressEnglishLetters}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your Email!",
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Date of Birth"
                      name="dob"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Date of birth!",
                        },
                      ]}
                    >
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        placeholder="Date of Birth"
                        size="middle"
                        // disabledDate={(d) => !d || d.isAfter("2002-12-31")}
                        // defaultPickerValue={moment("2002-12-31")}
                        // picker={"none"}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <Input.Password type="password" placeholder="Password" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Confirm Password"
                      name="confirmpassword"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Organization Name"
                      name="organization"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Organization Name!",
                        },
                        {
                          pattern: /^[a-zA-Z0-9_!@#$%^&*()-+=:;,.<>?/|[\]{} ]*$/,
                          message: "Organization name must be in english only",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Organization Name"
                        onKeyPress={this.handleKeyPressEnglish}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Role"
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: "Please select your role!",
                        },
                      ]}
                    >
                      <Select
                        style={{
                          width: "100%",
                          textAlign: "left",
                        }}
                        placeholder="Role"
                      >
                        <Option value="cameraman">Cameraman</Option>
                        <Option value="camera_technician">
                          Camera Technician
                        </Option>
                        <Option value="commentator">Commentator</Option>
                        {/* <Option value="media_manager">Media Manager</Option> */}
                        {/* <Option value="media_officer">Media Officer</Option> */}
                        <Option value="photographer">Photographer</Option>
                        <Option value="photo_technician">
                          Photo Technician
                        </Option>
                        <Option value="radio_reporter">Radio Reporter</Option>
                        <Option value="tv_reporter">Tv Reporter</Option>
                        <Option value="written_press">Written Press</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="National ID/Passport"
                      name="passport"
                      tooltip={{
                        title: (
                          <Tooltip
                            placement="top"
                            title={""}
                            className="image-tooltip"
                          >
                            <ul>
                              <li>Formats: JPG, JPEG, or PNG</li>
                              <li>Image size should not be more than 2 MB</li>
                              <li>
                                Do not use images that are too bright or dark
                              </li>
                              <li>
                                Do not use images where it is too close to or
                                far away from the camera
                              </li>
                            </ul>
                          </Tooltip>
                        ),
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please upload your ID/Passport!",
                        },
                      ]}
                    >
                      <Upload
                        {...uploadPassportProps}
                        maxCount={1}
                        multiple={false}
                        // onChange={this.handleUpload}
                        // beforeUpload={() => false}
                      >
                        <Button style={{ width: "100%", overflow: "hidden" }}>
                          Upload ID/Passport <UploadOutlined />
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Mobile Number"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input your mobile number!",
                        },
                      ]}
                    >
                      <Input
                        type="tel"
                        placeholder="Mobile Number"
                        onKeyPress={this.handleKeyPressNumbers}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Press ID"
                      name="press"
                      tooltip={{
                        title: (
                          <Tooltip
                            placement="top"
                            title={""}
                            className="image-tooltip"
                          >
                            <ul>
                              <li>Formats: JPG, JPEG, or PNG</li>
                              <li>Image size should not be more than 2 MB</li>
                              <li>
                                Do not use images that are too bright or dark
                              </li>
                              <li>
                                Do not use images where it is too close to or
                                far away from the camera
                              </li>
                            </ul>
                          </Tooltip>
                        ),
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please upload your Press ID!",
                        },
                      ]}
                    >
                      <Upload
                        {...uploadPressProps}
                        maxCount={1}
                        wrapperCol={{
                          offset: 0,
                          span: 12,
                        }}
                        multiple={false}
                        // onChange={this.handleUpload}
                        // beforeUpload={() => false}
                      >
                        <Button style={{ width: "100%", overflow: "hidden" }}>
                          Upload Press ID <UploadOutlined />
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Personal Photo"
                      name="presonalPhoto"
                      tooltip={{
                        title: (
                          <Tooltip
                            placement="top"
                            title={""}
                            className="image-tooltip"
                          >
                            <ul>
                              <li>Formats: JPG, JPEG, or PNG</li>
                              <li>Image size should not be more than 2 MB</li>
                              <li>Image must be of clear background</li>
                              <li>
                                Do not use images that are too bright or dark
                              </li>
                              <li>
                                Do not use images where you are too close to or
                                far away from the camera
                              </li>
                            </ul>
                          </Tooltip>
                        ),
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please upload your Personal Photo!",
                        },
                      ]}
                    >
                      <Upload
                        {...uploadPersonalPhotoProps}
                        maxCount={1}
                        wrapperCol={{
                          offset: 0,
                          span: 12,
                        }}
                        multiple={false}
                        // onChange={this.handleUpload}
                        // beforeUpload={() => false}
                      >
                        <Button style={{ width: "100%", overflow: "hidden" }}>
                          Upload Personal Photo <UploadOutlined />
                        </Button>
                      </Upload>
                    </Form.Item>

                    {/* <Form.Item
                      label="Personal Photo"
                      rules={[
                        {
                          required: true,
                          message: "Please upload your Personal Photo!",
                        },
                      ]}
                      validateTrigger="onFinish"
                    >
                      <Input
                        className="upload-logo"
                        suffix={
                          <>
                            <Upload maxCount={1} {...uploadPersonalPhotoProps}>
                              <a className="black-upload">
                                {<UploadOutlined />}
                              </a>
                            </Upload>
                          </>
                        }
                        readOnly
                        defaultValue={this.state.originalPersonalPhotoName}
                        value={this.state.originalPersonalPhotoName}
                      />
                    </Form.Item> */}
                  </Col>
                </Row>

                <Row>
                  <Form.Item
                    name="terms"
                    valuePropName="checked"
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Should accept terms and conditions")
                              ),
                      },
                    ]}
                  >
                    <Checkbox className="terms-conditions">
                      I have read the <a href="/terms-of-use">Terms</a> and{" "}
                      <a href="/privacy-policy">Privacy Policy</a>
                    </Checkbox>
                  </Form.Item>
                </Row>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="register-form-button"
                  >
                    Create Account
                  </Button>
                </Form.Item>

                <Row>
                  <Col xs={24} xl={24} md={24} sm={24}>
                    <Form.Item>
                      <div style={{ fontSize: "14px" }} className="haveAccount">
                        Already have an account ? <a href="/login">Login</a>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={24} xl={12} md={24} style={{ alignSelf: "center" }}>
              <img
                src="/dist/img/vertical-banner.jpeg"
                className="register-img"
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Register);
