import React, { Component } from 'react';
import { Card, Button, Form, Input, Select, message, Row, Col } from 'antd';
import './ContactUs.css';
import { connect } from 'react-redux';
import axios from '../lib/api-client';
import { ClockCircleOutlined } from '@ant-design/icons';
const { Option } = Select;

class Contacts extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {};
  }

  onFinish = (values) => {
    this.setState({ loading: true });

    let topic;
    switch (values.topic) {
      case 'complaint':
        topic = 'Complaint';
        break;
      case 'technical_issue':
        topic = 'Technical Issue';
        break;
      case 'suggestion':
        topic = 'Suggestion';
        break;
      default:
        topic = 'General Inquiry';
        break;
    }

    const requestData = {
      email: {
        mail: {
          subject: `Contact Us Form - ${topic} | ${this.props.auth.user.email}`,
          text: `<b>From: ${this.props.auth.user.email}</b><br />${values.message}`,
          button: false,
        },
        receivers: ['info@9inety.net'],
        templateName: 'genericTemplate',
      },
    };

    axios
      .post(`/api/notifications/mail`, requestData)
      .then((res) => {
        message.success('Email sent successfully');
        this.formRef.current.resetFields();
      })
      .catch((err) => {
        console.log(err);
        message.error('An error occurred! Please try again later.');
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error(errorInfo);
  };

  render() {
    const text = `For information on a topic, interview requests, please contact the
            Communications Division at: 
            
            E-mail: ${process.env.REACT_APP_PUBLIC_EMAIL}
            Telephone: ${process.env.REACT_APP_PUBLIC_TEL}
            
            Do you have a question, comment or idea regarding
            the registration? Please tell us what you think! 
            
            Write to ${process.env.REACT_APP_PUBLIC_EMAIL}. Your feedback assists in the continuous
            improvement of the media registration and other media services.`;
    return (
      <div className='contacts-container'>
        <Card
          style={{
            width: '100%',
            backgroundColor: 'black',
            height: '380px',
            backgroundImage: `url('dist/img/banner-2.jpeg')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <div className="banner">
            <span className="banner-title">Contact Us</span>
          </div>
        </Card>

        {this.props.auth.loggedIn && this.props.auth.status === 'approved' ? (
          <div style={{ backgroundColor: 'white' }}>
            <Row>
              <Col xs={24} xl={12} md={24}>
                <div className="form-main-title">Get In Touch</div>
                <Form
                  name="notifications"
                  ref={this.formRef}
                  style={{
                    padding: '60px',
                    fontFamily: 'Roboto',
                    fontWeight: 900,
                  }}
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  initialValues={{}}
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Topic"
                    name="topic"
                    rules={[
                      {
                        required: true,
                        message: 'Please select topic!',
                      },
                    ]}
                  >
                    <Select
                      className="topic-select"
                      placeholder="Please select a topic"
                    >
                      <Option value="complaint">Complaint</Option>
                      <Option value="general_inquiry">General Inquiry</Option>
                      <Option value="suggestion">Suggestion</Option>
                      <Option value="technical_issue">Technical Issue</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Message"
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: 'Please input a message!',
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="Message" rows={5} />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 2,
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-large"
                      loading={this.state.loading}
                    >
                      Send Message
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={24} xl={12} md={24}>
                <div className="social-media-title">Follow Us!</div>
                <div>
                  <div
                    className="social"
                    style={{
                      justifyContent: 'center',
                    }}
                  >
                    <ul style={{ padding: '0px' }}>
                      <li style={{ display: 'inline-block' }}>
                        <a href={`${process.env.REACT_APP_FACEBOOK}`} target={'_blank'}>
                          <img
                            src={'/dist/img/Facebook.png'}
                            width="25px"
                            height="auto"
                            alt="facebook"
                          />
                        </a>
                      </li>
                      <li
                        style={{ display: 'inline-block', marginLeft: '20px' }}
                      >
                         <a href={`${process.env.REACT_APP_TWITTER}`} target={'_blank'}>
                          <img
                            src={'/dist/img/Twitter.png'}
                            width="27px"
                            height="auto"
                            alt="twitter"
                          />
                        </a>
                      </li>
                      <li
                        style={{ display: 'inline-block', marginLeft: '20px' }}
                      >
                         <a href={`${process.env.REACT_APP_INSTAGRAM}`} target={'_blank'}>
                          <img
                            src={'/dist/img/Instagram.svg'}
                            width="38px"
                            height="auto"
                            alt="instagram"
                          />
                        </a>
                      </li>
                      <li style={{ display: 'inline-block', marginLeft: '20px' }}>
                         <a href={`${process.env.REACT_APP_TIKTOK}`} target={'_blank'}>
                          <img
                            src={'/dist/img/Tiktok.png'}
                            width="27px"
                            height="auto"
                            alt="tiktok"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="location-title">
                  <img
                    width={35}
                    style={{ verticalAlign: 'top' }}
                    src={`./dist/img/place-marker.png`}
                  />
                  Location
                </div>
                <div className="sub-text">{`${process.env.REACT_APP_LOCATION}`}</div>
                <div className="hours-title">
                  <ClockCircleOutlined style={{ color: 'gray' }} /> Working
                  Hours
                </div>
                <div className="sub-text">{`${process.env.REACT_APP_WORKING_HOURS}`}</div>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="description">{text}</div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}
export default connect(mapStateToProps)(Contacts);
