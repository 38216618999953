import axios from '../lib/api-client';

import { DISPLAY_INFO_MESSAGE, DISPLAY_ERROR_MESSAGE } from './ui';

export const LOGIN = 'LOGIN';
export const LOADING_LOGIN = "LOADING_LOGIN";
export const REFRESH = 'REFRESH';
export const REGISTER = 'REGISTER';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const LOGOUT = 'LOGOUT';

const login = (user) => (dispatch) => {
  dispatch({ type: LOADING_LOGIN, loading: true });
  axios
    .post(`/api/auth/login`, user)
    .then((response) => {
      dispatch({ type: LOGIN, data: response.data.data });
      dispatch({ type: LOADING_LOGIN, loading: false });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: LOADING_LOGIN, loading: false });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const refresh = (token, refreshToken) => (dispatch) => {
  dispatch({ type: REFRESH, data: { token, refreshToken } });
};

const register = (user) => (dispatch) => {
  axios
    .post(`/api/auth/register`, user)
    .then((response) => {
      dispatch({ type: REGISTER, data: response.data.data });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const setRedirectUrl = (url) => (dispatch) => {
  dispatch({ type: SET_REDIRECT_URL, data: url });
};

const forgetPassword = (email) => (dispatch) => {
  axios
    .post(`/api/auth/forgot-password`, { email })
    .then((response) => {
      dispatch({ type: FORGET_PASSWORD, data: response.data.data });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const resetPassword = (email, resetPasswordToken, password) => (dispatch) => {
  axios
    .post(`/api/auth/reset-password`, {
      email,
      password,
      resetPasswordToken,
    })
    .then((response) => {
      dispatch({ type: RESET_PASSWORD, data: true });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Password reset successfully.',
      });
    })
    .catch((err) => {
      dispatch({ type: RESET_PASSWORD, data: false });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const logout = () => (dispatch) => dispatch({ type: LOGOUT });

export default {
  login,
  refresh,
  register,
  logout,
  setRedirectUrl,
  forgetPassword,
  resetPassword,
};
