import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { Button, Form, Input, Select, message, Card, Tabs, Upload } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Option } = Select;

let timeout;
let currentValue;
class Assignments extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      category: 'event',
      eventId: null,
      matchId: null,
      officerId: null,
      data: [],
      officers: [],
      searchValue: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.admin.getEvents());
  }

  componentDidUpdate(prevProps, prevState) {
    // Event

    if (this.state.eventId && this.state.eventId != prevState.eventId) {
      this.props.dispatch(actions.admin.getEvent(this.state.eventId));
    }

    if (
      this.props.selectedEvent &&
      this.props.selectedEvent.Officers &&
      this.props.selectedEvent !== prevProps.selectedEvent
    ) {
      const selectedOfficers =
        this.props.selectedEvent && this.props.selectedEvent.Officers
          ? this.props.selectedEvent.Officers.map((v) => {
              this.state.officers.push(v.id);
              return {
                value: v.id,
                label: `${v.User.firstName} ${v.User.lastName}`,
              };
            })
          : [];
      this.formRef.current.setFieldsValue({
        officers: selectedOfficers,
      });
    }

    // Match

    if (
      this.state.category === 'match' &&
      this.state.eventId &&
      this.state.eventId != prevState.eventId
    ) {
      this.formRef.current.setFieldsValue({
        match: null,
        officer: null,
      });
    }

    if (
      this.state.eventId &&
      this.state.matchId &&
      this.state.matchId != prevState.matchId
    ) {
      this.props.dispatch(actions.admin.getMatch(this.state.matchId));
    }

    if (
      this.props.selectedMatch &&
      this.props.selectedMatch.Officers &&
      this.props.selectedMatch !== prevProps.selectedMatch
    ) {
      const selectedOfficer =
        this.props.selectedMatch && this.props.selectedMatch.Officers
          ? this.props.selectedMatch.Officers.map((v) => {
              this.setState({ officer: v.id });
              return {
                value: v.id,
                label: `${v.User.firstName} ${v.User.lastName}`,
              };
            })
          : [];
      this.formRef.current.setFieldsValue({
        officer: selectedOfficer,
      });
    }

    if (this.state.category !== prevState.category) {
      this.formRef.current.resetFields();
      this.formRef.current.setFieldsValue({
        category: this.state.category,
      });
    }
  }

  onFinish = (values) => {
    console.log('Venues Value: ', values);
    console.log('Officers: ', this.state.officers);

    if (this.state.category === 'event') {
      // CALL API with Officers ID & Event ID as params
      this.props.dispatch(
        actions.admin.assignOfficersToEvent(values.event, values.officers)
      );
    } else {
      // IF it is for a match
      // CALL API with Event id & Match Id as Params
      this.props.dispatch(
        actions.admin.assignOfficerToMatch(values.match, values.officer)
      );
    }
  };
  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  fetch = (value) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fake = () => {
      this.props.dispatch(actions.admin.getOfficers(value));

      //   if (this.props.officers) {
      //     console.log("OFF: ", this.props.officers);
      //     const data = [{ value: "a", text: "Officer 1" }];
      //     this.setState({ data: data });
      //   }

      //   axios
      //     .get("/api/admin/officers?firstName=a")
      //     .then((response) => {
      //       console.log("Repso: ", response);
      //       //   response.json();
      //     })
      //     .then((d) => {
      //       console.log("DD: ", currentValue, "VV: ", value);

      //       if (currentValue === value) {
      //         // const { result } = d;
      //         // const data = result.map((item) => ({
      //         //   value: "amr",
      //         //   text: "s",
      //         // }));
      //         const data = [{ value: "a", text: "s" }];
      //         this.setState({ data: data });
      //       }
      //     });
    };
    if (value) {
      timeout = setTimeout(fake, 300);
    } else {
      //   callback([]);
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('do validate');
      this.fetch(this.state.searchValue);
    }
  };

  handleSearch = (searchValue) => {
    // console.log("Search Value: ", newValue);
    // // this.fetch(newValue);
    this.setState({ searchValue });
  };
  handleChange = (newValues) => {
    this.setState({ officers: newValues });
  };

  render() {
    return (
      <div>
        <div className="content-title">
          <UserOutlined /> Media Officers Assignments
        </div>
        <Form
          name="assignments"
          className="assignment-form"
          ref={this.formRef}
          style={{ padding: '10px', fontFamily: 'Roboto', fontWeight: 900 }}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{ category: 'event' }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Assignment Category"
            name="category"
            rules={[
              {
                required: true,
                message: 'Please select a category!',
              },
            ]}
          >
            <Select
              className="emails-select"
              placeholder="Select Assignment Category"
              onSelect={(v) => {
                this.setState({ category: v });
              }}
            >
              <Option value="event">Event Assignments</Option>
              <Option value="match">Match Assignments</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Event"
            name="event"
            rules={[
              {
                required: true,
                message: 'Please select event!',
              },
            ]}
          >
            <Select
              className="emails-select"
              placeholder="Select Event"
              onSelect={(v) => {
                this.setState({ eventId: v });
              }}
            >
              {this.props.events.map((event, index) => {
                return (
                  <Option value={event.id} key={event.id}>
                    {event.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {this.state.category == 'event' &&
            this.props.selectedEvent &&
            this.state.eventId && (
              <Form.Item
                label="Media Officers"
                name="officers"
                rules={[
                  {
                    required: true,
                    message: 'Please select media officers!',
                  },
                ]}
              >
                <Select
                  className="emails-select"
                  placeholder="Media Officers"
                  mode="multiple"
                  showSearch
                  //   value={
                  //     this.props.selectedEvent &&
                  //     this.props.selectedEvent.Officers
                  //       ? this.props.selectedEvent.Officers.map((v) => {
                  //           return {
                  //             value: v.id,
                  //             label: `${v.User.firstName} ${v.User.lastName}`,
                  //           };
                  //         })
                  //       : []
                  //   }
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={false}
                  // onKeyDown={this.handleKeyDown}
                  // onSearch={this.handleSearch}
                  // onChange={this.handleChange}
                  onChange={(value) => {
                    console.log('searching for ', value);
                    this.props.dispatch(actions.admin.getOfficers(value));
                  }}
                  onSearch={(value) => {
                    console.log('1. searching for ', value);
                    this.props.dispatch(actions.admin.getOfficers(value));
                  }}
                  notFoundContent={null}
                  options={(this.props.officers ? this.props.officers : []).map(
                    (d) => ({
                      value: d.id,
                      label: `${d.User.firstName} ${d.User.lastName}`,
                    })
                  )}
                ></Select>
              </Form.Item>
            )}

          {this.state.category == 'match' && (
            <Form.Item
              label="Match"
              name="match"
              rules={[
                {
                  required: true,
                  message: 'Please select match!',
                },
              ]}
            >
              <Select
                className="emails-select"
                placeholder="Match"
                onSelect={(v) => {
                  this.setState({ matchId: v });
                }}
              >
                {this.props.selectedEvent &&
                  this.props.selectedEvent.Matches.map((match, index) => {
                    return (
                      <Option value={match.id} key={match.id}>
                        {match.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          )}

          {this.state.category == 'match' &&
            this.props.selectedEvent &&
            this.state.matchId && (
              <Form.Item
                label="Media Officer"
                name="officer"
                rules={[
                  {
                    required: true,
                    message: 'Please select a media officer!',
                  },
                ]}
              >
                <Select
                  className="emails-select"
                  placeholder="Media Officer"
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  //   onKeyDown={this.handleKeyDown}
                  //   onSearch={this.handleSearchMatch}
                  onChange={this.handleChange}
                  notFoundContent={null}
                  options={(this.props.selectedEvent
                    ? this.props.selectedEvent.Officers
                    : []
                  ).map((d) => ({
                    value: d.id,
                    label: `${d.User.firstName} ${d.User.lastName}`,
                  }))}
                ></Select>
              </Form.Item>
            )}

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 2,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn"
              // loading={this.state.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    events: state.admin.events,
    selectedEvent: state.admin.selectedEvent,
    officers: state.admin.officers,
    selectedMatch: state.admin.selectedMatch,
  };
}
export default connect(mapStateToProps)(Assignments);
