import axios from 'axios';

import { store } from '../store';
import auth from '../actions/auth';

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    config.headers['x-access-token'] = token;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.data &&
      (error.response.data.message === 'jwt expired' ||
        error.response.data.message.startsWith('jwt expired')) &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['x-access-token'] = token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const state = store.getState();

      const refreshToken = state.auth.refreshToken;
      const token = state.auth.token;

      return new Promise((resolve, reject) => {
        axios
          .post(`/api/auth/refresh`, {
            token,
            refreshToken,
          })
          .then((response) => {
            axios.defaults.headers.common['x-access-token'] =
              response.data.data.token;
            originalRequest.headers['x-access-token'] =
              response.data.data.token;

            store.dispatch(
              auth.refresh(
                response.data.data.token,
                response.data.data.refreshToken
              )
            );
            processQueue(null, response.data.token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            // Unable to refresh. Log out
            store.dispatch(auth.logout());
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.message.startsWith('Force Logout')
    ) {
      // Unable to refresh. Log out

      if (window.location.pathname !== '/login') {
        store.dispatch(auth.logout());
      }
    } else if (error.message === 'Request failed with status code 412') {
      console.log('display an alert to contact the adminstrator then log out');
      // Unable to refresh. Log out
      store.dispatch(auth.logout());
    }

    // Do something with response error
    return Promise.reject(error);
  }
);

export default axios;
