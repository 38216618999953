import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  NavLink,
  Link,
} from 'react-router-dom';
import { Layout, Menu, Dropdown, Avatar, Divider } from 'antd';

import {
  SafetyCertificateOutlined,
  PoweroffOutlined,
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import { connect } from 'react-redux';

import actions from '../../actions';

import 'antd/dist/antd.css';
import './NavBar.css';

const { Header } = Layout;

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNavItem: 'home',
    };
  }

  render() {
    const navItems = [];

    // logged in
    if (this.props.auth.loggedIn) {
      if (this.props.auth.status === 'approved') {
        navItems.push({
          key: 'home',
          label: (
            <Link to="/">
              <span className="nav-text">Home</span>
            </Link>
          ),
          onClick: () => {
            return;
          },
        });
        // navItems.push({
        //   key: 'news',
        //   label: (
        //     <Link to="/news">
        //       <span className="nav-text">News</span>
        //     </Link>
        //   ),
        //   onClick: () => {
        //     return;
        //   },
        // });
        navItems.push({
          key: 'events',
          label: (
            <Link to="/events">
              <span className="nav-text">Events</span>
            </Link>
          ),
          onClick: () => {
            return;
          },
        });
         navItems.push({
           key: "documents",
           label: (
             <Link to="/documents">
               <span className="nav-text">Documents</span>
             </Link>
           ),
           onClick: () => {
             return;
           },
         });

        if (this.props.auth.scopes.includes('officer')) {
          navItems.push({
            key: 'reports',
            label: (
              <Link to="/reports">
                <span className="nav-text">Reports</span>
              </Link>
            ),
          });
        }
      }

      if (this.props.auth.scopes.includes('admin')) {
        navItems.push({
          key: 'admin',
          label: (
            <Link to="/admin/approvals/users">
              <SafetyCertificateOutlined />
              <span className="nav-text">Admin</span>
            </Link>
          ),
        });
      } else {
        navItems.push({
          key: 'contact us',
          label: (
            <Link to="/contact-us">
              <span className="nav-text">Contact Us</span>
            </Link>
          ),
          onClick: () => {
            return;
          },
        });
      }

      navItems.push({
        key: 'logout',
        label: (
          <>
            <PoweroffOutlined />
            <span className="nav-text">Logout</span>
          </>
        ),
        style: { marginRight: 'auto' },
        onClick: () => {
          console.log('log out');
          this.props.dispatch(actions.auth.logout());
        },
      });
    } else {
      navItems.push({
        key: 'login',
        label: (
          <Link to="/login">
            <span className="nav-text">Login</span>
          </Link>
        ),
        onClick: () => {
          return;
        },
      });
      navItems.push({
        key: 'contact us',
        label: (
          <Link to="/get-in-touch">
            <span className="nav-text">Contact Us</span>
          </Link>
        ),
        onClick: () => {
          return;
        },
      });
    }

    return (
      <div className="nav-bar-menu">
        <Header className="nav-bar-header">
          <a
            className="logo"
            onClick={() => {
              if (this.props.auth.status === "approved") {
                window.location.href = "/";
              } else {
                this.props.dispatch(actions.auth.logout());
              }
            }}
          >
            <img
              className="nav-bar-logo-image"
              src={"/dist/img/nav-bar-logo.png"}
              alt="logo"
            />
          </a>
          <Menu
            theme="light"
            mode="horizontal"
            activeKey={this.state.selectedNavItem}
            onSelect={(e) => {
              console.log(e);
              this.setState({ selectedNavItem: e.key });
            }}
            items={navItems}
          ></Menu>
          {this.props.auth.loggedIn && this.props.auth.status === "approved" && (
            <div className="avatar-container">
              <Link
                to={`/profile/${this.props.auth.user.id}`}
                className="profile-link"
              >
                {this.props.auth.user.firstName}
              </Link>
              <Divider
                className="avatar-divider"
                type="vertival"
                orientation="center"
              />
              <Avatar
                className="loggedin-avatar"
                src={`/api/file/${this.props.auth.user.profilePicture}`}
              >
                {this.props.auth.user.firstName.charAt(0)}
                {this.props.auth.user.lastName.charAt(0)}
              </Avatar>
            </div>
          )}
        </Header>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(NavBar);
