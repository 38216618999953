import React, { Component } from "react";
import "./Documents.css";
import { Card, Col, Row, List, Button } from "antd";
import { connect } from "react-redux";
import actions from "../actions";
import moment from "moment";

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoading: false,
      loading: false,
      pageNumber: 1,
      pageSize: 20,
    };
  }
  componentDidMount() {
    this.props.dispatch(
      actions.documents.getDocuments({
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
      })
    );
  }

  onLoadMore = () => {
    const nextPage = this.state.pageNumber + 1;
    this.props.dispatch(
      actions.documents.getDocuments({
        pageSize: this.state.pageSize,
        pageNumber: nextPage,
      })
    );
    this.setState({ pageNumber: nextPage });
  };

  render() {
    const allDocsLoaded =
      this.props.documents.length === this.props.totalCount;
    const loadMore =
      !this.state.initLoading && !this.props.loading && !allDocsLoaded ? (
        <div
          style={{
            textAlign: "center",
            marginTop: 12,
            height: 32,
            lineHeight: "32px",
          }}
        >
          <Button onClick={this.onLoadMore} loading={this.props.loading}>
            Load more
          </Button>
        </div>
      ) : null;

    return (
      <div className="document-container">
        <Row justify="start">
          <Col span={24}>
            <List
              loadMore={loadMore}
              grid={{
                gutter: 48,
                xs: 1,
                sm: 2,
                md: 2,
                // lg: 4,
                // xl: 4,
                // xxl: 5,
              }}
              dataSource={this.props.documents}
              renderItem={(file) => (
                <List.Item>
                  <div key={file.id}>
                    <Card
                      style={{
                        width: 260,
                        height: 300,
                        borderRadius: 12,
                      }}
                      onClick={() => {
                        window.open(`/api/file/${file.name}`);
                      }}
                      cover={
                        <img
                          alt="example"
                          src={`/api/file/${file.image}`}
                          width="100%"
                          height="220px"
                          style={{
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                      }
                      bodyStyle={{
                        width: "100%",
                        height: "60px",
                      }}
                    >
                      <div className="document-content">
                        <div className="document-content-date">
                          {moment(file.createdAt).format("DD-MM-YYYY")}
                        </div>
                        <div className="document-content-description">
                          {file.title}
                        </div>
                      </div>
                    </Card>
                  </div>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    documents: state.documents.documentsList,
    loading: state.documents.loading,
    totalCount: state.documents.totalCount,
  };
}
export default connect(mapStateToProps)(Documents);
