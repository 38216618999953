import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Carousel,
  Card,
  Col,
  Row,
  Button,
  Calendar,
  Badge,
  Tooltip,
  Popover,
  message,
  Tag,
} from 'antd';

import actions from '../actions';

import './EventDetails.css';
import moment from 'moment';

class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      slides: 3,
      event: {
        id: props.match.params.id,
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    props.dispatch(actions.events.selectEvent(this.state.event.id));
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    let slides = 1;
    if (window.innerWidth < 464) {
      slides = 1;
    } else if (window.innerWidth > 464 && window.innerWidth < 1024) {
      slides = 2;
    } else {
      slides = 3;
    }
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      slides,
    });
  }

  getListData = (value) => {
    let listData;
    let dateValue = value.format('yyyy/MM/DD'); // you can parse value in every format you want
    switch (dateValue) {
      case '2022/11/09':
        listData = [
          { type: 'processing', content: 'Training Session', time: '08:05 pm' },
          { type: 'warning', content: 'Press Conference', time: '04:00 pm' },
        ];
        break;
      case '2022/11/11':
        listData = [
          { type: 'warning', content: 'Training Session 2', time: '11:00 am' },
        ];
        break;
      default:
    }
    return listData || [];
  };

  //   onPanelChange = (value, mode) => {
  //     console.log(value.format("YYYY-MM-DD"), mode);
  //   };

  onSelectDate = (value) => {
    console.log(value.format('YYYY-MM-DD'));
    const listData = this.getListData(value);
  };

  canBookMatches = (scopes) => {
    if (scopes.includes(`event_${this.state.event.id}`)) {
      return true;
    }
    return false;
  };

  render() {
    if (!this.props.selectedEvent) {
      return <></>;
    }

    const appliedMembers = this.props.selectedEvent.Members.map((member) => {
      return {
        id: member.User.id,
        status: member.EventMember.status,
      };
    });

    const appliedOfficers = this.props.selectedEvent.Officers.map((officer) => {
      return {
        id: officer.User.id,
        status: officer.EventOfficer.status,
      };
    });

    const dateCellRender = (value) => {
      const listData = this.getListData(value);
      const date = listData.length > 0 ? value.format('MMMM, DD') : '';
      const day = listData.length > 0 ? value.format('dddd') : '';
      const badge = listData.map((item) => {
        return (
          <li key={item.content} style={{ marginBottom: '5px' }}>
            <Badge
              className="event-badge"
              status={item.type}
              text={item.time + '   -   ' + item.content}
            />
          </li>
        );
      });
      const content = (
        <div>
          <div className="popover-content-day">{day}</div>
          <div className="popover-content-date">{date}</div>
          <ul className="events">{badge}</ul>
        </div>
      );
      return (
        <span>
          {listData.map((item, i) => (
            <Popover content={content} trigger="hover" placement="right">
              <span key={i} className="event-star">
                *
              </span>
            </Popover>
          ))}
        </span>
      );
    };

    const actionButton = () => {
      if (this.props.auth.scopes.includes('admin')) {
        return (
          <>
            <Button
              onClick={() =>
                this.props.history.push(
                  `${this.props.selectedEvent.id}/reservations`
                )
              }
              className="submit-btn"
            >
              Matches
            </Button>
            <Button
              onClick={() => {
                this.props.dispatch(
                  actions.events.exportEvent(this.props.selectedEvent.id)
                );
              }}
              className="submit-btn"
            >
              Export List
            </Button>
          </>
        );
      } else if (this.props.auth.scopes.includes('member')) {
        const member = appliedMembers.find(
          (m) => m.id === this.props.auth.user.id
        );

        if (member) {
          if (member.status === 'pending') {
            return <Tag color="warning">Requested</Tag>;
          }
          if (member.status === 'rejected') {
            return <Tag color="error">Rejected</Tag>;
          }
          if (member.status === 'approved') {
            return (
              <Button
                onClick={() =>
                  this.props.history.push(
                    `${this.props.selectedEvent.id}/reservations`
                  )
                }
                className="submit-btn"
              >
                Matches
              </Button>
            );
          } else {
            return <></>;
          }
        }
        if (this.props.selectedEvent.accreditationEnabled) {
          return (
            <Button
              onClick={() => {
                this.props.dispatch(
                  actions.events.joinEvent(
                    this.props.selectedEvent.id,
                    'member'
                  )
                );
              }}
              className="submit-btn"
              loading={this.props.ui.loading_join_event}
            >
              Apply
            </Button>
          );
        }
      } else if (this.props.auth.scopes.includes('officer')) {
        const officer = appliedOfficers.find(
          (m) => m.id === this.props.auth.user.id
        );
        if (officer) {
          if (officer.status === 'pending') {
            return <Tag color="warning">Requested</Tag>;
          }
          if (officer.status === 'rejected') {
            return <Tag color="error">Rejected</Tag>;
          }
          if (officer.status === 'approved') {
            return (
              <>
                <Button
                  onClick={() =>
                    this.props.history.push(
                      `${this.props.selectedEvent.id}/reservations`
                    )
                  }
                  className="submit-btn"
                >
                  Matches
                </Button>
                <Button
                  onClick={() => {
                    message.info('Exporting Accepted List');
                    this.props.dispatch(
                      actions.events.exportEvent(this.props.selectedEvent.id)
                    );
                  }}
                  className="submit-btn"
                >
                  Export List
                </Button>
              </>
            );
          } else {
            return <></>;
          }
        }
        if (this.props.selectedEvent.accreditationEnabled) {
          return (
            <Button
              onClick={() => {
                this.props.dispatch(
                  actions.events.joinEvent(
                    this.props.selectedEvent.id,
                    'officer'
                  )
                );
              }}
              className="submit-btn"
              loading={this.props.ui.loading_join_event}
            >
              Apply
            </Button>
          );
        }
      } else return <></>;
    };

    return (
      <div className="homePage">
        <div className="main-container">
          <img
            className="blurr"
            src={`/api/file/${this.props.selectedEvent.banner}`}
            alt="event-img"
            height="400px"
            width="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
          <div className="centered">
            <img
              src={`/api/file/${this.props.selectedEvent.banner}`}
              alt="event-img"
              height="400px"
              width="100%"
              style={{
                objectFit: 'contain',
                objectPosition: 'center',
              }}
            />
          </div>
        </div>
        <div className="event-info">
          <Row>
            <Col xs={24} xl={12} md={24}>
              <div className="event-main-title">
                {this.props.selectedEvent.name}
              </div>
              <div
                className="event-description"
                style={{ whiteSpace: 'pre-line' }}
              >
                {this.props.selectedEvent.description}
              </div>
              <div>
                {/* {this.canBookMatches(this.props.auth.scopes) ||
                this.props.auth.scopes.includes('admin') ? (
                  ''
                ) : (
                  <Button
                    onClick={() => {
                      const scopes = this.props.auth.scopes;
                      let scope = 'member';
                      if (scopes.includes('officer')) {
                        scope = 'officer';
                      }
                      this.props.dispatch(
                        actions.events.joinEvent(
                          this.props.selectedEvent.id,
                          scope
                        )
                      );
                    }}
                    className="submit-btn"
                  >
                    Apply
                  </Button>
                )} */}

                {/* {this.canBookMatches(this.props.auth.scopes) && (
                  <Button
                    onClick={() =>
                      this.props.history.push(
                        `${this.props.selectedEvent.id}/reservations`
                      )
                    }
                    className="submit-btn"
                  >
                    Matches
                  </Button>
                )} */}
                {/* {this.props.auth.scopes.includes('admin') && (
                  <Button
                    onClick={() => message.info('Exporting Accepted List')}
                    className="submit-btn"
                  >
                    Export List
                  </Button>
                )} */}

                {actionButton()}
              </div>
            </Col>
            <Col xs={24} xl={12} md={24}>
              {/* <div className="site-calendar-demo-card">
                 <Calendar
                  fullscreen={false}
                  //   onPanelChange={this.onPanelChange}
                  onSelect={this.onSelectDate}
                  dateCellRender={dateCellRender}
                /> 
              </div> */}
            </Col>
          </Row>
        </div>

        <div className="event-documents">
          <span className="event-title">
            {this.props.selectedEvent.name} Document List
          </span>

          {this.props.selectedEvent.Files.length ? (
            <div className="event-slider">
              <Carousel
                slidesToShow={this.state.slides}
                //   centerMode={true}
                //   asNavFor={this.state.media}
                draggable={true}
                ref={(carousel) => (this.nav = carousel)}
                swipeToSlide={true}
                //   touchThreshold={50}
                focusOnSelect={true}
                dots={false}
                infinite={false}
              >
                {this.props.selectedEvent.Files.map((file) => {
                  return (
                    <div>
                      <Card
                        style={{
                          width: 260,
                          height: 300,
                          borderRadius: 12,
                        }}
                        onClick={() => {
                          window.open(`/api/file/${file.name}`);
                        }}
                        cover={
                          <img
                            alt="example"
                            src={`/api/file/${file.image}`}
                            width="100%"
                            height="220px"
                            style={{
                              objectFit: 'cover',
                              // borderTopLeftRadius: "12px",
                              // borderTopRightRadius: "12px",
                              borderRadius: '10px',
                            }}
                          />
                        }
                        bodyStyle={{
                          width: '100%',
                          height: '60px',
                        }}
                      >
                        <div className="event-content">
                          <div className="event-content-date">
                            {moment(file.createdAt).format('DD-MM-YYYY')}
                          </div>
                          <div className="event-content-description">
                            {file.title}
                          </div>
                          {/* <div className="event-download-btn">
                            <a
                              onClick={() => {
                                this.props.dispatch(
                                  actions.events.downloadDocument(file.name)
                                );
                                message.info('Downloading PDF');
                              }}
                              className="btn"
                            >
                              Download
                            </a>
                          </div> */}
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <div className="event-document-no-data">No available documents</div>
          )}

          <br />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    ui: state.ui,
    events: state.events.eventList,
    selectedEvent: state.events.selectedEvent,
  };
}

export default connect(mapStateToProps)(EventDetails);
