export const DISPLAY_INFO_MESSAGE = 'DISPLAY_INFO_MESSAGE';
export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE';

const displayInfoMessage = (message) => (dispatch) => {
  dispatch({ type: DISPLAY_INFO_MESSAGE, data: message });
};

const displayErrorMessage = (message) => (dispatch) => {
  dispatch({ type: DISPLAY_ERROR_MESSAGE, data: message });
};

export default { displayInfoMessage, displayErrorMessage };
