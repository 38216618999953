import React, { Component } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { BankOutlined } from '@ant-design/icons';
const { Option } = Select;

class VenuesTab extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      country: '',
      city: '',
      editMode: false,
      loading: false,
      imageUrl: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.events.getCountries());
  }

  onFinish = (values) => {
    console.log('Venues Value: ', values);
    const data = {
      name: values.name,
      cityId: values.city,
    };
    this.props.dispatch(actions.admin.createLocation(data));
  };
  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  render() {
    return (
      <div>
        <div className="content-title">
          <BankOutlined /> Venues
        </div>
        <Form
          name="venues"
          className="teams-form"
          ref={this.formRef}
          style={{ padding: '10px', fontFamily: 'Roboto', fontWeight: 900 }}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{
            type: this.state.type,
          }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Venue Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input venue name!',
              },
            ]}
          >
            <Input placeholder="Venue name" />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            rules={[
              {
                required: true,
                message: 'Please input a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Country"
              showArrow
              showSearch
              optionFilterProp="children"
              onChange={(val) => {
                this.setState({ country: val });
                this.props.dispatch(actions.events.getCity(val));
              }}
            >
              {this.props.countries &&
                this.props.countries.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                message: 'Please input a value!',
              },
            ]}
            className="capital-cities"
          >
            <Select
              className="news-select"
              placeholder="City"
              showArrow
              onChange={(val) => {
                this.setState({ city: val });
              }}
            >
              {this.props.cities &&
                this.state.country &&
                this.props.cities.map((item) => (
                  <Option
                    key={item.id}
                    value={item.id}
                    className="capital-cities"
                  >
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 2,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn"
              // loading={this.state.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    countries: state.events.countries,
    cities: state.events.cities,
  };
}
export default connect(mapStateToProps)(VenuesTab);
