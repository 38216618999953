import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Table } from "antd";
import '../Approvals/Tables.css';
import './Manage.css';
import { UserOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons';
import actions from '../../../actions';
const { Search } = Input;

class AllUsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = { searchText: "" };
  }

  componentDidMount() {
    this.props.dispatch(actions.admin.getUsers());
  }

  onSearch = (value) => {
    this.setState({ searchText: value });
  };

  handleSearchChange = (e) => {
    this.setState({ searchText: e.target.value });
  };

  render() {
    const toTitleCase = (str) => {
      if (!str) return null;
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '25%',
        align: 'left',
        render: (name) => <a className="table-name-btn">{name}</a>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '20%',
        align: 'left',
        responsive: ['md'],
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        width: '10%',
        align: 'left',
        responsive: ['lg'],
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        width: '20%',
        align: 'left',
        render: (val) => {
          return toTitleCase(val);
        },
      },
      {
        title: 'Role',
        dataIndex: 'role',
        width: '10%',
        align: 'left',
        responsive: ['md'],
        render: (val) => {
          return toTitleCase(val);
        },
      },
      {
        title: 'Action',
        dataIndex: '',
        width: '15%',
        key: 'x',
        render: (record) => {
          if (record.Admin) {
            return <></>;
          }
          return (
            <div className="table-actions">
              <FormOutlined
                className="table-icons"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.props.dispatch({ type: "EDIT_USER", data: record });
                  this.props.history.push(`/admin/manage/users/${record.key}`);
                }}
              />
              <DeleteOutlined
                className="table-icons"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.props.dispatch(actions.admin.deleteUser(record.key));
                }}
              />
            </div>
          );
        },
      },
    ];

    const usersList = this.props.users
      .map((user) => {
        return {
          key: user.id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          phone: user.mobileNumber,
          userId: user.id,
          Member: user.Member,
          Officer: user.Officer,
          Admin: user.Admin,
        };
      })
      .filter((user) => !!user);

    usersList.forEach((u) => {
      if (u.Member) {
        u.organization = u.Member.organization;
        u.role = u.Member.role;
      } else if (u.Officer) {
        u.organization = u.Officer.organization;
        u.role = 'Media Officer';
      } else if (u.Admin) {
        u.organization = '9inety';
        u.role = 'Admin';
      } else {
        u.organization = 'N/A';
        u.role = 'N/A';
      }
    });

     const filteredUsers =
       this.state.searchText && usersList
         ? usersList.filter((user) => {
              const searchText = this.state.searchText.toLowerCase();
              return (
                user.name.toLowerCase().includes(searchText) ||
                user.email.toLowerCase().includes(searchText) ||
                user.organization.toLowerCase().includes(searchText)
              );
           })
         : usersList;

    return (
      <div>
        <div className="content-title">
          <UserOutlined /> Users ({filteredUsers.length || 0})
        </div>
        <div className="tabs-table-container">
          <div className="table-top-bar">
            <div className="news-search">
              <Search
                placeholder="Search"
                onSearch={this.onSearch}
                onChange={this.handleSearchChange}
                value={this.state.searchText}
                style={{
                  width: "25vw",
                }}
              />
            </div>
          </div>
          <Table
            className="tabs-table"
            // scroll={{ x: "calc(700px + 50%)"}}
            columns={columns}
            dataSource={filteredUsers}
            onRow={(record) => ({
              onClick: () => {
                // this.props.history.push(`/admin/manage/users/${record.userId}`);
              },
            })}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    users: state.admin.users,
  };
}

export default connect(mapStateToProps)(AllUsersTable);
