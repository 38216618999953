import React, { Component } from 'react';
import { connect } from 'react-redux';

import QueryString from 'qs';
import actions from '../actions';

class Redirect extends Component {
  constructor(props) {
    super(props);
    const queryParams = QueryString.parse(
      props.location.search.substring(1, props.location.search.length)
    );
    this.state = {
      redirectUrl: queryParams.url,
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.auth.setRedirectUrl(this.state.redirectUrl));
    this.props.history.push('/');
  }

  render() {
    return (
      <>
        <p>Redirecting...</p>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Redirect);
