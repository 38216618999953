import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  NavLink,
} from 'react-router-dom';
import { Layout, message } from 'antd';
import { connect } from 'react-redux';

import 'antd/dist/antd.css';
import './App.css';
import './Identity.css';

import Home from './Components/Home';
import Register from './Components/Register';
import Login from './Components/Login';
import ResetPassword from './Components/ResetPassword';
import Reservations from './Components/Reservations';
import ReservationDetails from './Components/ReservationDetails';
import FootballPitchMap from './Components/FootballPitchMap';
import EventDetails from './Components/EventDetails';
// import News from './Components/News';
import Events from './Components/Events';
import Documents from "./Components/Documents";
// import NewsDetails from './Components/NewsDetails';
import NotFound from './Components/404';
import NotAuthorized from './Components/401';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsOfUse from './Components/TermsOfUse';
import MainFooter from './Components/Layout/MainFooter';
import NavBar from './Components/Navigation/NavBar';
import UserDetails from './Components/Admin/UserDetails';
import PendingPage from './Components/PendingPage';
import UserProfile from './Components/UserProfile';

import ContactUs from './Components/ContactUs';
import RedirectPage from './Components/Redirect';

import Notifications from './Components/Admin/Notifications';

import UsersApprovalTab from './Components/Admin/Approvals/UsersTab';
import MatchesApprovalTab from './Components/Admin/Approvals/MatchesTab';
import TournamentsApprovalTab from './Components/Admin/Approvals/TournamentsTab';

import AdminLayout from './Components/Layout/AdminLayout';

import NewsTab from './Components/Admin/Manage/NewsTab';
import NewsTable from './Components/Admin/Manage/NewsTable';
import UsersTable from './Components/Admin/Manage/AllUsersTable';
import EditUser from "./Components/Admin/Manage/EditUser";
import TeamsTab from './Components/Admin/Manage/TeamsTab';
import VenuesTab from './Components/Admin/Manage/VenuesTab';
import TournamentsTable from './Components/Admin/Manage/TournamentsTable';
import ManageTournamentTab from './Components/Admin/Manage/ManageTournamentTab';
import MatchesTable from './Components/Admin/Manage/MatchesTable';
import ManageMatchTab from './Components/Admin/Manage/ManageMatchTab';
import DocumentsTab from './Components/Admin/Manage/DocumentsTab';
import Reports from './Components/Reports';
import EditReport from './Components/EditReport';
import CreateReport from './Components/Admin/Reports/CreateReport';
import ReportsTable from './Components/Admin/Reports/ReportsTable';
import ViewReport from './Components/Admin/Reports/ViewReport';
import ManageReport from './Components/Admin/Reports/ManageReport';
import Assignments from './Components/Admin/Manage/Assignments';

// import { configureSocket } from './socketClient';
import actions from './actions';

const { Footer, Content } = Layout;

class App extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.ui.errorMessage !== this.props.ui.errorMessage) {
      message.error(this.props.ui.errorMessage);
    }
    if (prevProps.ui.infoMessage !== this.props.ui.infoMessage) {
      message.success(this.props.ui.infoMessage);
    }
  }

  render() {
    const LoginRoute = ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={(props) => {
            if (this.props.auth.loggedIn) {
              // configureSocket();
              if (this.props.auth.redirectUrl) {
                const redirectUrl = this.props.auth.redirectUrl;
                this.props.dispatch(actions.auth.setRedirectUrl(null));
                return <Redirect to={redirectUrl} />;
              }
              return <Redirect to="/" />;
            }
            return <Component {...props} />;
          }}
        />
      );
    };

    const PrivateRoute = ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={(props) => {
            if (!this.props.auth.loggedIn) {
              // configureSocket();
              return <Redirect to="/login" />;
            }

            if (this.props.auth.status !== 'approved') {
              return <PendingPage {...props} />;
            }

            if (this.props.auth.redirectUrl) {
              const redirectUrl = this.props.auth.redirectUrl;
              this.props.dispatch(actions.auth.setRedirectUrl(null));
              return <Redirect to={redirectUrl} />;
            }

            return <Component {...props} />;
          }}
        />
      );
    };

    const AdminRoute = ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={(props) => {
            if (!this.props.auth.loggedIn) {
              // configureSocket();
              return <Redirect to="/login" />;
            } else {
              if (!this.props.auth.scopes.includes('admin')) {
                return <NotAuthorized {...props} />;
              }
              return (
                <AdminLayout>
                  <Component {...props} />
                </AdminLayout>
              );
            }
          }}
        />
      );
    };

    return (
      <div className="App">
        <BrowserRouter>
          <Layout>
            <NavBar history={this.props.history}></NavBar>

            <Content>
              <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <LoginRoute exact path="/login" component={Login} />
                <LoginRoute exact path="/register" component={Register} />
                <PrivateRoute exact path="/reports" component={Reports} />
                <PrivateRoute
                  exact
                  path="/reports/edit/:id"
                  component={EditReport}
                />
                <Route
                  exact
                  path="/reset-password/:token"
                  component={ResetPassword}
                ></Route>
                <Route exact path="/redirect" component={RedirectPage}></Route>
                <Route exact path="/get-in-touch" component={ContactUs} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/terms-of-use" component={TermsOfUse} />
                <PrivateRoute exact path="/contact-us" component={ContactUs} />
                {/* <PrivateRoute exact path="/news" component={News} /> */}
                <PrivateRoute exact path="/events" component={Events} />
                <PrivateRoute exact path="/documents" component={Documents} />
                <PrivateRoute
                  exact
                  path="/events/:id/reservations"
                  component={Reservations}
                />
                <PrivateRoute
                  exact
                  path="/reservation/:ref"
                  component={ReservationDetails}
                />
                <PrivateRoute
                  exact
                  path="/events/:id/reservations/:ref"
                  component={ReservationDetails}
                />
                <PrivateRoute
                  exact
                  path="/events/:id/reservations/:matchId/map"
                  component={FootballPitchMap}
                />
                <PrivateRoute
                  exact
                  path="/profile/:id"
                  component={UserProfile}
                />
                <PrivateRoute
                  exact
                  path="/events/:id"
                  component={EventDetails}
                />
                {/* <PrivateRoute
                  exact
                  path="/news/:id/:title"
                  component={NewsDetails}
                /> */}
                <AdminRoute
                  exact
                  path="/user-details/:id"
                  component={UserDetails}
                />
                <AdminRoute
                  exact
                  path="/admin/approvals/users"
                  component={UsersApprovalTab}
                />
                <AdminRoute
                  exact
                  path="/admin/approvals/tournaments-accreditations"
                  component={TournamentsApprovalTab}
                />
                <AdminRoute
                  exact
                  path="/admin/approvals/match-tickets"
                  component={MatchesApprovalTab}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/users"
                  component={UsersTable}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/users/:id"
                  component={EditUser}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/news"
                  component={NewsTable}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/news/:id"
                  component={NewsTab}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/news/create"
                  component={NewsTab}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/documents"
                  component={DocumentsTab}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/teams"
                  component={TeamsTab}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/venues"
                  component={VenuesTab}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/matches"
                  component={MatchesTable}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/matches/:id"
                  component={ManageMatchTab}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/matches/create"
                  component={ManageMatchTab}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/tournaments"
                  component={TournamentsTable}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/tournaments/:id"
                  component={ManageTournamentTab}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/tournaments/create"
                  component={ManageTournamentTab}
                />
                <AdminRoute
                  exact
                  path="/admin/manage/assignments"
                  component={Assignments}
                />
                <AdminRoute
                  exact
                  path="/admin/notifications"
                  component={Notifications}
                />
                <AdminRoute
                  exact
                  path="/admin/reports"
                  component={ReportsTable}
                />
                <AdminRoute
                  exact
                  path="/admin/reports/create"
                  component={CreateReport}
                />
                <AdminRoute
                  exact
                  path="/admin/reports/view/:id"
                  component={ViewReport}
                />
                <AdminRoute
                  exact
                  path="/admin/reports/edit/:id"
                  component={ManageReport}
                />
                <Route component={NotFound} />
              </Switch>
            </Content>
            <Footer style={{ backgroundColor: "white" }}>
              <MainFooter />
            </Footer>
          </Layout>
        </BrowserRouter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    ui: state.ui,
  };
}

export default connect(mapStateToProps)(App);
