import axios from '../lib/api-client';

import { DISPLAY_ERROR_MESSAGE, DISPLAY_INFO_MESSAGE } from './ui';

export const ADMIN_GET_USERS = "ADMIN_GET_USERS";
export const ADMIN_GET_USER = "ADMIN_GET_USER";
export const ADMIN_EDIT_USER = "ADMIN_EDIT_USER";
export const ADMIN_DELETE_USER = "ADMIN_DELETE_USER";

export const ADMIN_GET_TEAMS = 'ADMIN_GET_TEAMS';
export const ADMIN_GET_TEAM = 'ADMIN_GET_TEAM';
export const ADMIN_CREATE_TEAM = 'ADMIN_CREATE_TEAM';
export const ADMIN_EDIT_TEAM = 'ADMIN_EDIT_TEAM';
export const ADMIN_DELETE_TEAM = 'ADMIN_DELETE_TEAM';

export const ADMIN_GET_EVENTS = 'ADMIN_GET_EVENTS';
export const ADMIN_GET_EVENT = 'ADMIN_GET_EVENT';
export const ADMIN_CREATE_EVENT = 'ADMIN_CREATE_EVENT';
export const ADMIN_EDIT_EVENT = 'ADMIN_EDIT_EVENT';
export const ADMIN_DELETE_EVENT = 'ADMIN_DELETE_EVENT';

export const ADMIN_GET_MATCHES = 'ADMIN_GET_MATCHES';
export const ADMIN_GET_MATCH = 'ADMIN_GET_MATCH';
export const ADMIN_CREATE_MATCH = 'ADMIN_CREATE_MATCH';
export const ADMIN_EDIT_MATCH = 'ADMIN_EDIT_MATCH';
export const ADMIN_DELETE_MATCH = 'ADMIN_DELETE_MATCH';

export const ADMIN_GET_LOCATIONS = 'ADMIN_GET_LOCATIONS';
export const ADMIN_GET_LOCATION = 'ADMIN_GET_LOCATION';
export const ADMIN_CREATE_LOCATION = 'ADMIN_CREATE_LOCATION';
export const ADMIN_EDIT_LOCATION = 'ADMIN_EDIT_LOCATION';
export const ADMIN_DELETE_LOCATION = 'ADMIN_DELETE_LOCATION';

export const ADMIN_GET_NEWS = 'ADMIN_GET_NEWS';
export const ADMIN_GET_NEWS_DETAILS = 'ADMIN_GET_NEWS_DETAILS';
export const ADMIN_CREATE_NEWS = 'ADMIN_CREATE_NEWS';
export const ADMIN_EDIT_NEWS = 'ADMIN_EDIT_NEWS';
export const ADMIN_DELETE_NEWS = 'ADMIN_DELETE_NEWS';

export const ADMIN_GET_OFFICERS = 'ADMIN_GET_OFFICERS';
export const ADMIN_ASSIGN_EVENT_OFFICERS = 'ADMIN_ASSIGN_EVENT_OFFICERS';
export const ADMIN_ASSIGN_MATCH_OFFICER = 'ADMIN_ASSIGN_MATCH_OFFICER';

export const ADMIN_GET_REPORTS = 'ADMIN_GET_REPORTS';
export const ADMIN_GET_REPORT = 'ADMIN_GET_REPORT';
export const ADMIN_EDIT_REPORT = 'ADMIN_EDIT_REPORT';
export const ADMIN_CREATE_REPORTS = 'ADMIN_CREATE_REPORTS';

const getUsers = () => (dispatch) => {
  axios
    .get(`/api/admin/users`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_USERS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_USERS, data: { users: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Unexpected error. Please try again later",
      });
    });
};

const getUser = (id) => (dispatch) => {
  axios
    .get(`/api/admin/user/${id}`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_USER, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_USER, data: { user: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Unexpected error. Please try again later",
      });
    });
};

const editUser = (id, user) => (dispatch) => {
  axios
    .patch(`/api/admin/user/${id}`, { user })
    .then((response) => {
      dispatch({ type: ADMIN_EDIT_USER, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: "User edited successfully.",
      });
      window.history.back();
    })
    .catch((err) => {
      dispatch({ type: ADMIN_EDIT_USER, data: { user: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Unexpected error. Please try again later",
      });
    });
};

const deleteUser = (id) => (dispatch) => {
  axios
    .delete(`/api/admin/user/${id}`)
    .then((response) => {
      dispatch({ type: ADMIN_DELETE_USER });
       dispatch({
         type: DISPLAY_INFO_MESSAGE,
         data: "User deleted successfully.",
       });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Unexpected error. Please try again later",
      });
    });
};

const getTeams = () => (dispatch) => {
  axios
    .get(`/api/admin/teams`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_TEAMS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_TEAMS, data: { teams: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getTeam = (id) => (dispatch) => {
  axios
    .get(`/api/admin/team/${id}`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_TEAM, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_TEAM, data: { team: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createTeam = (team) => (dispatch) => {
  axios
    .post(`/api/admin/team`, { team })
    .then((response) => {
      dispatch({ type: ADMIN_CREATE_TEAM, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Team created.',
      });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_CREATE_TEAM, data: { team: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const editTeam = (id, team) => (dispatch) => {
  axios
    .patch(`/api/admin/team/${id}`, { team })
    .then((response) => {
      dispatch({ type: ADMIN_EDIT_TEAM, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Team edited.',
      });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_EDIT_TEAM, data: { team: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const deleteTeam = (id) => (dispatch) => {
  axios
    .delete(`/api/admin/team/${id}`)
    .then(() => {
      dispatch({ type: ADMIN_DELETE_TEAM });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Team deleted.',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getEvents = () => (dispatch) => {
  axios
    .get(`/api/admin/events`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_EVENTS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_EVENTS, data: { events: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getEvent = (id) => (dispatch) => {
  axios
    .get(`/api/admin/event/${id}`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_EVENT, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_EVENT, data: { event: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createEvent = (event) => (dispatch) => {
  axios
    .post(`/api/admin/event`, { event })
    .then((response) => {
      dispatch({ type: ADMIN_CREATE_EVENT, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Event created.',
      });
      window.history.back();
    })
    .catch((err) => {
      dispatch({ type: ADMIN_CREATE_EVENT, data: { event: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const editEvent = (id, event) => (dispatch) => {
  axios
    .patch(`/api/admin/event/${id}`, { event })
    .then((response) => {
      dispatch({ type: ADMIN_EDIT_EVENT, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Event edited.',
      });
      window.history.back();
    })
    .catch((err) => {
      dispatch({ type: ADMIN_EDIT_EVENT, data: { event: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const deleteEvent = (id) => (dispatch) => {
  axios
    .delete(`/api/admin/event/${id}`)
    .then(() => {
      dispatch({ type: ADMIN_DELETE_EVENT });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Event deleted.',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getMatches = () => (dispatch) => {
  axios
    .get(`/api/admin/matches`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_MATCHES, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_MATCHES, data: { matches: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getMatch = (id) => (dispatch) => {
  axios
    .get(`/api/admin/match/${id}`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_MATCH, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_MATCH, data: { match: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createMatch = (match) => (dispatch) => {
  axios
    .post(`/api/admin/match`, { match })
    .then((response) => {
      dispatch({ type: ADMIN_CREATE_MATCH, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Match created.',
      });
      window.history.back();
    })
    .catch((err) => {
      dispatch({ type: ADMIN_CREATE_MATCH, data: { match: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const editMatch = (id, match) => (dispatch) => {
  axios
    .patch(`/api/admin/match/${id}`, { match })
    .then((response) => {
      dispatch({ type: ADMIN_EDIT_MATCH, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Match edited.',
      });
      window.history.back();
    })
    .catch((err) => {
      dispatch({ type: ADMIN_EDIT_MATCH, data: { match: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const deleteMatch = (id) => (dispatch) => {
  axios
    .delete(`/api/admin/match/${id}`)
    .then(() => {
      dispatch({ type: ADMIN_DELETE_MATCH });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Match deleted.',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getLocations = () => (dispatch) => {
  axios
    .get(`/api/admin/locations`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_LOCATIONS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_LOCATIONS, data: { locations: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getLocation = (id) => (dispatch) => {
  axios
    .get(`/api/admin/location/${id}`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_LOCATION, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_LOCATION, data: { location: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createLocation = (location) => (dispatch) => {
  axios
    .post(`/api/admin/location`, { location })
    .then((response) => {
      dispatch({ type: ADMIN_CREATE_LOCATION, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Location created.',
      });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_CREATE_LOCATION, data: { location: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const editLocation = (id, location) => (dispatch) => {
  axios
    .patch(`/api/admin/location/${id}`, {
      location,
    })
    .then((response) => {
      dispatch({ type: ADMIN_EDIT_LOCATION, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Location edited.',
      });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_EDIT_LOCATION, data: { location: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const deleteLocation = (id) => (dispatch) => {
  axios
    .delete(`/api/admin/location/${id}`)
    .then(() => {
      dispatch({ type: ADMIN_DELETE_LOCATION });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Location deleted.',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getOfficers = (searchValue) => (dispatch) => {
  axios
    .get(`/api/admin/officers?firstName=${searchValue}`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_OFFICERS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_OFFICERS, data: { officers: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const assignOfficersToEvent = (eventId, officers) => (dispatch) => {
  axios
    .patch(`/api/admin/event/${eventId}/members`, { officers })
    .then((response) => {
      dispatch({ type: ADMIN_ASSIGN_EVENT_OFFICERS });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Event assignment done successfully.',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const assignOfficerToMatch = (matchId, officer) => (dispatch) => {
  axios
    .patch(`/api/admin/match/${matchId}/members`, { officers: [officer] })
    .then((response) => {
      dispatch({ type: ADMIN_ASSIGN_MATCH_OFFICER });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Officer assigned to match successfully.',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getReports = () => (dispatch) => {
  axios
    .get(`/api/report`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_REPORTS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_REPORTS, data: { reports: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getReport = (id) => (dispatch) => {
  axios
    .get(`/api/report/${id}`)
    .then((response) => {
      dispatch({ type: ADMIN_GET_REPORT, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_GET_REPORT, data: { report: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createReports = (reports) => (dispatch) => {
  axios
    .post(`/api/report`, reports)
    .then((response) => {
      dispatch({ type: ADMIN_CREATE_REPORTS, data: response.data.data });
      dispatch({ type: DISPLAY_INFO_MESSAGE, data: 'Reports created.' });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_CREATE_REPORTS, data: { reports: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const editReport = (id, report) => (dispatch) => {
  axios
    .patch(`/api/report/${id}`, { report })
    .then((response) => {
      dispatch({ type: ADMIN_EDIT_REPORT, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Report edited successfully.',
      });
    })
    .catch((err) => {
      dispatch({ type: ADMIN_EDIT_REPORT, data: { report: null } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

export default {
  getUsers,
  getUser,
  editUser,
  deleteUser,

  getTeams,
  getTeam,
  createTeam,
  editTeam,
  deleteTeam,

  getEvents,
  getEvent,
  createEvent,
  editEvent,
  deleteEvent,

  getMatches,
  getMatch,
  createMatch,
  editMatch,
  deleteMatch,

  getLocations,
  getLocation,
  createLocation,
  editLocation,
  deleteLocation,

  getOfficers,
  assignOfficersToEvent,
  assignOfficerToMatch,

  getReports,
  getReport,
  editReport,
  createReports,
};
