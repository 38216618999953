import { FETCH_USERS, SELECT_USER } from '../actions/users';
import { LOGOUT } from '../actions/auth';

const initialState = {
  usersList: [],
  selectedUser: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        usersList: action.data.users,
      };
    case SELECT_USER:
      return {
        ...state,
        selectedUser: {
          ...action.data.profile,
          organization: action.data.organization,
          role: action.data.role,
          status: action.data.status,
          identification: action.data.identification,
          pressId: action.data.pressId,
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default usersReducer;
