import React, { Component } from "react";
import "./PrivacyPolicy.css";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="main-privacy-policy-container">
        <div className="main-privacy-policy-title">Privacy Policy</div>
        <div className="policy-container">
          <p>
            This Privacy Policy outlines the types of personal information that
            {` ${process.env.REACT_APP_ORGANIZATION}`} ("we," "our," or "us")
            collects from users ("you" or "your") when you access and use our
            website and services. We are committed to protecting your privacy
            and ensuring the security of your personal information. By using our
            website, you consent to the practices outlined in this Privacy
            Policy.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            a. <strong>Personal Information:</strong> When you register on our
            website, we may collect personal information such as your name,
            email address, date of birth, and any other information you provide
            during the registration process.
          </p>
          <p>
            b. <strong>Usage Data:</strong> We may collect information about how
            you use our website, including your IP address, browser type,
            operating system, pages visited, and other usage statistics.
          </p>

          <h2>2. How We Use Your Information</h2>
          <p>
            a. <strong>User Account:</strong> We use the personal information
            you provide to create and manage your user account, allowing you to
            access certain features and services on our website.
          </p>
          <p>
            b. <strong>Communication:</strong> We may use your email address to
            send you important updates, newsletters, promotional offers, and
            other communications related to our services. You can opt-out of
            marketing communications at any time.
          </p>
          <p>
            c. <strong>Personalization:</strong> We may use the information
            collected to personalize your experience on our website, such as
            recommending content or features based on your preferences.
          </p>
          <p>
            d. <strong>Analytics:</strong> We use usage data to analyze and
            improve the performance, content, and usability of our website.
          </p>

          <h2>3. Data Sharing and Disclosure</h2>
          <p>
            a. <strong>Service Providers:</strong> We may share your personal
            information with third-party service providers who assist us in
            operating our website, conducting business activities, and providing
            services to you. These service providers are obligated to keep your
            information confidential and use it solely for the purposes of
            providing their services to us.
          </p>
          <p>
            b. <strong>Legal Requirements:</strong> We may disclose your
            personal information if required by law, court order, or
            governmental authority.
          </p>

          <h2>4. Cookies and Tracking Technologies</h2>
          <p>
            We use cookies and similar tracking technologies to enhance your
            browsing experience and gather usage information. You can manage
            your cookie preferences through your browser settings.
          </p>

          <h2>5. Data Security</h2>
          <p>
            We implement reasonable security measures to protect your personal
            information from unauthorized access, disclosure, alteration, or
            destruction. However, no data transmission over the internet is
            entirely secure, and we cannot guarantee the security of your
            information.
          </p>

          <h2>6. Children's Privacy</h2>
          <p>
            Our website is not intended for individuals under the age of
            [minimum age]. We do not knowingly collect personal information from
            children under this age. If you believe we have inadvertently
            collected such information, please contact us to have it removed.
          </p>

          <h2>7. Your Rights</h2>
          <p>
            You have the right to access, update, or delete your personal
            information. You can also request a copy of the personal information
            we hold about you. To exercise these rights, please contact us using
            the information provided below.
          </p>

          <h2>8. Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. We will notify you of any material changes by
            posting the updated Privacy Policy on our website. Your continued
            use of the website after such changes signifies your acceptance of
            the updated Privacy Policy.
          </p>

          <h2>9. Contact Us</h2>
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy, please contact us at {`${process.env.REACT_APP_PUBLIC_EMAIL}`}.
          </p>

          <p>
            By using our website, you agree to the terms outlined in this
            Privacy Policy.
          </p>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
