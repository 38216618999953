import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, message, Table, Input } from 'antd';
import '../Approvals/Tables.css';
import './Manage.css';
import {
  DeleteOutlined,
  FormOutlined,
  TrophyOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import actions from '../../../actions';
const { Search } = Input;

class MatchesTable extends Component {
  componentDidMount() {
    this.props.dispatch(actions.admin.getMatches());
  }

  onSearch = (value) => console.log(value);

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '10%',
        align: 'left',
        // render: (media) => (
        //   <img
        //     className="news-table-image"
        //     alt="user-image"
        //     src={`/api/file/${media}`}
        //   />
        // ),
      },
      {
        title: 'Match',
        dataIndex: 'match',
        width: '20%',
        align: 'left',
      },
      {
        title: 'Venue',
        dataIndex: 'venue',
        width: '20%',
        align: 'left',
        responsive: ['lg'],
      },
      {
        title: 'Event',
        dataIndex: 'event',
        width: '20%',
        align: 'left',
        responsive: ['lg'],
      },
      {
        title: 'Date',
        dataIndex: 'date',
        width: '15%',
        align: 'left',
        responsive: ['md'],
      },
      {
        title: 'Reservation',
        dataIndex: 'reservationOpen',
        width: '5%',
        align: 'left',
        responsive: ['md'],
        render: (reservation) => (
          <>
            {reservation === true ? "Opened" : "Closed"}
          </>
        ),
      },
      {
        title: 'Action',
        dataIndex: '',
        width: '10%',
        key: 'x',
        render: (record) => (
          <div className="table-actions">
            <FormOutlined
              className="table-icons"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                this.props.dispatch({ type: "ADMIN_EDIT_MATCH", data: record });
                this.props.history.push(`/admin/manage/matches/${record.key}`);
              }}
            />
            <DeleteOutlined
              className="table-icons"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.props.dispatch(actions.admin.deleteMatch(record.key));
                this.props.dispatch(actions.admin.getMatches());
              }}
            />
          </div>
        ),
      },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
      console.log('Params: ', pagination, filters, sorter, extra);
    };

    const matchesData = this.props.matches.map((m) => {
      return {
        key: m.id,
        name: m.name,
        match: `${m.HomeTeam.name} vs ${m.AwayTeam.name}`,
        venue: m.Location.name,
        event: m.Event.name,
        reservationOpen: m.reservationOpen,
        date: `${new Date(m.date).toLocaleDateString('en-GB')} ${new Date(
          m.date
        ).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        })}`,
      };
    });
    return (
      <div>
        <div className="content-title">
          <TrophyOutlined /> Matches
        </div>
        <div className="table-container">
          <div className="table-top-bar">
            <div className="news-search">
              {/* <Search
                placeholder="Search"
                onSearch={this.onSearch}
                style={{
                  width: '25vw',
                }}
              /> */}
            </div>
            <Button
              className="news-addbtn"
              onClick={() => {
                this.props.dispatch({ type: 'ADMIN_CREATE_MATCH' });
                this.props.history.push(`/admin/manage/matches/create`);
              }}
            >
              <PlusCircleOutlined /> Create
            </Button>
          </div>
          <Table
            className="news-table"
            // scroll={{ x: "calc(700px + 50%)"}}
            columns={columns}
            dataSource={matchesData}
            onChange={onChange}
            onRow={(record, recordIndex) => ({
              onClick: (event) => {
                console.log(
                  'onRow onClick',
                  record,
                  // recordIndex,
                  this.props
                );
                // this.props.history.push(
                //   `/admin/manage/news/${record.key}`
                // );
              },
            })}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    matches: state.admin.matches,
  };
}
export default connect(mapStateToProps)(MatchesTable);
