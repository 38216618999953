import { Row, Col } from 'antd';
import React, { Component } from 'react';

class MainFooter extends Component {
  render() {
    return (
      <div>
        {/* <hr /> */}
        <Row
          style={{
            fontSize: "12px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Col
            className="social"
            style={{
              justifyContent: "left",
            }}
            xs={24}
            xl={4}
            l={4}
            md={24}
          >
            <ul style={{ padding: "0", marginLeft: "-10px" }}>
              <li style={{ display: "inline-block" }}>
                <a href={`${process.env.REACT_APP_FACEBOOK}`} target={"_blank"}>
                  <img
                    src={"/dist/img/Facebook.png"}
                    width="17px"
                    height="auto"
                    alt="facebook"
                  />
                </a>
              </li>
              <li style={{ display: "inline-block", marginLeft: "10px" }}>
                <a href={`${process.env.REACT_APP_TWITTER}`} target={"_blank"}>
                  <img
                    src={"/dist/img/Twitter.png"}
                    width="18px"
                    height="auto"
                    alt="twitter"
                  />
                </a>
              </li>
              <li style={{ display: "inline-block", marginLeft: "10px" }}>
                <a
                  href={`${process.env.REACT_APP_INSTAGRAM}`}
                  target={"_blank"}
                >
                  <img
                    src={"/dist/img/Instagram.svg"}
                    width="26px"
                    height="auto"
                    alt="instagram"
                  />
                </a>
              </li>
              <li style={{ display: "inline-block", marginLeft: "10px" }}>
                <a href={`${process.env.REACT_APP_TIKTOK}`} target={"_blank"}>
                  <img
                    src={"/dist/img/Tiktok.png"}
                    width="18px"
                    height="auto"
                    alt="linkedin"
                  />
                </a>
              </li>
            </ul>
          </Col>
          <Col
            className="identity"
            style={{ justifyContent: "center" }}
            xs={24}
            xl={16}
            l={16}
            md={24}
          >
            © 2023 {process.env.REACT_APP_ORGANIZATION}. All rights reserved.
          </Col>
          <Col
            className="identity"
            style={{ justifyContent: "right" }}
            xs={24}
            xl={4}
            l={4}
            md={24}
          >
            A product of {""}
            <a href="https://www.9inety.net">
              <img
                src={"/dist/img/9inetyLogo.png"}
                width="52px"
                height="auto"
                alt="9inety"
              />
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MainFooter;
