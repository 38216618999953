import React, { Component } from "react";
import "./PrivacyPolicy.css";

class TermsOfUse extends Component {
  render() {
    return (
      <div className="main-privacy-policy-container">
        <div className="main-privacy-policy-title">Terms Of Use</div>
        <div className="policy-container">
          <p>
            These Terms of Use ("Terms") govern your use of{" "}
            {`${process.env.REACT_APP_ORGANIZATION} `}
            ("we," "our," or "us") and its services. By accessing and using our
            website, you agree to comply with these Terms. If you do not agree
            with these Terms, please refrain from using our website.
          </p>

          <h2>1. Use of Content</h2>
          <p>
            All content on our website, including text, images, videos, and
            other materials, is protected by intellectual property laws and is
            the property of {`${process.env.REACT_APP_ORGANIZATION}`} or its
            licensors. You may not use, reproduce, distribute, modify, or create
            derivative works based on our content without obtaining explicit
            permission from us.
          </p>

          <h2>2. User Conduct</h2>
          <p>
            a. You are solely responsible for your actions while using our
            website. You agree not to engage in any behavior that could harm our
            website, other users, or third parties.
          </p>
          <p>
            b. You agree not to use our website for any illegal or unauthorized
            purposes, including but not limited to violating intellectual
            property rights, transmitting malicious code, or engaging in any
            form of harassment.
          </p>

          <h2>3. User Accounts</h2>
          <p>
            a. In order to access certain features, you may be required to
            create a user account. You are responsible for maintaining the
            confidentiality of your account credentials and for all activities
            that occur under your account.
          </p>
          <p>
            b. You agree to provide accurate and up-to-date information during
            the registration process. We reserve the right to suspend or
            terminate your account if the information provided is found to be
            false or incomplete.
          </p>

          <h2>4. Privacy</h2>
          <p>
            Your use of our website is also governed by our{" "}
            <a href="/privacy-policy">Privacy Policy</a>, which outlines how we
            collect, use, and protect your personal information. By using our
            website, you consent to the practices described in our Privacy
            Policy.
          </p>

          <h2>5. Limitation of Liability</h2>
          <p>
            a. {`${process.env.REACT_APP_ORGANIZATION}`} is not liable for any
            direct, indirect, incidental, special, or consequential damages
            arising from the use of our website or its content.
          </p>
          <p>
            b. We do not guarantee the accuracy, completeness, or reliability of
            the content on our website. You use our website at your own risk.
          </p>

          <h2>6. Changes to Terms</h2>
          <p>
            We may update these Terms from time to time to reflect changes in
            our services or for other operational, legal, or regulatory reasons.
            We will notify you of any material changes by posting the updated
            Terms on our website. Your continued use of the website after such
            changes signifies your acceptance of the updated Terms.
          </p>

          <h2>7. Termination</h2>
          <p>
            We reserve the right to suspend or terminate your access to our
            website at our discretion, without prior notice or liability, for
            any reason, including but not limited to a violation of these Terms.
          </p>

          <h2>8. Governing Law</h2>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of [Jurisdiction]. Any disputes arising from these Terms or
            your use of our website will be subject to the exclusive
            jurisdiction of the courts in [Jurisdiction].
          </p>

          <h2>9. Contact Us</h2>
          <p>
            If you have any questions, concerns, or requests regarding these
            Terms, please contact us at{" "}
            {`${process.env.REACT_APP_PUBLIC_EMAIL}`}.
          </p>

          <p>
            By using our website, you agree to the terms outlined in these Terms
            of Use.
          </p>
        </div>
      </div>
    );
  }
}

export default TermsOfUse;
