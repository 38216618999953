import React, { Component } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Upload,
  DatePicker,
  Radio,
} from 'antd';
import { connect } from 'react-redux';
import actions from '../../../actions';
import {
  LoadingOutlined,
  PlusOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';
import moment from 'moment';
const { Option } = Select;
const { RangePicker } = DatePicker;

class ManageTournamentTab extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      acronym: '',
      description: '',
      date: null,
      startDate: '',
      endDate: '',
      banner: '',
      accreditation: true,
      editMode: props.editMode,
      loading: false,
      imageUrl: '',

      uploadedPictureFileName: '',
      originalPictureName: 'Upload Tournament Cover Image',

      event: {
        id: props.match.params.id,
      },
    };
    if (props.editMode) {
      props.dispatch(actions.admin.getEvent(this.state.event.id));
    }
  }

  componentDidMount() {
    console.log('PROPS: ', this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedEvent &&
      this.props.selectedEvent !== prevProps.selectedEvent &&
      this.props.editMode
    ) {
      this.setState({
        name: this.props.selectedEvent.name,
        acronym: this.props.selectedEvent.acronym,
        description: this.props.selectedEvent.description,
        accreditation: this.props.selectedEvent.accreditationEnabled,
        coverImage: this.props.selectedEvent.banner,
        imageUrl: `/api/file/${this.props.selectedEvent.banner}`,
        uploadedPictureFileName: this.props.selectedEvent.banner,
        startDate: this.props.selectedEvent.startDate,
        endDate: this.props.selectedEvent.endDate,
      });
      this.formRef.current.setFieldsValue({
        name: this.props.selectedEvent.name,
        acronym: this.props.selectedEvent.acronym,
        date: [
          moment(this.props.selectedEvent.startDate),
          moment(this.props.selectedEvent.endDate),
        ],
        description: this.props.selectedEvent.description,
        accreditation: this.props.selectedEvent.accreditationEnabled,
        coverImage: this.props.selectedEvent.banner,
      });
    }
  }

  onChangeDate = (value, dateString) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    this.setState({ date: dateString });
  };

  onChangeAccreditationWindow = (e) => {
    console.log(`checked = ${e.target.value}`);
  };

  onFinish = (values) => {
    console.log('Form Values: ', values);
    this.setState({ loading: true });
    const data = {
      name: values.name,
      acronym: values.acronym,
      startDate: values.date[0],
      endDate: values.date[1],
      description: values.description,
      banner: this.state.uploadedPictureFileName,
      accreditationEnabled: values.accreditation,
    };
    if (this.state.editMode) {
      this.props.dispatch(actions.admin.editEvent(this.state.event.id, data));
    } else {
      this.props.dispatch(actions.admin.createEvent(data));
    }
  };

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  render() {
    const self = this;
    const uploadPictureProps = {
      name: 'file',
      multiple: false,
      maxCount: 1,
      action: `/api/file`,
      beforeUpload: (file) => {
        const isIMG = file.type === 'image/png' || 'image/jpeg' || 'image/jpg';

        if (!isIMG) {
          message.error(`${file.name} is not an image.`);
          return Promise.reject(new Error('File not an image.'));
        }
        if (file.size > 5000000) {
          message.error(`${file.name} size should be less than 5 MB`);
          return Promise.reject(
            new Error('File size should be less than 5 MB.')
          );
        }

        return isIMG || Upload.LIST_IGNORE;
      },
      onChange(info) {
        console.log(info.fileList);
        if (info.fileList.length == 1 && info.fileList[0].response) {
          self.setState({
            uploadedPictureFileName: info.fileList[0].response.fileName,
            originalPictureName: info.fileList[0].name,
            imageUrl: `/api/file/${info.fileList[0].response.fileName}`,
          });
        }
      },
    };

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
            fontWeight: 'lighter',
            fontSize: '11px',
          }}
        >
          Upload Tournament Cover Photo
        </div>
      </div>
    );

    return (
      <div>
        <div className="content-title">
          <DatabaseOutlined /> Tournaments
        </div>
        <Form
          name="tournaments"
          ref={this.formRef}
          style={{ padding: '10px', fontFamily: 'Roboto', fontWeight: 900 }}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{ accreditation: true }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Tournament Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input tournament name!',
              },
            ]}
          >
            <Input placeholder="Tournament name" />
          </Form.Item>

          <Form.Item
            label="Acronym"
            name="acronym"
            rules={[
              {
                required: false,
                message: 'Please input tournament acronym!',
              },
            ]}
          >
            <Input placeholder="Acronym ex: AFCON19, ..etc" />
          </Form.Item>

          <Form.Item
            label="Date"
            name="date"
            className="tournament-date"
            rules={[
              {
                required: true,
                message: 'Please input a value!',
              },
            ]}
          >
            <RangePicker onChange={this.onChangeDate} />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please input a description!',
              },
            ]}
          >
            <Input.TextArea placeholder="Description" rows={10} />
          </Form.Item>

          <Form.Item
            label="Tournament Cover Image"
            name="coverImage"
            rules={[
              {
                required: true,
                message: 'Please upload your tournament cover image!',
              },
            ]}
          >
            <Upload
              {...uploadPictureProps}
              maxCount={1}
              multiple={false}
              listType="picture-card"
              showUploadList={false}
              className="avatar-uploader"
              // onChange={this.handleUpload}
              // beforeUpload={() => false}
            >
              {this.state.imageUrl ? (
                <img
                  src={this.state.imageUrl}
                  alt="cover-image"
                  style={{
                    width: '100%',
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Accredition Window"
            name="accreditation"
            className="accreditation-radio"
            rules={[
              {
                required: true,
                message: 'Please input a description!',
              },
            ]}
          >
            <Radio.Group onChange={this.onChangeAccreditationWindow}>
              <Radio.Button value={true}>Opened</Radio.Button>
              <Radio.Button value={false}>Closed</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 2,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn"
              // loading={this.state.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedEvent: state.admin.selectedEvent,
    editMode: state.events.editMode,
  };
}
export default connect(mapStateToProps)(ManageTournamentTab);
