import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Row, Col, Card, Input } from 'antd';
import actions from '../actions';
import { Redirect } from "react-router-dom";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showValidationMessage: false,
      email: "",
      token: props.match.params.token,
      redirectToLogin: false,
    };
  }

  onFinish = (values) => {
    this.props.dispatch(
      actions.auth.resetPassword(
        values.email,
        this.state.token,
        values.password
      )
    );
  };

  componentDidUpdate(prevProps) {
   if (
     this.props.rp_status === true &&
     this.props.rp_status !== prevProps.rp_status &&
     !this.state.redirectToLogin
   ) {
     this.setState({ redirectToLogin: true });
   }
  }

  render() {
    if (this.state.redirectToLogin) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <Card className="card-layout" bodyStyle={{ padding: '0' }}>
          <Row>
            <Col xs={24} xl={12} md={24}>
              <p className="form-title">Reset Password</p>
              <Form
                name="normal_register"
                className="register-form"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 22,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
              >
                <Row>
                  <Col xs={24} xl={24} md={24} sm={24}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Password!',
                        },
                      ]}
                    >
                      <Input type="email" placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="New Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Password!',
                        },
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder="New Password"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} md={12} sm={12}>
                    <Form.Item
                      label="Confirm Password"
                      name="confirmpassword"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                'The two passwords that you entered do not match!'
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="register-form-button"
                  >
                    Change Password
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} xl={12} md={24} style={{ alignSelf: 'center' }}>
              <img src="/dist/img/vertical-banner.jpeg" className="register-img" />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    rp_status: state.auth.rp_status,
  };
}

export default connect(mapStateToProps)(ResetPassword);
