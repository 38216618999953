import axios from '../lib/api-client';

import { DISPLAY_ERROR_MESSAGE, DISPLAY_INFO_MESSAGE } from './ui';

export const LOADING_NEWS = 'LOADING_NEWS'
export const FETCH_NEWS = 'FETCH_NEWS';
export const SELECT_NEWS = 'SELECT_NEWS';
export const CREATE_NEWS = 'CREATE_NEWS';
export const EDIT_NEWS = 'EDIT_NEWS';
export const DELETE_NEWS = 'DELETE_NEWS';
export const FETCH_TAGS = 'FETCH_TAGS';

const getNews = ( pageSize, pageNumber, options = {}) => (dispatch) => {
  dispatch({ type: LOADING_NEWS, loading: true });
  axios
    .get(`/api/feed`, { params: { pageSize, pageNumber } })
    .then((response) => {
      dispatch({ type: FETCH_NEWS, data: response.data.data, pageNumber });
      dispatch({ type: LOADING_NEWS, loading: false });
    })
    .catch((err) => {
      dispatch({ type: FETCH_NEWS, data: { news: [] }, pageNumber });
      dispatch({ type: LOADING_NEWS, loading: false });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : "Unexpected error. Please try again later",
      });
    });
};

const selectNews = (id) => (dispatch) => {
  axios
    .get(`/api/feed/${id}`)
    .then((response) => {
      dispatch({ type: SELECT_NEWS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const createNews = (feed) => (dispatch) => {
  axios
    .post(`/api/feed`, { feed })
    .then((response) => {
      dispatch({ type: CREATE_NEWS });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'News created successfully!',
      });
      window.history.back();
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const editNews = (id, feed) => (dispatch) => {
  axios
    .patch(`/api/feed/${id}`, { feed })
    .then((response) => {
      dispatch({ type: EDIT_NEWS, data: response.data.data });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'Data updated successfully!',
      });
      window.history.back();
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const deleteNews = (id) => (dispatch) => {
  axios
    .delete(`/api/feed/${id}`)
    .then(() => {
      dispatch({ type: DELETE_NEWS });
      dispatch({
        type: DISPLAY_INFO_MESSAGE,
        data: 'News deleted.',
      });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const getTags = (name) => (dispatch) => {
  let query = '';
  if (name) {
    query += `?name=${name}`;
  }
  axios
    .get(`/api/feed/tags${query}`)
    .then((response) => {
      dispatch({ type: FETCH_TAGS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_TAGS, data: { tags: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

export default {
  getNews,
  selectNews,
  editNews,
  createNews,
  deleteNews,
  getTags,
};
