import React, { Component } from 'react';
import './UserDetails.css';
import { connect } from 'react-redux';
import { Card, Row, Col, Divider, Image, Button, Modal, Input, Spin } from "antd";
import actions from '../../actions';

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: props.match.params.id,
      },
      openRejectModal: false,
      rejectionReason: "",
      profileLoaded: true,
      identificationLoaded: true,
      pressLoaded: true,
      selectedRequestId: null,
    };
    props.dispatch(actions.users.selectUser(this.state.user.id));
  }

  handleOk = () => {
    console.log("Message: ", this.state.rejectionReason);
    if (this.props.selectedRequest.id) {
      this.props.dispatch(
        actions.requests.handleRequest(this.props.selectedRequest.id, {
          status: "rejected",
          rejectionReason: this.state.rejectionReason,
        })
      );
      this.props.history.goBack();
    }
  this.setState({ openRejectModal: false, rejectionReason: "" });
  };

  handleCancel = () => {
    this.setState({ openRejectModal: false, rejectionReason: "" });
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedUser != prevProps.selectedUser &&
      this.props.selectedUser
    ) {
      this.setState({
        firstName: this.props.selectedUser
          ? this.props.selectedUser.firstName
          : "",
        lastName: this.props.selectedUser.lastName,
        email: this.props.selectedUser.email,
        organization: this.props.selectedUser.organization,
        mobileNumber: this.props.selectedUser.mobileNumber,
        pressId: this.props.selectedUser.pressId,
        profilePicture: this.props.selectedUser.profilePicture,
        identification: this.props.selectedUser.identification,
        role: this.props.selectedUser.role,
      });
    }
  }

  render() {
    console.log(this.props.selectedUser);
    // if (!this.props.selectedUser) {
    //   return (
    //     <div style={{ backgroundColor: "white" }}>
    //       <Card
    //         style={{
    //           width: "100%",
    //           backgroundColor: "black",
    //           height: "380px",
    //           backgroundImage: `url('dist/img/banner-6.jpeg')`,
    //           backgroundPosition: "center",
    //         }}
    //       >
    //         <div className="banner">
    //           <span className="banner-title">User not found.</span>
    //         </div>
    //       </Card>
    //     </div>
    //   );
    // }

    return (
      <div style={{ backgroundColor: "white" }}>
        <div className="user-data-container">
          <Row>
            <Col xs={24} xl={6} md={24}>
              <div className="user-image-container">
                <Image
                  className="circular-user-image"
                  // alt="user-image"
                  preview={this.state.profileLoaded}
                  placeholder={<Spin size="default" className="image-spinner"/>}
                  src={`/api/file/${this.state.profilePicture}`}
                  onLoad={() => this.setState({ profileLoaded: true })}
                  onError={() => this.setState({ profileLoaded: false })}
                />
                <div className="user-name">
                  {this.state.firstName} {this.state.lastName}
                </div>
                <div className="user-role">{this.state.role}</div>
              </div>
            </Col>
            <Col xs={24} xl={18} md={24} className="user-info-column">
              <div className="user-info-header">
                User Info
                <Divider />
              </div>

              <div className="user-data">
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">First Name</div>
                    <div className="entry-data">{this.state.firstName}</div>
                  </Col>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Last Name</div>
                    <div className="entry-data">{this.state.lastName}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Role</div>
                    <div className="entry-data-cptl">{this.state.role}</div>
                  </Col>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Organization</div>
                    <div className="entry-data">{this.state.organization}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Email</div>
                    <div className="entry-data">{this.state.email}</div>
                  </Col>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Phone Number</div>
                    <div className="entry-data">{this.state.mobileNumber}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">ID/Passport</div>
                    <div className="entry-data">
                      <Image
                        className="document-img"
                        src={`/api/file/${this.state.identification}`}
                        preview={this.state.identificationLoaded}
                        placeholder={<Spin size="default" className="image-spinner"/>}
                        onLoad={() =>
                          this.setState({ identificationLoaded: true })
                        }
                        onError={() =>
                          this.setState({ identificationLoaded: false })
                        }
                      />
                    </div>
                  </Col>
                  <Col xs={12} xl={12} md={12}>
                    <div className="entry-title">Press ID</div>
                    <div className="entry-data">
                      <Image
                        className="document-img"
                        src={`/api/file/${this.state.pressId}`}
                        preview={this.state.pressLoaded}
                        placeholder={<Spin size="default" className="image-spinner"/>}
                        onLoad={() => this.setState({ pressLoaded: true })}
                        onError={() => this.setState({ pressLoaded: false })}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} xl={24} md={24}>
                    <Button
                      className="user-data-btn accept"
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("IDDD: ", this.props);
                        this.props.dispatch(
                          actions.requests.handleRequest(
                            this.props.selectedRequest.id,
                            {
                              status: "approved",
                            }
                          )
                        );
                        this.props.history.goBack();
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      className="user-data-btn reject"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({
                          openRejectModal: true,
                        });
                        // this.props.dispatch(
                        //   actions.requests.handleRequest(
                        //     this.props.selectedRequest.id,
                        //     {
                        //       status: "rejected",
                        //     }
                        //   )
                        // );
                        // this.props.history.goBack();
                      }}
                    >
                      Reject
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Modal
          title="Rejection Reason"
          open={this.state.openRejectModal}
          className="rejection-modal"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelButtonProps={{ className: "table-btn reject" }}
          okButtonProps={{
            disabled: !this.state.rejectionReason,
            className: "table-btn accept",
          }}
          okText="Submit"
        >
          <div className="rejection-modal-text">
            Please describe briefly the reason behind rejecting this profile
          </div>
          <div>
            <Input.TextArea
              rows={5}
              placeholder="Description"
              value={this.state.rejectionReason}
              onChange={(e) =>
                this.setState({ rejectionReason: e.target.value })
              }
            />
            {this.state.showValidationMessage &&
              !this.state.rejectionReason && (
                <div className="rejection-modal-error-message">
                  Rejection reason is required
                </div>
              )}
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedUser: state.users.selectedUser,
    selectedRequest: state.requests.selectedRequest,
  };
}
export default connect(mapStateToProps)(UserDetails);
