import axios from '../lib/api-client';

import { DISPLAY_ERROR_MESSAGE } from './ui';

export const FETCH_USERS = 'FETCH_USERS';
export const SELECT_USER = 'SELECT_USER';

// const getUsers = (options = {}) => (dispatch) => {
//   axios
//     .get(`/api/profile`)
//     .then((response) => {
//       dispatch({ type: FETCH_USERS, data: response.data.data });
//     })
//     .catch((err) => {
//       dispatch({ type: FETCH_USERS, data: { users: [] } });
//       dispatch({
//         type: DISPLAY_ERROR_MESSAGE,
//         data:
//           err.response.data && err.response.data.message
//             ? err.response.data.message
//             : 'Unexpected error. Please try again later',
//       });
//     });
// };

const selectUser = (id) => (dispatch) => {
  axios
    .get(`/api/profile/${id}`)
    .then((response) => {
      dispatch({ type: SELECT_USER, data: response.data.data });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

export default {
  // getUsers,
  selectUser,
};
