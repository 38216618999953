import React, { Component } from 'react';
import { Button, Form, Input, Select, message, Upload } from 'antd';
import { connect } from 'react-redux';
import actions from '../../../actions';
import {
  LoadingOutlined,
  PlusOutlined,
  DribbbleOutlined,
} from '@ant-design/icons';
const { Option } = Select;

class TeamsTab extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      type: 'club',
      name: '',
      country: '',
      city: '',
      editMode: false,
      loading: false,
      imageUrl: '',

      uploadedPictureFileName: '',
      originalPictureName: 'Upload Club Logo',
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.events.getCountries());
  }

  onFinish = (values) => {
    console.log('Teams Value: ', values);
    const data = {
      type: values.type,
      name: values.name,
      countryId: values.country,
      cityId: values.city ? values.city : null,
      logo: this.state.uploadedPictureFileName
        ? this.state.uploadedPictureFileName
        : null,
    };
    this.props.dispatch(actions.admin.createTeam(data));
  };
  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  render() {
    const self = this;
    const uploadPictureProps = {
      name: 'file',
      multiple: false,
      maxCount: 1,
      action: `/api/file`,
      beforeUpload: (file) => {
        const isIMG = file.type === 'image/png';

        if (!isIMG) {
          message.error(`${file.name} is not a PNG image.`);
          // return Promise.reject(new Error('File not an image.'));
        }
        if (file.size > 5000000) {
          message.error(`${file.name} size should be less than 5 MB`);
          // return Promise.reject(
          //   new Error('File size should be less than 5 MB.')
          // );
        }

        return isIMG || Upload.LIST_IGNORE;
      },
      onChange(info) {
        console.log(info.fileList);
        if (info.fileList.length == 1 && info.fileList[0].response) {
          self.setState({
            uploadedPictureFileName: info.fileList[0].response.fileName,
            originalPictureName: info.fileList[0].name,
            imageUrl: `/api/file/${info.fileList[0].response.fileName}`,
          });
        }
      },
    };

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
            fontWeight: 'lighter',
            fontSize: '11px',
          }}
        >
          Upload Club Logo
        </div>
      </div>
    );

    return (
      <div>
        <div className="content-title">
          <DribbbleOutlined /> Teams
        </div>
        <Form
          name="teams"
          className="teams-form"
          ref={this.formRef}
          style={{ padding: '10px', fontFamily: 'Roboto', fontWeight: 900 }}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{
            type: this.state.type,
          }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Team Type"
            name="type"
            rules={[
              {
                required: true,
                message: 'Please input a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Team Type"
              showArrow
              value={this.state.type}
              onSelect={(v) => {
                this.setState({ type: v });
              }}
            >
              <Option value="club">Club</Option>
              <Option value="national">National Team</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Team Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input team name!',
              },
            ]}
          >
            <Input placeholder="Team name" />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            rules={[
              {
                required: true,
                message: 'Please input a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Country"
              showArrow
              showSearch
              optionFilterProp="children"
              onChange={(val) => {
                this.setState({ country: val });
                this.props.dispatch(actions.events.getCity(val));
              }}
            >
              {this.props.countries &&
                this.props.countries.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {this.state.type == 'club' && (
            <>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: 'Please input a value!',
                  },
                ]}
                className="capital-cities"
              >
                <Select
                  className="news-select"
                  placeholder="City"
                  showArrow
                  onChange={(val) => {
                    this.setState({ city: val });
                  }}
                >
                  {this.props.cities &&
                    this.state.country &&
                    this.props.cities.map((item) => (
                      <Option
                        key={item.id}
                        value={item.id}
                        className="capital-cities"
                      >
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Club Logo"
                name="clubLogo"
                rules={[
                  {
                    required: true,
                    message: 'Please upload your club logo!',
                  },
                ]}
                extra="Formats accepted: .png"
              >
                <Upload
                  {...uploadPictureProps}
                  maxCount={1}
                  multiple={false}
                  listType="picture-card"
                  showUploadList={false}
                  className="logo-uploader"
                  // onChange={this.handleUpload}
                  // beforeUpload={() => false}
                >
                  {this.state.imageUrl ? (
                    <img
                      src={this.state.imageUrl}
                      alt="club-logo"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </>
          )}
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 2,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn"
              // loading={this.state.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    countries: state.events.countries,
    cities: state.events.cities,
  };
}
export default connect(mapStateToProps)(TeamsTab);
