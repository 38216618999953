import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Tag, Table, Input, message } from 'antd';
import '../Approvals/Tables.css';
import '../Manage/Manage.css';
import {
  DeleteOutlined,
  FormOutlined,
  PlusCircleOutlined,
  FileOutlined,
  DownloadOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import actions from '../../../actions';
const { Search } = Input;

class ReportsTable extends Component {
  componentDidMount() {
    this.props.dispatch(actions.reports.getReports());
  }

  render() {
    const columns = [
      {
        title: 'Report Name',
        dataIndex: '',
        width: '15%',
        align: 'left',
        responsive: ['lg'],
        render: (record) => (
          <>
            {record.urgent === false ? (
              record.name
            ) : (
              <>
                <Tag color="red">Urgent</Tag>
                {record.name}
              </>
            )}
          </>
        ),
      },
      {
        title: 'Media Officer',
        dataIndex: 'officer',
        width: '14%',
        align: 'left',
      },
      {
        title: 'Tournament',
        dataIndex: 'event',
        width: '14%',
        align: 'left',
      },
      {
        title: 'Venue',
        dataIndex: 'venue',
        width: '15%',
        align: 'left',
        responsive: ['md'],
      },
      {
        title: 'Match',
        dataIndex: 'match',
        width: '16%',
        align: 'left',
        responsive: ['lg'],
      },
      {
        title: 'Date',
        dataIndex: 'date',
        width: '11%',
        align: 'left',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '5%',
        align: 'left',
        responsive: ['lg'],
        render: (status) => <>{tagSwitch(status)}</>,
      },
      {
        title: 'Action',
        dataIndex: '',
        width: '10%',
        key: 'x',
        render: (record) => (
          <div className="reports-table-actions">
            <EyeOutlined
              className="reports-table-icons"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                console.log('handling', record);
                this.props.history.push(`/admin/reports/view/${record.key}`);
              }}
            />
            <FormOutlined
              className="reports-table-icons"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (record.status === "pending") {
                  this.props.history.push(`/admin/reports/edit/${record.key}`);
                } else {
                  message.error(
                    "Edit can not be performed since Media Officer already submitted data."
                  );
                }
              }}
            />
            <DeleteOutlined
              className="reports-table-icons"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                console.log('handling', record);
              }}
            />
          </div>
        ),
      },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
      console.log('Params: ', pagination, filters, sorter, extra);
    };

    const tagSwitch = (status) => {
      switch (status) {
        case 'draft':
          return <Tag color="orange">Draft</Tag>;
        case 'pending':
          return <Tag color="blue">Assigned</Tag>;
        default:
          return <Tag color="green">Published</Tag>;
      }
    };

    // const reportData = [
    //   {
    //     key: '1',
    //     officer: 'Amr Salem',
    //     event: 'Egyptian Pro League',
    //     venue: 'Cairo International Stadium',
    //     match: 'Al Ahly vs Pyramids FC',
    //     date: '15/5/2023 3:00 PM',
    //     name: 'Arrival',
    //     status: 'assigned',
    //     urgent: true,
    //   },
    //   {
    //     key: '2',
    //     officer: 'Mohamed Shokr',
    //     event: 'Egyptian Super Cup',
    //     venue: 'Cairo International Stadium',
    //     match: 'Future FC vs Pyramids FC',
    //     date: '13/7/2023 9:00 PM',
    //     name: 'Pre-Match',
    //     status: 'assigned',
    //     urgent: true,
    //   },
    //   {
    //     key: '3',
    //     officer: 'Mohamed Thabet',
    //     event: 'Egyptian Pro League',
    //     venue: 'Cairo International Stadium',
    //     match: 'Al Ahly vs Pyramids FC',
    //     date: '15/5/2023 3:00 PM',
    //     name: 'Match Day',
    //     status: 'assigned',
    //     urgent: false,
    //   },
    //   {
    //     key: '4',
    //     officer: 'Menna Abdelaziz',
    //     event: 'Egyptian League Cup',
    //     venue: 'Cairo International Stadium',
    //     match: 'Al Ahly vs Pyramids FC',
    //     date: '15/5/2023 3:00 PM',
    //     name: 'Post Match',
    //     status: 'published',
    //     urgent: false,
    //   },
    // ];

    const reportData = this.props.reports.reportsList
      ? this.props.reports.reportsList.map((r) => {
          return {
            key: r.id,
            officer: `${r.Officer.User.firstName} ${r.Officer.User.lastName}`,
            event: r.Event.name,
            venue: r.Match.Location.name,
            match: `${r.Match.HomeTeam.name} vs ${r.Match.AwayTeam.name}`,
            date: new Date(r.createdAt).toDateString(),
            name: r.name,
            status: r.status,
            urgent: r.urgent,
          };
        })
      : [];

    return (
      <div>
        <div className="content-title">
          <FileOutlined /> Reports
        </div>
        <div className="table-container">
          <div className="table-top-bar">
            <div className="news-search">
              {/* <Search
                placeholder="Search"
                onSearch={this.onSearch}
                style={{
                  width: '25vw',
                }}
              /> */}
            </div>
            <Button
              className="news-addbtn"
              onClick={() => {
                this.props.history.push(`/admin/reports/create`);
              }}
            >
              <PlusCircleOutlined /> Create
            </Button>
          </div>
          <Table
            className="news-table"
            // scroll={{ x: "calc(700px + 50%)"}}
            columns={columns}
            dataSource={reportData}
            onChange={onChange}
            onRow={(record, recordIndex) => ({
              onClick: (event) => {
                console.log(
                  'onRow onClick',
                  record,
                  // recordIndex,
                  this.props
                );
                // this.props.history.push(
                //   `/admin/manage/news/${record.key}`
                // );
              },
            })}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    reports: state.reports,
  };
}
export default connect(mapStateToProps)(ReportsTable);
