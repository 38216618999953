import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, message, Table, Input } from 'antd';
import '../Approvals/Tables.css';
import './Manage.css';
import {
  DeleteOutlined,
  FormOutlined,
  DatabaseOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import actions from '../../../actions';
const { Search } = Input;

class TournamentsTable extends Component {
  componentDidMount() {
    this.props.dispatch(actions.admin.getEvents());
  }

  onSearch = (value) => console.log(value);

  render() {
    const columns = [
      {
        title: 'Media',
        dataIndex: 'media',
        width: '5%',
        align: 'left',
        render: (media) => (
          <img
            className="news-table-image"
            alt="image"
            src={`/api/file/${media}`}
          />
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        width: '30%',
        align: 'left',
      },
      {
        title: 'Acronym',
        dataIndex: 'acronym',
        width: '15%',
        align: 'left',
        responsive: ['lg'],
      },
      {
        title: 'Date',
        dataIndex: 'date',
        width: '25%',
        align: 'left',
        responsive: ['md'],
      },
      {
        title: 'Window',
        dataIndex: 'window',
        width: '10%',
        align: 'left',
        responsive: ['lg'],
        render: (boolean) => <>{boolean == true ? 'Opened' : 'Closed'}</>,
      },
      {
        title: 'Action',
        dataIndex: '',
        width: '15%',
        key: 'x',
        render: (record) => (
          <div className="table-actions">
            <FormOutlined
              className="table-icons"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                this.props.dispatch({ type: "EDIT_EVENT", data: record });
                this.props.history.push(
                  `/admin/manage/tournaments/${record.key}`
                );
              }}
            />
            <DeleteOutlined
              className="table-icons"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                console.log('handling', record);
                this.props.dispatch(actions.admin.deleteEvent(record.key));
                this.props.dispatch(actions.admin.getEvents());
              }}
            />
          </div>
        ),
      },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
      console.log('Params: ', pagination, filters, sorter, extra);
    };

    const tournamentData = this.props.events.map((t) => {
      return {
        key: t.id,
        name: t.name,
        acronym: t.acronym,
        media: t.banner,
        window: t.accreditationEnabled,
        date: `${new Date(t.startDate).toLocaleDateString(
          'en-GB'
        )} - ${new Date(t.endDate).toLocaleDateString('en-GB')} `,
      };
    });
    return (
      <div>
        <div className="content-title">
          <DatabaseOutlined /> Tournaments
        </div>
        <div className="table-container">
          <div className="table-top-bar">
            <div className="news-search">
              {/* <Search
                placeholder="Search"
                onSearch={this.onSearch}
                style={{
                  width: '25vw',
                }}
              /> */}
            </div>
            <Button
              className="news-addbtn"
              onClick={() => {
                this.props.dispatch({ type: "CREATE_EVENT" });
                this.props.history.push(`/admin/manage/tournaments/create`);
              }}
            >
              <PlusCircleOutlined /> Add
            </Button>
          </div>
          <Table
            className="news-table"
            // scroll={{ x: "calc(700px + 50%)"}}
            columns={columns}
            dataSource={tournamentData}
            onChange={onChange}
            onRow={(record, recordIndex) => ({
              onClick: (event) => {
                console.log(
                  'onRow onClick',
                  record,
                  // recordIndex,
                  this.props
                );
                // this.props.history.push(
                //   `/admin/manage/news/${record.key}`
                // );
              },
            })}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    events: state.admin.events,
  };
}
export default connect(mapStateToProps)(TournamentsTable);
