import {
  FETCH_REQUESTS,
  HANDLE_REQUEST,
  SELECT_REQUEST,
} from '../actions/requests';
import { LOGOUT } from '../actions/auth';

const initialState = {
  requestList: [],
  selectedRequest: null,
};

const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUESTS:
      return {
        ...state,
        requestList: action.data.requests,
      };
    case SELECT_REQUEST:
      return {
        ...state,
        selectedRequest: action.data.request,
      };
    case HANDLE_REQUEST:
      return {
        ...state,
        selectedRequest: null,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default requestsReducer;
