import axios from '../lib/api-client';

import { DISPLAY_ERROR_MESSAGE } from './ui';

export const FETCH_REQUESTS = 'FETCH_REQUESTS';
export const SELECT_REQUEST = 'SELECT_REQUEST';
export const HANDLE_REQUEST = 'HANDLE_REQUEST';

const getRequests = (options = {}) => (dispatch) => {
  axios
    .get(
      `/api/admin/requests?pageSize=${options.pageSize ||
        10}&page=${options.page || 1}&&type=${options.type ||
        'create_profile'}&&status=pending`
    )
    .then((response) => {
      dispatch({ type: FETCH_REQUESTS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_REQUESTS, data: { requests: [] } });
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const selectRequest = (id) => (dispatch) => {
  axios
    .get(`/api/admin/request/${id}`)
    .then((response) => {
      dispatch({ type: SELECT_REQUEST, data: response.data.data });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

const handleRequest = (id, payload, options = {}) => (dispatch) => {
  axios
    .patch(`/api/admin/request/${id}`, payload)
    .then((response) => {
      dispatch({ type: HANDLE_REQUEST, data: response.data.data });
      return axios.get(
        `/api/admin/requests?pageSize=${options.pageSize ||
          10}&page=${options.page || 1}&&type=${options.type ||
          'create_profile'}&&status=pending`
      );
    })
    .then((response) => {
      dispatch({ type: FETCH_REQUESTS, data: response.data.data });
    })
    .catch((err) => {
      dispatch({
        type: DISPLAY_ERROR_MESSAGE,
        data:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Unexpected error. Please try again later',
      });
    });
};

export default {
  getRequests,
  selectRequest,
  handleRequest,
};
