import {
  LOGIN,
  LOADING_LOGIN,
  REFRESH,
  LOGOUT,
  REGISTER,
  SET_REDIRECT_URL,
  FORGET_PASSWORD,
  RESET_PASSWORD,
} from '../actions/auth';

const initialState = {
  loggedIn: false,
  loading: false,
  token: null,
  refreshToken: null,
  scopes: [],
  status: null,
  rp_status: false,
  redirectUrl: null,
  email: null,
  user: {
    id: null,
    username: null,
    email: null,
    firstName: null,
    lastName: null,
    profilePicture: null,
  },
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: true,
        token: action.data.token,
        refreshToken: action.data.user.refreshToken,
        scopes: action.data.scopes,
        status: action.data.status,
        user: {
          ...state.user,
          id: action.data.user.id,
          username: action.data.user.username,
          email: action.data.user.email,
          firstName: action.data.user.firstName,
          lastName: action.data.user.lastName,
          profilePicture: action.data.user.profilePicture,
        },
      };
    case REFRESH:
      return {
        ...state,
        token: action.data.token,
        refreshToken: action.data.refreshToken,
      };
    case REGISTER:
      return {
        ...state,
        loggedIn: true,
        token: action.data.token,
        refreshToken: action.data.user.refreshToken,
        scopes: action.data.scopes,
        status: action.data.status,
        user: {
          ...state.user,
          id: action.data.user.id,
          username: action.data.user.username,
          email: action.data.user.email,
        },
      };
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.data || null,
      };
    case LOADING_LOGIN:
      return {
        ...state,
        loading: action.loading,
      };
    case FORGET_PASSWORD:
      return {
        ...state,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        rp_status: action.data,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default auth;
