import {
  LOADING_DOCUMENTS,
  GET_DOCUMENTS,
  GET_DOCUMENT,
    CREATE_DOCUMENT,
} from "../actions/documents";
import { LOGOUT } from "../actions/auth";

const initialState = {
  documentsList: [],
  selectedDocument: null,
  pageNumber: 1,
  loading: false,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        documentsList:
          action.pageNumber === 1
            ? action.data.documents
            : [...state.documentsList, ...action.data.documents],
        totalCount: action.data.totalCount,
        pageNumber: action.pageNumber,
      };
    case GET_DOCUMENT:
      return { ...state, selectedDocument: action.data.document };
    case CREATE_DOCUMENT:
      return {
        ...state,
      };
    case LOADING_DOCUMENTS:
      return {
        ...state,
        loading: action.loading,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reportsReducer;
