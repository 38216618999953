import React, { Component } from 'react';
import { Button, Form, Input, Select, message, Upload } from 'antd';
import { connect } from 'react-redux';
import actions from '../../../actions';
import {
  LoadingOutlined,
  PlusOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const { Option } = Select;

class NewsTab extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      message: '',
      newsList: [],
      eventList: [],
      selectedNewsID: null,
      selectedNews: null,
      selectedEvent: null,
      title: '',
      description: '',
      event: null,
      editMode: props.editMode,
      loading: false,
      imageUrl: '',

      uploadedPictureFileName: '',
      originalPictureName: 'Upload News Cover Image',

      feed: {
        id: props.match.params.id,
      },
      editorState: EditorState.createEmpty(),
      selectedTags: [],
    };
    if (props.editMode) {
      props.dispatch(actions.news.selectNews(this.state.feed.id));
    }
  }

  componentDidMount() {
    // LOAD EXISITING NEWS
    this.props.dispatch(actions.events.getEvents());
    this.props.dispatch(actions.news.getTags());
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedFeed &&
      this.props.selectedFeed !== prevProps.selectedFeed &&
      this.props.editMode
    ) {
      const contentState = convertFromRaw(
        JSON.parse(this.props.selectedFeed.text)
      );
      const postEditorState = EditorState.createWithContent(contentState);
      this.setState({
        title: this.props.selectedFeed.title,
        editorState: postEditorState,
        // description: this.props.selectedFeed.text,
        event: this.props.selectedFeed.Event
          ? this.props.selectedFeed.Event.id
          : 'null',
        coverImage: this.props.selectedFeed.banner,
        imageUrl: `/api/file/${this.props.selectedFeed.banner}`,
        uploadedPictureFileName: this.props.selectedFeed.banner,
      });
      this.formRef.current.setFieldsValue({
        title: this.props.selectedFeed.title,
        description: this.props.selectedFeed.text,
        event: this.props.selectedFeed.Event
          ? this.props.selectedFeed.Event.id
          : 'null',
        coverImage: this.props.selectedFeed.banner,
      });
    }
  }

  onFinish = (values) => {
    const { editorState } = this.state;
    const content_state = editorState.getCurrentContent();
    const plainText = content_state.getPlainText();

    if (plainText.trim().length === 0) {
      this.formRef.current.setFields([
        {
          name: 'description',
          value: '',
          errors: ['Content is required. Please write it.'],
        },
      ]);
      return;
    }

    const contentState = editorState.getCurrentContent();
    const body = JSON.stringify(convertToRaw(contentState));

    const data = {
      title: values.title,
      text: body,
      banner: this.state.uploadedPictureFileName,
      eventId: values.event != 'null' ? values.event : null,
      Tags: this.state.selectedTags.map((t) => {
        const existingTag = this.props.tags.find((t1) => t1.name === t);
        if (existingTag) {
          return existingTag.id;
        } else return { name: t };
      }),
    };
    if (this.state.editMode) {
      this.props.dispatch(actions.news.editNews(this.state.feed.id, data));
    } else {
      this.props.dispatch(actions.news.createNews(data));
    }
  };

  onSelectEvent = (value) => {};

  handleEditorChange = (editorState) => {
    this.setState({ editorState });
  };

  render() {
    const self = this;
    const uploadPictureProps = {
      name: 'file',
      multiple: false,
      maxCount: 1,
      action: `/api/file`,
      beforeUpload: (file) => {
        const isIMG = file.type === 'image/png' || 'image/jpeg' || 'image/jpg';

        if (!isIMG) {
          message.error(`${file.name} is not an image.`);
          return Promise.reject(new Error('File not an image.'));
        }
        if (file.size > 5000000) {
          message.error(`${file.name} size should be less than 5 MB`);
          return Promise.reject(
            new Error('File size should be less than 5 MB.')
          );
        }

        return isIMG || Upload.LIST_IGNORE;
      },
      onChange(info) {
        if (info.fileList.length == 1 && info.fileList[0].response) {
          self.setState({
            uploadedPictureFileName: info.fileList[0].response.fileName,
            originalPictureName: info.fileList[0].name,
            imageUrl: `/api/file/${info.fileList[0].response.fileName}`,
          });
        }
      },
    };

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
            fontWeight: 'lighter',
            fontSize: '11px',
          }}
        >
          Upload News Cover Image
        </div>
      </div>
    );

    return (
      <div className="news-admin">
        <div className="content-title">
          <FileAddOutlined /> News
        </div>
        <Form
          name="news"
          ref={this.formRef}
          style={{ padding: '10px', fontFamily: 'Roboto', fontWeight: 900 }}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{}}
          onFinish={this.onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please input news title!',
              },
            ]}
          >
            <Input placeholder="News title" />
          </Form.Item>

          <Form.Item
            label="Assosiated Event"
            name="event"
            rules={[
              {
                required: true,
                message: 'Please input a value!',
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Event"
              showArrow
              onSelect={(v) => this.onSelectEvent(v)}
            >
              {this.props.events.map((event, index) => {
                return (
                  <Select.Option value={event.id} key={event.id}>
                    {event.name}
                  </Select.Option>
                );
              })}
              <Select.Option value={'null'} key={this.state.eventList.length}>
                None
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Content"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please input the content!',
              },
            ]}
          >
            <Editor
              editorState={this.state.editorState}
              onEditorStateChange={this.handleEditorChange}
              handlePastedText={() => false}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'list',
                  'link',
                  'history',
                  'textAlign',
                  'fontSize',
                  'fontFamily',
                ],
                inline: {
                  options: ['bold', 'italic', 'underline'],
                },
                fontSize: {
                  options: [
                    8,
                    9,
                    10,
                    11,
                    12,
                    14,
                    16,
                    18,
                    24,
                    30,
                    36,
                    48,
                    60,
                    72,
                    96,
                  ],
                },
                fontFamily: {
                  options: [
                    'Alexandria',
                    'Arial',
                    'Georgia',
                    'Impact',
                    'Tahoma',
                    'Times New Roman',
                    'Roboto',
                    'Verdana',
                  ],
                },
                blockType: {
                  options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
                },
                list: {
                  options: ['unordered', 'ordered'],
                },
                link: {
                  options: ['link', 'unlink'],
                },
                history: {
                  options: ['undo', 'redo'],
                },
              }}
              mention={false} // disable mention feature
              hashtag={false} // disable hashtag feature
              mentionSuggestions={null} // remove mention suggestions
              toolbarClassName="editor-toolbar"
              wrapperClassName="editor-wrapper"
              editorClassName="editor-content"
              handlePastedFiles={() => false} // disable image upload on paste
              toolbarOnFocus={false} // disable image upload from toolbar
            />
          </Form.Item>

          <Form.Item name="tags" label="Tags">
            <Select
              mode="tags"
              style={{
                width: '100%',
              }}
              onChange={(values) => {
                this.setState({ selectedTags: values });
              }}
              options={this.props.tags.map((t, i) => ({
                key: i,
                label: t.name,
                value: t.name,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="News Cover Image"
            name="coverImage"
            rules={[
              {
                required: true,
                message: 'Please upload your news cover image!',
              },
            ]}
          >
            <Upload
              {...uploadPictureProps}
              maxCount={1}
              multiple={false}
              listType="picture-card"
              showUploadList={false}
              className="avatar-uploader"
              // onChange={this.handleUpload}
              // beforeUpload={() => false}
            >
              {this.state.imageUrl ? (
                <img
                  src={this.state.imageUrl}
                  alt="cover-image"
                  style={{
                    width: '100%',
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 2,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn"
              // loading={this.state.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    news: state.news.newsList,
    events: state.events.eventList,
    selectedFeed: state.news.selectedFeed,
    editMode: state.news.editMode,
    tags: state.news.tags,
  };
}
export default connect(mapStateToProps)(NewsTab);
