import React, { Component } from 'react';
import { Carousel, Card, Button, notification } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import actions from '../actions';
import './Home.css';
import moment from 'moment';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, slides: 3, pageSize: 4, pageNumber: 1 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  openNotification = (eventId, eventName) => {
    const key = `open${eventId}`;
    const btn = (
      <Button
        type="primary"
        className="submit-btn"
        onClick={(e) => {
          e.stopPropagation();
          this.props.history.push(`/events/${eventId}`);
          notification.close(key);
        }}
      >
        Apply Now!
      </Button>
    );
    notification.open({
      message: (
        <>
          {' '}
          <InfoCircleOutlined /> Accreditation Window
        </>
      ),
      description: (
        <>
          Accreditations for <b>{eventName}</b> is now open!
        </>
      ),
      btn,
      duration: 6.5,
      style: {
        borderRadius: "12px"
      },
      key,
    });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.props.dispatch(actions.events.getEvents());
    this.props.dispatch(
      actions.documents.getDocuments({
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
      })
    );
    // this.props.dispatch(
    //   actions.news.getNews(this.state.pageSize, this.state.pageNumber)
    // );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.events && this.props.events !== prevProps.events) {
      let limit = 0;
      this.props.events.map((e) => {
        if (e.accreditationEnabled && limit < 3) {
          limit += 1;
          this.openNotification(e.id, e.name);
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    let slides = 1;
    if (window.innerWidth < 464) {
      slides = 1;
    } else if (window.innerWidth > 464 && window.innerWidth < 1024) {
      slides = 2;
    } else {
      slides = 3;
    }
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      slides,
    });
  }

  render() {
    return (
      <div className="homePage">
        <div className="main-container">
          <div className="homePage-main-image" />
          {/* <Carousel autoplay className="carousel" key={"highlights"}> */}
          {/* <div className="carousel-container"> */}
          {/* <span className="carousel-title">BAL Media Channel</span> */}
          {/* <span className="carousel-subtitle">
                The first in the MENA region
              </span> */}
          {/* <img
                src={"/dist/img/001.png"}
                alt="hightlight"
                className="homepage-main-image"
                // style={{ objectFit: "cover" }}
              /> */}
          {/* </div> */}
          {/* <div className="carousel-container">
              <span className="carousel-title">Media Channel 2</span>
              <span className="carousel-subtitle">
                The first in the MENA region
              </span>
              <img src={'/dist/img/carousel6.png'} alt="hightlight" />
            </div> */}
          {/* </Carousel> */}
        </div>

        <div className="second-container">
          {/* <div className="news-container">
            <span className="news-title">Latest News</span>

            <div className="news-slider">
              <Carousel
                key={"news"}
                slidesToShow={this.state.slides}
                //   centerMode={true}
                //   asNavFor={this.state.media}
                draggable={true}
                ref={(carousel) => (this.nav = carousel)}
                swipeToSlide={true}
                //   touchThreshold={50}
                focusOnSelect={true}
                dots={false}
                infinite={false}
              >
                {this.props.news.map((feed) => (
                  <div key={feed.id}>
                    <Card
                      style={{
                        width: 260,
                        height: 300,
                        borderRadius: 12,
                      }}
                      cover={
                        <img
                          alt="example"
                          src={`/api/file/${feed.banner}`}
                          width="100%"
                          height="160px"
                          style={{
                            objectFit: "cover",
                            borderTopLeftRadius: "12px",
                            borderTopRightRadius: "12px",
                            // borderRadius: "12px"
                          }}
                        />
                      }
                      bodyStyle={{
                        width: "100%",
                        height: "140px",
                      }}
                      onClick={(e) => {
                        console.log("News Clicked");
                        this.props.history.push(
                          `/news/${feed.id}/${feed.title}`
                        );
                      }}
                    >
                      <div className="news-content">
                        <div className="news-content-title">
                          {feed.Event ? feed.Event.name : ""}
                        </div>
                        <div className="news-content-description">
                          {feed.title}
                        </div>
                        <div className="news-content-date">
                          {moment(feed.createdAt).format("DD-MM-YYYY")}
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </Carousel>
            </div>

            <br />
          </div> */}

          <div className="events-container">
            <span className="events-title">Events</span>

            <div className="events-slider">
              <Carousel
                key={"events"}
                slidesToShow={this.state.slides}
                //   centerMode={true}
                //   asNavFor={this.state.media}
                draggable={true}
                ref={(carousel) => (this.nav = carousel)}
                swipeToSlide={true}
                //   touchThreshold={50}
                focusOnSelect={true}
                dots={false}
                infinite={false}
              >
                {this.props.events.map((event) => {
                  return (
                    <div key={event.id}>
                      <Card
                        onClick={(e) => {
                          console.log("Event Clicked");
                          this.props.history.push(`/events/${event.id}`);
                        }}
                        style={{
                          width: 260,
                          height: 300,
                          borderRadius: 12,
                          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                          // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                          // boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 12px",
                          // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"
                        }}
                        cover={
                          <img
                            alt="example"
                            src={`/api/file/${event.banner}`}
                            width="100%"
                            height="220px"
                            style={{
                              objectFit: "cover",
                              // borderTopLeftRadius: "12px",
                              // borderTopRightRadius: "12px",
                              borderRadius: "10px",
                            }}
                          />
                        }
                        bodyStyle={{
                          width: "100%",
                          height: "80px",
                        }}
                      >
                        <div className="events-content">
                          <div className="events-content-title">
                            {event.acronym ? `#${event.acronym}` : ""}
                          </div>
                          <div className="events-content-description">
                            {event.name}
                          </div>
                          <div className="events-content-date">
                            {moment(event.startDate).format("DD-MM-YYYY")} -{" "}
                            {moment(event.endDate).format("DD-MM-YYYY")}
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </Carousel>
            </div>

            <br />
          </div>

          <div className="documents-container">
            <span className="documents-title">Documents</span>

            <div className="documents-slider">
              <Carousel
                key={"documents"}
                slidesToShow={this.state.slides}
                //   centerMode={true}
                //   asNavFor={this.state.media}
                draggable={true}
                ref={(carousel) => (this.nav = carousel)}
                swipeToSlide={true}
                //   touchThreshold={50}
                focusOnSelect={true}
                dots={false}
                infinite={false}
              >
                {this.props.documents.map((file) => {
                  return (
                    <div key={file.id}>
                      <Card
                        style={{
                          width: 260,
                          height: 300,
                          borderRadius: 12,
                        }}
                        onClick={() => {
                          window.open(`/api/file/${file.name}`);
                        }}
                        cover={
                          <img
                            alt="example"
                            src={`/api/file/${file.image}`}
                            width="100%"
                            height="220px"
                            style={{
                              objectFit: "cover",
                              borderRadius: "10px",
                            }}
                          />
                        }
                        bodyStyle={{
                          width: "100%",
                          height: "60px",
                        }}
                      >
                        <div className="document-content">
                          <div className="document-content-date">
                            {moment(file.createdAt).format("DD-MM-YYYY")}
                          </div>
                          <div className="document-content-description">
                            {file.title}
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </Carousel>
            </div>

            <br />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    events: state.events.eventList,
    news: state.news.newsList,
    documents: state.documents.documentsList
  };
}

export default connect(mapStateToProps)(Home);
