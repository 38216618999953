import React, { Component } from 'react';
import { Typography, Button } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import "./PendingPage.css";
import actions from '../actions';

class PendingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ margin: "1rem" }}>
        <img className="done-img" src="/dist/img/done.png" alt="done" />
        <Typography.Title className='main-title' level={3}>
          Thank you for registering to join EPL Media Channel!
        </Typography.Title>
        <div className='subtitle'>
          Your application will be reviewed and you will be notified by email
          shortly.
        </div>
        <Link to="/">
          <Button
            type="primary"
            className="submit-btn"
            onClick={() => {
              this.props.dispatch(actions.auth.logout());
            }}
          >
            GO BACK
          </Button>
        </Link>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(PendingPage);
