import React, { Component } from 'react';
import { Button, Form, Input, Select, message, Upload } from 'antd';
import { connect } from 'react-redux';
import actions from '../../../actions';
import {
  LoadingOutlined,
  PlusOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
class DocumentsTab extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      type: 'club',
      name: '',
      country: '',
      city: '',
      editMode: false,
      loading: false,
      imageUrl: '',

      uploadedPictureFileName: '',
      originalPictureName: 'Upload Document Cover Image',

      uploadedDocumentFileName: '',
      originalDocumentName: 'Upload File',
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.events.getEvents());
  }

  onFinish = (values) => {
    console.log('File Value: ', values);
    const data = {
      title: values.title,
      name: this.state.uploadedDocumentFileName,
      image: this.state.uploadedPictureFileName,
      eventId: values.event != "null" ? values.event : null,
    };
    this.props.dispatch(actions.documents.createDocument(data));
  };

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  onSelectEvent = (value) => {};

  render() {
    const self = this;
    const uploadPictureProps = {
      name: 'file',
      multiple: false,
      maxCount: 1,
      action: `/api/file`,
      beforeUpload: (file) => {
        const types = ['image/png', 'image/jpeg', 'image/jpg'];

        if (!types.includes(file.type)) {
          message.error(`${file.name} is not an image.`);
          // return Promise.reject(new Error("File not an image."));
        }
        if (file.size > 5000000) {
          message.error(`${file.name} size should be less than 5 MB`);
          // return Promise.reject(
          //   new Error("File size should be less than 5 MB.")
          // );
        }

        return types.includes(file.type) || Upload.LIST_IGNORE;
      },
      onChange(info) {
        console.log(info.fileList);
        if (info.fileList.length == 1 && info.fileList[0].response) {
          self.setState({
            uploadedPictureFileName: info.fileList[0].response.fileName,
            originalPictureName: info.fileList[0].name,
            imageUrl: `/api/file/${info.fileList[0].response.fileName}`,
          });
        }
      },
    };

    const uploadDocumentProps = {
      name: 'file',
      multiple: false,
      maxCount: 1,
      action: `/api/file`,
      beforeUpload: (file) => {
        const types = [
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingm',
          'application/msword',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];
        if (!types.includes(file.type)) {
          message.error(`${file.name} is not a file.`);
          // return Promise.reject(new Error('File not a document.'));
        }
        if (file.size > 5000000) {
          message.error(`${file.name} size should be less than 5 MB`);
          // return Promise.reject(
          //   new Error('File size should be less than 5 MB.')
          // );
        }

        return types.includes(file.type) || Upload.LIST_IGNORE;
      },
      onChange(info) {
        console.log(info.fileList);
        if (info.fileList.length == 1 && info.fileList[0].response) {
          self.setState({
            uploadedDocumentFileName: info.fileList[0].response.fileName,
            originalDocumentName: info.fileList[0].name,
            // imageUrl: `/api/file/${info.fileList[0].response.fileName}`,
          });
        }
      },
    };

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
            fontWeight: 'lighter',
            fontSize: '11px',
          }}
        >
          Upload Document Cover Image
        </div>
      </div>
    );

    const uploadFileButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
            fontWeight: 'lighter',
            fontSize: '11px',
          }}
        >
          Upload Document
        </div>
      </div>
    );

    return (
      <div>
        <div className="content-title">
          <FilePdfOutlined /> Documents
        </div>
        <Form
          name="teams"
          className="teams-form"
          ref={this.formRef}
          style={{ padding: "10px", fontFamily: "Roboto", fontWeight: 900 }}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{
            type: this.state.type,
          }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Document Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Please input document title!",
              },
            ]}
          >
            <Input placeholder="Title" />
          </Form.Item>

          <Form.Item
            label="Assosiated Event"
            name="event"
            rules={[
              {
                required: true,
                message: "Please input a value!",
              },
            ]}
          >
            <Select
              className="news-select"
              placeholder="Event"
              showArrow
              onSelect={(v) => this.onSelectEvent(v)}
            >
              {this.props.events.map((event, index) => {
                return (
                  <Select.Option value={event.id} key={event.id}>
                    {event.name}
                  </Select.Option>
                );
              })}
              <Select.Option value={"null"} key={"null"}>
                None
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Document Cover Image"
            name="coverImage"
            rules={[
              {
                required: true,
                message: "Please upload your document cover image!",
              },
            ]}
          >
            <Upload
              {...uploadPictureProps}
              maxCount={1}
              multiple={false}
              listType="picture-card"
              showUploadList={false}
              className="avatar-uploader"
              // onChange={this.handleUpload}
              // beforeUpload={() => false}
            >
              {this.state.imageUrl ? (
                <img
                  src={this.state.imageUrl}
                  alt="cover-image"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Document"
            name="document"
            rules={[
              {
                required: true,
                message: "Please upload your document!",
              },
            ]}
            extra="Formats accepted: .pdf, .docx, .doc, .xlsx"
          >
            <Upload
              {...uploadDocumentProps}
              maxCount={1}
              multiple={false}
              listType="picture-card"
              //   showUploadList={false}
              className="logo-uploader"
              // onChange={this.handleUpload}
              // beforeUpload={() => false}
            >
              {uploadFileButton}
            </Upload>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 2,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="submit-btn"
              // loading={this.state.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    events: state.events.eventList,
    countries: state.events.countries,
    cities: state.events.cities,
  };
}
export default connect(mapStateToProps)(DocumentsTab);
