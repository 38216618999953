import React, { Component } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Card, Col, Row, Modal } from 'antd';
import { connect } from 'react-redux';
import './Login.css';

import actions from '../actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openForgetModal: false,
      showValidationMessage: false,
      email: "",
    };
  }

  handleOk = () => {
    // Validate Email
    if (this.emailValidation()) {
      // window.alert('Tamam');
      this.props.dispatch(actions.auth.forgetPassword(this.state.email));
      this.setState({
        openForgetModal: false,
        email: "",
        showValidationMessage: false,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      openForgetModal: false,
      email: "",
      showValidationMessage: false,
    });
  };

  emailValidation = () => {
    const { email } = this.state;
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    if (!email || !emailRegex.test(email)) {
      this.setState({ showValidationMessage: true });
      return false;
    } else {
      this.setState({ showValidationMessage: false });
      // Proceed with your logic to handle valid email
      return true;
    }
  };

  onFinish = (values) => {
    this.props.dispatch(
      actions.auth.login({
        email: values.email,
        password: values.password,
      })
    );
  };

  render() {
    return (
      <div className="login-layout">
        <Card className="card-layout" bodyStyle={{ padding: "0" }}>
          <Row>
            <Col xs={24} xl={12} md={24}>
              <p className="form-title">Login</p>
              <Form
                name="normal_login"
                className="login-form"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 18,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input
                    suffix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password type="password" placeholder="Password" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName=""
                  wrapperCol={{
                    offset: 0,
                    span: 18,
                  }}
                >
                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Checkbox>Remember me</Checkbox>
                    <span className="forgetPassword">
                      <a
                        onClick={() => {
                          this.setState({
                            openForgetModal: true,
                          });
                        }}
                      >
                        Forgot Password?
                      </a>
                    </span>
                  </div>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.props.loading}
                    className="login-form-button"
                    onClick={() => {
                      // this.props.dispatch(
                      //   actions.auth.login({
                      //     email: 'test@test.com',
                      //     password: 'test123',
                      //   })
                      // );
                    }}
                  >
                    Login
                  </Button>
                </Form.Item>
                <Form.Item>
                  <div className="noAccount">
                    <p>Don't have an account yet ?</p>
                    <a href="/register">Sign Up</a>
                  </div>
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} xl={12} md={24}>
              <img src="/dist/img/vertical-banner.jpeg" className="login-img" />
            </Col>
          </Row>
        </Card>
        <Modal
          title="Forget Password"
          open={this.state.openForgetModal}
          className="rejection-modal"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelButtonProps={{ className: "table-btn reject" }}
          okButtonProps={{
            disabled: !this.state.email,
            className: "table-btn accept",
          }}
          okText="Submit"
        >
          <div className="rejection-modal-text">
            Please write your email address
          </div>
          <div>
            <Input
              placeholder="Email address"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
            {this.state.showValidationMessage && !this.state.email && (
              <div className="rejection-modal-error-message">
                Email address is required
              </div>
            )}
            {this.state.showValidationMessage &&
              this.state.email &&
              !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
                this.state.email
              ) && (
                <div className="rejection-modal-error-message">
                  Invalid email address
                </div>
              )}
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { loading: state.auth.loading };
}

export default connect(mapStateToProps)(Login);
